export default {
  namespaced: true,
  state: {
    //聯絡人類別下拉
    contactList:[]

  },
  mutations: {
    updateContact (state, val) {
      state.contactList = val
    },

  }
}
