<template>
    <div>
      <div id="certify">
          <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item, index) in dataList" :key="index"><img @click="selImg(index)" :src="'/api/quickpay/quickpay/readPartImage/' + item" /></div>
                </div>
          </div>
          <div class="slideBottom">
              <div class="swiper-button-prev"></div>
              <div class="swiper-pagination"></div>
              
              <div class="swiper-button-next"></div>
          </div>
      </div>
      <div class="previewImageBox" v-show="previewFlg" @click="hidePreview()">
          <img class="previewImage" @click.stop :src="'/api/quickpay/quickpay/readPartImage/'+ dataList[previewIndex]"/>
          <div class="min-img-list" @click.stop>
            <img :class="previewIndex == index?'active':''" v-for="(item, index) in dataList" :key="'min'+index" @click.stop="changeImg(index)" :src="'/api/quickpay/quickpay/readPartImage/'+item"/>
          </div>
      </div>
  </div>
</template>

<script>
// import Swiper from 'swiper'
export default {
  name: "ImgSlide",
    components:{ 
  },
  props: {
    dataList: {
      type: Array,
      default: []
    },
  },
  data() {
    return {
        previewIndex: 0,
        previewFlg: false
       
    }
  },
  // watch:{
  //     dataList: function(newVal, oldVal){
  //       if(newVal && newVal.length > 0){
  //           this.initSwiper()
  //       }
  //     }
  // },
  created() { },
  mounted() {
    // this.initSwiper()
  },
  methods: {
    initSwiper() {
        console.log(9999)
        var certifySwiper = new Swiper('#certify .swiper-container', {
        	watchSlidesProgress: true,
        	slidesPerView: 'auto',
        	centeredSlides: true,
        	loop: true,
        	loopedSlides: 5,
        	autoplay: true,
        	navigation: {
        		nextEl: '.swiper-button-next',
        		prevEl: '.swiper-button-prev',
        	},
        	pagination: {
        		el: '.swiper-pagination',
        		//clickable :true,
        	},
        	on: {
        		progress: function(progress) {
        			for (var i = 0; i < this.slides.length; i++) {
        				var slide = this.slides.eq(i);
        				var slideProgress = this.slides[i].progress;
        				var modify = 1;
        				if (Math.abs(slideProgress) > 1) {
        					modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
        				}
        				var translate = slideProgress * modify * 200 + 'px';
        				var scale = 1 - Math.abs(slideProgress) / 5;
        				var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
        				slide.transform('translateX(' + translate + ') scale(' + scale + ')');
        				slide.css('zIndex', zIndex);
        				slide.css('opacity', 1);
        				if (Math.abs(slideProgress) > 3) {
        					slide.css('opacity', 0);
        				}
        			}
        		},
        		setTransition: function(swiper, transition) {
        			for (var i = 0; i < this.slides.length; i++) {
        				var slide = this.slides.eq(i)
        				slide.transition(transition);
        			}
        
        		}
        	}
        
        })
    },
    selImg(index){
        this.previewIndex = index;
        this.previewFlg = true;
    },
    changeImg(index){
        this.previewIndex = index;
    },
    hidePreview(){
        this.previewFlg = false;
    }
  },
}

</script>

<style lang="scss">
    @import "@/assets/scss/certify.css";
    @import "@/assets/scss/swiper-bundle.min.css";
    .previewImageBox{
        position: fixed;
        z-index: 99;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0,0.6);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .previewImage{
        width: 500px;
        height: 500px;
    }
    .min-img-list{
        margin-top: 28px;
    }
    .min-img-list img{
        width: 64px;
        height: 64px;
        border-radius: 8px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px;
    }
    .min-img-list img.active{
        border: 5px solid #8F0304;
    }
</style>