<template>
  <!--繳款單-->
  <div class="mod-home">
    <div class="down-btn" >
      <div style="width: 841px;margin: 0 auto;">
        <el-button @click="downLoadPdf">下載PDF</el-button>
      </div>
    </div>
    <div class="container box" v-loading="dataLoading" div id="pdfDom">
      <div class="home-header text-right">
        <div class="home-header-title">{{postForm.accountName}}</div>
        <img class="home-header-img" :src="headerImg" alt="">
      </div>
      <div class="home-body mb-4">
        <div class="home-middle">
          <!--頭部表單-->
          <div class="home-middle-head">
            <el-form class="form" ref="dataForm" :label-position="labelPosition" :model="dataForm"
                     label-width="110px">
              <el-row type="flex">
                <el-col :span="12">
                  <el-form-item label="繳款期限：" prop="paymentDeadLine" label-width="90px">
                    <div>{{dataForm.paymentDeadLine}}</div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="商店自訂編號：" prop="merchantOrderId" label-width="110px">
                    <div>{{dataForm.merchantOrderId}}</div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="用戶編號：" prop="userNo" label-width="90px">
                    <div>{{dataForm.userNo}}</div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="紅陽訂單編號：" prop="orderId" label-width="110px">
                    <div>{{dataForm.orderId}}</div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="列帳日期：" prop="createOrderTime" label-width="90px">
                    <div>{{dataForm.createOrderTime}}</div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="繳款人姓名：" prop="sna" label-width="110px">
                    <div>{{dataForm.sna}}</div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <el-divider></el-divider>
          <!--產品 表格-->
          <div class="home-middle-table" style="height: 270px">
            <!--表格-->
            <el-table
              :data="productNameList"
              fit
              style="width: 100%;" empty-text=" ">
              <el-table-column
                header-align="left"
                label="產品名稱"
                prop="productName"
                min-width="40%"
              >
              </el-table-column>
              <el-table-column
                header-align="left"
                label="數量"
                prop="productQuantity"
                min-width="20%"
              >
              </el-table-column>
              <el-table-column
                header-align="left"
                label="單價金額（元）"
                prop="productPrice"
                min-width="20%"
              >
              </el-table-column>
            </el-table>
          </div>
          <div class="mt-2 text-right font-size-10">合計：<span v-format="'#,##0'">{{productPriceAdd}}</span>元</div>
          <el-divider></el-divider>
          <!--商店網站名稱...-->
          <el-form ref="dataForm2" class="form2" style="height: 136px;" :label-position="labelPosition" :model="dataForm2"
                   label-width="110px">
            <el-row type="flex" :gutter="20">
              <el-col :span="19">
                <el-col :span="24" class="pl-0">
                  <el-form-item label="商店網站名稱：" prop="webSite">
                    <div>{{dataForm2.webSite}}</div>
                  </el-form-item>
                </el-col>
                <el-col :span="24" class="pl-0">
                  <el-form-item label="客服聯絡電話：" prop="phoneNumber">
                    <div>{{dataForm2.phoneNumber}}</div>
                  </el-form-item>
                </el-col>
                <el-col :span="24" class="pl-0">
                  <el-form-item label="交易內容：" prop="content">
                    <div>{{dataForm2.content}}</div>
                  </el-form-item>
                </el-col>
              </el-col>
              <el-col :span="4">
                <div class="home-middle-chapter" style="width:99px;">
                  收執章
                  <el-divider class="chapter-line"></el-divider>
                </div>
              </el-col>
              <el-col :span="1" class="tips">
                <span class="circle">◎</span>
                <span class="tips-text">第一聯—繳款人保留</span>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <!--沿虛線剪下-->
        <div class="dot">
          <div class="dot-tips">(請保留收據備查)</div>
          <div class="home-dot"></div>
          <div class="ml-1 mr-1">沿虛線剪下</div>
          <div class="home-dot2"></div>
          <i class="el-icon-scissors"></i>
        </div>
        <!--****************超商櫃檯條碼區***********-->
        <div class="item-title mb-1">超商櫃檯條碼區</div>
        <div class="home-middle" style="height: 196px;">
          <el-form ref="supermarketForm" class="form2" :label-position="labelPosition" :model="supermarketForm"
                   label-width="110px">
            <el-row type="flex" :gutter="20">
              <!--超商繳款專用-->
              <el-col :span="10" class="home-middle-left">
                <div class="item-title-sub mt-0 mb-1">超商繳款專用</div>
                <div class="font-size-10 mb-1 mt-1" style="line-height: 1.4;">
                  7-11超商、全家超商、OK超商、萊爾富超商(請
                  務必索取繳款證明單並核對繳款金額)逾期繳費
                  請勿代收。[繳款人無須另付手續費]
                </div>
                <div class="font-size-10">繳款截止日: {{supermarketForm.paymentDeadLine}}</div>
                <el-row type="flex" :gutter="20" class="flex-1">
                  <!--圖標-->
                  <el-col :span="15" class="pl-0 pr-0 mt-2 flex-1" style="width: 148px;">
                    <img class="icon-1" :src="superIcon" alt="">
                  </el-col>
                  <!--收執章-->
                  <el-col :span="9">
                    <div class="home-middle-chapter">
                      收執章
                      <el-divider class="chapter-line"></el-divider>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
              <!--超商繳款專用條碼區-->
              <el-col :span="12" class="text-center ">
                <div class="item-title-sub mt-0 mb-2">超商繳款專用條碼區</div>
                <div>
                  <barcode class="barcode" v-if="marketBarCodeOne!==null&&marketBarCodeOne!==''" :value="marketBarCodeOne"
                           :options="option"></barcode>
                </div>
                <div>
                  <barcode class="barcode" v-if="marketBarCodeTwo!==null&&marketBarCodeTwo!==''" :value="marketBarCodeTwo"
                           :options="option"></barcode>
                </div>
                <div>
                  <barcode class="barcode" v-if="marketBarCodeThree!==null&&marketBarCodeThree!==''" :value="marketBarCodeThree"
                           :options="option"></barcode>
                </div>
              </el-col>
              <el-col :span="2" class="tips">
                <span class="circle">◎</span>
                <span class="font-size-10">第二聯—超商收執</span>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <!--*************郵局櫃檯條碼區*************-->
        <div class="item-title mt-1" v-if="false">郵局櫃檯條碼區</div>
        <div class="home-middle mb-4" style="height:180px;" v-if="false">
          <el-form ref="postForm" class="form2" :label-position="labelPosition" :model="postForm"
                   label-width="110px">
            <el-row type="flex" :gutter="20" style="min-height: 280px;">
              <!--郵局櫃台專用-->
              <el-col :span="10" class="home-middle-left">
                <h3 class="item-title-sub mt-0 mb-1">郵局櫃台專用</h3>
                <div class="mb-1 font-size-10">
                  戶名：{{postForm.accountName}}
                </div>
                <div class="font-size-10 mb-1">[繳款人無須另付手續費]</div>
                <div class="font-size-10">繳款截止日: {{supermarketForm.paymentDeadLine}}</div>
                <el-row type="flex" class="flex-1" :gutter="20">
                  <!--圖標-->
                  <el-col :span="15" class="pl-0 pr-0 mt-2 flex-1" style="width: 148px;">
                    <img class="icon-2" :src="postIcon" alt="">
                  </el-col>
                  <!--郵局認證欄-->
                  <el-col :span="9">
                    <div class="home-middle-chapter">
                      郵局認證欄
                      <el-divider class="chapter-line"></el-divider>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
              <!--郵局櫃台專用條碼區-->
              <el-col :span="12" class="text-center">
                <div class="item-title-sub mt-0 mb-2">郵局櫃台專用條碼區</div>
                <div style="margin-bottom: -4px;">
                  <barcode class="barcode" v-if="postOfficeBarCodeOne!==null&&postOfficeBarCodeOne!==''" :value="postOfficeBarCodeOne"
                           :options="option"></barcode>
                </div>
                <div style="margin-bottom: -4px;">
                  <barcode class="barcode" v-if="postOfficeBarCodeTwo!==null&&postOfficeBarCodeTwo!==''" :value="postOfficeBarCodeTwo"
                           :options="option"></barcode>
                </div>
                <div style="margin-bottom: -4px;">
                  <barcode class="barcode" v-if="postOfficeBarCodeThree!==null&&postOfficeBarCodeThree!==''"
                           :value="postOfficeBarCodeThree" :options="option"></barcode>
                </div>
              </el-col>
              <el-col :span="2" class="tips">
                <span class="circle">◎</span>
                <span class="font-size-10">第二聯—郵局收執</span>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <!--Footer-->
      <div class="home-footer">
        <div>{{footer.merchant}}</div>
        <div v-if="footer.fax">傳真：{{footer.fax}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import barcode from '@xkeshi/vue-barcode'

export default {
    name: 'contribution-doc',
    components: {
      barcode
    },
    data () {
      return {
        exportPDFtitle: '超商條碼繳費單',
        headerImg: require('@/assets/img/doc-1.png'),
        superIcon: require('@/assets/img/doc-2.png'),
        postIcon: require('@/assets/img/doc-3.png'),
        labelPosition: 'left',
        dataForm: {
          // 繳款期限
          paymentDeadLine: this.$route.query.createOrderTime,
          // 商店自訂編號
          merchantOrderId: '',
          // 用戶編號
          userNo: '',
          // 紅陽訂單編號
          orderId: '',
          // 列帳日期
          createOrderTime: '',
          // 繳款人姓名
          sna: this.$route.query.creditCardUserName
        },
        dataForm2: {
          // 商店網站名稱
          webSite: this.$route.query.merchantName,
          // 客服聯絡電話
          phoneNumber: '',
          // 交易內容
          content: this.$route.query.orderInfo
        },
        supermarketForm: {
          // 繳款截止日
          paymentDeadLine: this.$route.query.createOrderTime
        },
        postForm: {
          accountName: this.$route.query.merchantName
        },
        productNameList: [],
        footer: {
          merchant: this.$route.query.merchantName,
          // 傳真
          fax: ''
        },
        // 合計
        productPriceAdd: this.$route.query.transactionAmount,
        // 超商條形碼
        marketBarCodeOne: this.$route.query.marketBarCodeOne,
        marketBarCodeTwo: this.$route.query.marketBarCodeTwo,
        marketBarCodeThree: this.$route.query.marketBarCodeThree,
        // 郵局條形碼
        postOfficeBarCodeOne: this.$route.query.postOfficeBarCodeOne,
        postOfficeBarCodeTwo: this.$route.query.postOfficeBarCodeTwo,
        postOfficeBarCodeThree: this.$route.query.postOfficeBarCodeThree,
        // 配置
        option: {
          format: 'CODE39',
          displayValue: true, // 是否默认显示条形码数据
          background: '#ffffff', // 条形码背景颜色
          height: '35px',
          fontSize: '8px',
          lineColor: '#606266',
          marginTop: '-7px'
        },
        dataLoading: false
      }
    },
    mounted () {
      this.showInfo()
    },
    methods: {
      downLoadPdf () {
        this.$nextTick(() => {
          this.getPdf(this.exportPDFtitle, '')
        })
      },
      showInfo () {
        // this.dataLoading = true;
        this.$http({
          url: this.$http.adornUrl(`/order/getFeeBill`),
          method: 'get',
          params: this.$http.adornParams({
            // 提交ID
            masterId: this.$route.query.masterId
          })
        }).then(({data}) => {
          if (data && data.resultCode === 200) {
            // 商店自訂編號
            this.dataForm.merchantOrderId = data.body.merchantOrderId
            // 用戶編號
            this.dataForm.userNo = data.body.merchantCode
            // 紅陽訂單編號
            this.dataForm.orderId = data.body.orderId
            // 列帳日期
            this.dataForm.createOrderTime = data.body.createOrderTime
            // 客服聯絡電話
            this.dataForm2.phoneNumber = data.body.phoneNumber
          // ------------超商條形碼------------
            this.marketBarCodeOne = data.body.marketBarCodeOne
            this.marketBarCodeTwo = data.body.marketBarCodeTwo
            this.marketBarCodeThree = data.body.marketBarCodeThree
          // -----------郵局條形碼----------------
            this.postOfficeBarCodeOne = data.body.postOfficeBarCodeOne
            this.postOfficeBarCodeTwo = data.body.postOfficeBarCodeTwo
            this.postOfficeBarCodeThree = data.body.postOfficeBarCodeThree
            this.productNameList = data.body.productNameList
            this.footer.fax = data.body.faxNumber
          } else {
            this.$message.error(data.resultMessage)
          }
          // this.dataLoading = false;
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";
  .mod-home {
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    line-height:1.3;
    .box {
      box-shadow: 0 2px 12px 0 $grey-3;
      width: 841px;
      padding: 32px 38px 44px 38px;
    }

    .down-btn {
      position: fixed;
      z-index: 1000;
      width: 100%;
      background: #fff;
      padding: 10px 0;
      top: 0;
    }
    .icon-1{
      width: 107px;
      height: 67px;
      padding-left: 10px;
    }
    .icon-2{
      width:175px;
      height:41px;
      padding-left:10px;
    }

    .home-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-title {
        font-size: 10px;
        font-weight: bold;
      }

      &-img {
        height: 50px;
      }
    }

    .item-title{
      font-weight:700;
      font-size:18px;
    }
    .item-title-sub{
      font-size:10px;
      font-weight:bold;
    }

    .home-middle {
      border: 1px solid #000;
      padding: 5px 8px;

      ::v-deep .el-form-item__label {
        font-size: 10px !important;
        line-height: 20px;
        color: #000;
      }

      ::v-deep .el-form-item__content {
        font-size: 10px !important;
        line-height: 20px;
        color: #000;
        word-break: break-all;
      }

      &-left {
        display: flex;
        flex-direction: column;
      }

      &-head {
        .el-form-item {
          margin-bottom: 0 !important;
        }
      }

      .form2 {
        .el-form-item {
          margin-bottom: 0 !important;
        }
      }



      &-chapter {
        font-size: 10px;
        font-weight: bold;
        padding-top: 5px;
        border: 1px solid #000;
        text-align: center;
        height: 92px;;
        position: relative;
      }

      .el-divider--horizontal {
        margin: 6px 0 !important;
      }

      .el-divider--horizontal.chapter-line {
        margin: 6px 0 !important;
      }

      .chapter-line.el-divider {
        background-color: #000;
      }
      .el-divider{
        background-color: #000000!important;
      }

      &-table{
        min-height:116px;
      }

      ::v-deep .el-table {
        font-size: 10px !important;

        tr {
          height: 13px;
          line-height: 13px;
        }

        .cell {
          padding: 5px 0 !important;
          color: #000000;
        }

        th > .cell {
          font-size: 10px;
          color: #000;
          padding-left: 0 !important;
          font-weight: lighter !important;
        }

        thead {
          color: #000000 !important;
          font-weight: lighter !important;
        }

      }


      .tips {
        writing-mode: tb;
        font-weight: bold;
        &-text{
          font-size:12px;
        }
      }
      .circle {
        font-size: 17px;
      }

    }

    .dot {
      margin-top:7px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size:10px;

      .el-icon-scissors {
        transform: rotate(-90deg);

        &:before {
          font-size: 21px;
        }
      }

      &-tips {
        position: absolute;
        top: -5px;
        right: 47%;
      }

      .home-dot {
        width: 70%;
        border-top: 2px dashed #000;
        flex: 1;
      }

      .home-dot2 {
        width: 10%;
        border-top: 2px dashed #000;
      }

    }


    .home-footer {
      display: flex;
      justify-content: space-between;
      border-top: 16px solid red;
    }

  }
  .barcode{
    max-width: 100%;
  }
</style>
