<template>
  <div class="member">
    <el-result icon="error" title="">
      <template slot="extra">
          <div class="sub-title">
              <span class="mr-4">交易逾時</span>
          </div>
      </template>
    </el-result>
  </div>
</template>

<script>
  export default {
    name: 'orderovertime',
    data() {
      return {
        count: '',
        isShowTimeHeader: 1
      }
    },
    mounted(){
      // this.countDown();
    },
    methods: {
      countDown() {
        const TIME_COUNT = 3;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.timer = setInterval(() => {
            if (this.count > 1 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              clearInterval(this.timer);
              this.timer = null;
              this.isShowTimeHeader = 0;
              window.close()
              //this.goPage()
            }
          }, 1000);
        }
      },
      goPage(page){
        this.$router.push({
          name:page
        })
      }

    }
  }
</script>

<style lang="scss" scoped>
  .member {
    width: 700px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 57px;
    border-radius: 20px;

    ::v-deep .icon-success {
      width: 150px;
      height: 150px;
    }

    ::v-deep .el-result__title {
      font-size: 45px;
    }
    .sub-title{
      font-size:48px;
    }

  }
</style>
