// 導出頁面為PDF
import html2canvas from "html2canvas"
import JsPDF from 'jspdf'
export default{
  install (Vue, options) {
    Vue.prototype.getPdf = function (htmlTitle, currentTime) {
      var element = document.getElementById("pdfDom")
      html2canvas(element, {
        logging: false,
        allowTaint: true,
        taintTest: false,
        useCORS: true,
        //width:960,
        //height:5072,
        dpi: window.devicePixelRatio*4, //將分辨率提高到特定的DPI 提高四倍
        scale:4 //按比例增加分辨率
      }).then(function (canvas) {
        var pdf = new JsPDF("p", "mm", "a4") // A4紙，縱向
        var ctx = canvas.getContext("2d")
        var a4w = 210; var a4h = 297 // A4大小，210mm x 297mm
        var imgHeight = Math.floor(a4h * canvas.width / a4w) // 按A4顯示比例換算一頁圖像的像素高度
        var renderedHeight = 0
        while (renderedHeight < canvas.height) {
          var page = document.createElement("canvas")
          page.width = canvas.width
          page.height = Math.min(imgHeight, canvas.height - renderedHeight)// 可能内容不足一页
          // 用getImageData剪裁指定區域，並畫到前面創建的canvas對象中
          page.getContext("2d").putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0)
          pdf.addImage(page.toDataURL("image/jpeg", 1.0), "JPEG", 0, 0, a4w, Math.min(a4h, a4w * page.height / page.width)) // 添加圖像到頁面，保留10mm邊距
          renderedHeight += imgHeight
          if (renderedHeight < canvas.height) { pdf.addPage() }// 如果後面還有内容，添加一個空頁
          // delete page;
        }
        pdf.save(htmlTitle + currentTime+ ".pdf")
      })
    }
  }
}
