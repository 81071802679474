const stringToJson = (keyName,type)=>{
  if(type==='number'){
    return localStorage.getItem(keyName)?JSON.parse(localStorage.getItem(keyName)):0
  }else if(type==='array'){
    return localStorage.getItem(keyName)?JSON.parse(localStorage.getItem(keyName)):[]
  }else if(type==='object'){
    return localStorage.getItem(keyName)?JSON.parse(localStorage.getItem(keyName)):{}
  }else{
    return localStorage.getItem(keyName)?localStorage.getItem(keyName):''
  }
};
export default {
  namespaced: true,
  state: {
    //信用卡
    creditCardPayList:stringToJson("creditCardPayList",'array'),
    //信用卡類型
    creditCardNameList:stringToJson("creditCardNameList",'array'),
    //行動支付
    actionPayList:stringToJson("actionPayList",'array'),
    //超商代收
    convenienceStorePayList:stringToJson("convenienceStorePayList",'array'),
    //其他支付
    otherPayList:stringToJson("otherPayList",'array'),
    //月
    currentMonthList:stringToJson("currentMonthList",'array'),
    //年
    currentYearList:stringToJson("currentYearList",'array'),
    //商戶編號加密 填寫的數據對象
    merchantCodeObj: stringToJson("merchantCodeObj",'object'),
    //交易金額
    transactionAmount:stringToJson("transactionAmount",'number'),
    //跳轉頁面
    routerName:stringToJson('routerName',''),
    payMethods:stringToJson('payMethods',''),
  },
  mutations: {
    updateCreditCardPay (state, val) {
      state.creditCardPayList = val;
      localStorage.setItem("creditCardPayList",JSON.stringify(state.creditCardPayList));
    },
    updateCreditCardName (state, val) {
      state.creditCardNameList = val;
      localStorage.setItem("creditCardNameList",JSON.stringify(state.creditCardNameList));
    },
    updateActionPay(state,val){
      state.actionPayList = val;
      localStorage.setItem("actionPayList",JSON.stringify(state.actionPayList));
    },
    updateConvenienceStorePay(state,val){
      state.convenienceStorePayList = val;
      localStorage.setItem("convenienceStorePayList",JSON.stringify(state.convenienceStorePayList));
    },
    updateOtherPay(state,val){
      state.otherPayList = val;
      localStorage.setItem("otherPayList",JSON.stringify(state.otherPayList));
    },
    updateCurrentMonth(state,val){
      state.currentMonthList = val;
      localStorage.setItem("currentMonthList",JSON.stringify(state.currentMonthList));
    },
    updateCurrentYear(state,val){
      state.currentYearList = val;
      localStorage.setItem("currentYearList",JSON.stringify(state.currentYearList));
    },
    updateMerchantCodeObj(state,val){
      state.merchantCodeObj = val;
      localStorage.setItem("merchantCodeObj",JSON.stringify(state.merchantCodeObj));
    },
    updateTransactionAmount(state,val){
      state.transactionAmount = val;
      localStorage.setItem("transactionAmount",JSON.stringify(state.transactionAmount));
    },
    updateRouterName(state,val){
      state.routerName = val;
      localStorage.setItem("routerName",val);
    },
    updatePayMethods(state,val){
      state.payMethods = val;
      localStorage.setItem("payMethods",val);
    }

  }
}
