<template>
  <!--掃描QR Code Apple Pay-->
  <div class="payment grey-bg">
    <div class="container">
      <!--頭-->
      <div class="show-top">
        <img :src="titleIcon" alt="" class="scanning-head-icon">
      </div>
      <div class="container box" style="margin-top: 20px;">
        <div class="show-body-inner">
          <div style="text-align:center; margin-top:20px; margin-bottom: 20px" v-if="expirationTime">
            <div class="payment-valid-until">
              付款有效時間至 Payment valid until
              <span style="white-space: nowrap;">{{ expirationTime}}</span>
            </div>
            <div style="display: inline-block; vertical-align: middle; white-space: nowrap;">
              <el-image class="clock-img" :src="clockImgUrl"></el-image>
              <div class="tb-txt">台北 (GMT+8)</div>
            </div>
          </div>
          <div>
            <!--商店信息...-->
            <div style="display: flex;justify-content: center;">
              <div class="mb-2">
                <div class="show-body-business mb-2">商店名稱／ Merchant Name：{{dataForm.businessName}}</div>
                <div class="show-body-business mb-2">商店訂單編號／ Merchant Order No.：{{dataForm.orderCode}}</div>
                <div class="show-body-business mb-2">訂單金額／ Amount：NT$<span class="black" style="font-weight: bold;"
                                                                           v-format="'#,##0'">{{dataForm.amount}}</span></div>
              </div>
            </div>

            <!--apple Pay-->
            <div class="show-body-pay-btn" v-if="type==='apple'">
              <div v-if="showQRCode">
                <div style="color: crimson;font-size: 16px;font-weight: 600;margin-bottom: 20px;">請使用iOS手機裝置掃描下方QR
                  Code，以利進行ApplePay支付步驟</div>
                <div style="display: flex;justify-content: center;">
                  <div class="qrcode" ref="qrCodeDiv"></div>
                </div>
              </div>
              <apple-pay-button v-if="showApplePay" @click="onApplePayButtonClickedJapan" buttonstyle="black"
                                type="plain" locale="zh-CN"></apple-pay-button>
            </div>
            <div class="font-size-18 text-center mb-4">SSL認證／ Secure Socket Layer</div>
            <div class="text-center">
              <img :src="greenIcon" alt="" style="width:141px;">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import QRCode from 'qrcodejs2'
  export default {
    name: "apple-pay-info",
    data() {
      return {
        titleIcon: require("../../assets/img/payment/pay-icon-3.png"),
        appleIcon: require("../../assets/img/payment/pay-icon-6.png"),
        googleIcon: require("../../assets/img/payment/pay-icon-7.png"),
        twIcon: require("../../assets/img/payment/pay-icon-8.png"),
        greenIcon: require("../../assets/img/payment/pay-icon-1.jpg"),
        clockImgUrl: require("../../assets/img/new-payment/clock-01.png"),
        dataForm: {
          //商店名稱
          businessName: '',
          //訂單編號
          orderCode: '',
          //交易金額
          amount: ''
        },
        applePayConfig: {
          merchantIdentifier: "merchant.com.redsunrise.applepay",
          countryCode: "TW",
          currency: "TWD"
        },
        id: '',
        type: '',
        showApplePay: false,
        showQRCode: false,
        expirationTime: ""
      }
    },
    mounted() {
      this.type = this.$route.query.type;
      this.id = this.$route.query.id;
      this.bindQRCode();
      this.showInfo()
      this.isPC();
    },
    methods: {
      isPC() {
        if (this.type == 'apple') {
          if (this.global.isPC()) {
            this.showQRCode = true;
          } else {
            window.ApplePaySession === undefined ? (this.showApplePay = false) : (this.showApplePay = true);
          }
        }

      },
      //獲取支付信息並判斷訂單狀態
      showInfo() {
        this.$http({
          url: this.$http.adornUrl('/order/applepay/timeoutVerification'),
          method: 'post',
          data: this.$http.adornData({
            masterId: this.id
          })
        }).then(({data})=>{
          if (data && data.resultCode == 200) {
            this.dataForm = {
              businessName: data.body.merchantName,
              orderCode: data.body.orderCode,
              amount: data.body.amount
            };
            if(data.body.statusFlag == 1){
              this.$router.push({
                path: '/error',
                query:{
                  id: this.id
                }
              })
            }else if(data.body.timeoutFlag == 1){
              this.$router.push({
                path: '/orderovertime'
              })
            }else{
              this.expirationTime = data.body.expirationTime
            }
          }
        })
      },
      // 苹果支付按钮
      async onApplePayButtonClickedJapan() {
        // 获取订单总价
        let strTotal = this.dataForm.amount;
        strTotal = strTotal.toString();

        if (!ApplePaySession) {
          return;
        }
        const request = {
          'countryCode': 'TW',
          'currencyCode': 'TWD',
          "merchantCapabilities": [
            "supports3DS"
          ],
          "supportedNetworks": [
            'visa',
            'masterCard',
            'jcb'
          ],
          "total": {
            "label": "Suntech Apple Pay",
            "type": "final",
            "amount": strTotal
          }
        };
        // Create ApplePaySession
        const session = new ApplePaySession(3, request);
        const failObject = {
          'status': ApplePaySession.STATUS_FAILURE
        }

        session.onvalidatemerchant = event => {
          const validationURL = event.validationURL;

          // const failObject = {
          //   'status': ApplePaySession.STATUS_FAILURE
          // }

          this.$http({
            url: this.$http.adornUrl('/order/applepay/validateMerchant'),
            method: 'post',
            data: this.$http.adornData({
              // 訂單ID
              validationUrl: validationURL
            })
          }).then((response)=>{
            let result = JSON.parse(response.data.body)
            session.completeMerchantValidation(result);
            // }).then(function(response) {
            //   session.completeMerchantValidation(failObject)
          }).catch(err => {
            session.completeMerchantValidation(failObject)
          })

          // this.getApplePaySession(validationURL).then(function(response) {
          //   let result = JSON.parse(response)
          //   session.completeMerchantValidation(result);
          // }).then(function(response) {
          //   session.completeMerchantValidation(failObject)
          // }).catch(err => {
          //   session.completeMerchantValidation(failObject)
          // })
        };
        session.onpaymentauthorized = event => {

          /*alert('onpaymentauthorized' + JSON.stringify(event.payment.token.paymentData))*/

          var paymentDataString =
                  JSON.stringify(event.payment.token.paymentData);
          var paymentDataBase64 = btoa(paymentDataString);

          let data = {
            masterId: this.id,
            amount: request.total.amount,
            paymentTokenObject: paymentDataBase64
          }

          this.$http({
            url: this.$http.adornUrl('/order/applepay/paymentProcess'),
            method: 'post',
            data: this.$http.adornData(data)
          }).then((response)=>{
            if (response.data.resultCode == 200) {
              /*alert('true')*/
              const result = {
                "status": ApplePaySession.STATUS_SUCCESS
              };
              session.completePayment(result);
              this.$router.push({
                name: 'card-info',
                query: {
                  masterId: this.id,
                  transactionAmount: this.dataForm.amount,
                  // orderInfo: this.dataForm.orderInfo,
                  // uuid: this.dataForm.uuid
                }
              })
            } else {
              session.completePayment(failObject);
            }
            // }).then(function(response) {
            //   session.completeMerchantValidation(failObject)
          }).catch(err => {
            session.completeMerchantValidation(failObject)
          })

          // this.paymentProcess(data).then(function(response) {

          //   if (response === true) {
          //     /*alert('true')*/
          //     const result = {
          //       "status": ApplePaySession.STATUS_SUCCESS
          //     };
          //     session.completePayment(result);
          //   } else {
          //     session.completePayment(failObject);
          //   }
          //   //let result = JSON.parse(response)
          //   //session.completeMerchantValidation(result);
          // }).then(function(response) {
          //   session.completeMerchantValidation(failObject)
          // }).catch(err => {
          //   session.completeMerchantValidation(failObject)
          // })

          // Define ApplePayPaymentAuthorizationResult

        };

        session.oncancel = event => {
          alert('oncancel')
          session.abort(); // maybe not*/
        };

        session.begin();

      },
      // 驗證商戶
      getApplePaySession(url) {

        return new Promise(function(resolve, reject) {

          var xhr = new XMLHttpRequest();
          xhr.open('POST', this.$http.adornUrl('/order/applepay/validateMerchant'));
          xhr.onload = function() {
            if (this.status >= 200 && this.status < 300) {
              resolve(JSON.parse(xhr.response));
            } else {
              reject({
                status: this.status,
                statusText: xhr.statusText
              });
            }
          };

          xhr.onerror = function() {
            reject({
              status: this.status,
              statusText: xhr.statusText
            });
          };

          xhr.setRequestHeader("Content-Type", "application/json");
          xhr.send(JSON.stringify({
            validationUrl: url
          }));
        });
      },
      // 付款
      paymentProcess(data) {
        return new Promise(function(resolve, reject) {
          var xhr = new XMLHttpRequest();
          xhr.open('POST', '/order/applepay/paymentProcess');
          xhr.onload = function() {
            if (this.status >= 200 && this.status < 300) {

              resolve(JSON.parse(xhr.response));
            } else {
              reject({
                status: this.status,
                statusText: xhr.statusText
              });
            }
          };

          xhr.onerror = function() {
            reject({
              status: this.status,
              statusText: xhr.statusText
            });
          };

          xhr.setRequestHeader("Content-Type", "application/json");
          xhr.send(JSON.stringify(data));
        });
      },
      bindQRCode() {
        let url = window.location.href;
        console.log(url);
        this.$nextTick(() => {
          new QRCode(this.$refs.qrCodeDiv, {
            text: url,
            width: 200,
            height: 200,
            correctLevel: 3
          })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .show-body {
    text-align: left !important;

  &-inner {
     max-width: 700px;
     width: 100%;
     margin: 0 auto;
   }

  &-title {
     font-size: 32px;
     color: #8e0303;
     margin-bottom: 32px;
   }

  &-amount {
     font-size: 32px;
     color: #707070;
     margin-bottom: 14px;
     font-weight: bold;
   }

  &-business {
     font-size: 20px;
     color: #707070;
   }

  &-bottom {
     font-size: 21px;
     color: #030303;
     margin-bottom: 31px;
   }

  &-pcode {
     font-size: 32px;
     color: #030303;
   }

  &-img-box {
     margin-bottom: 63px;
   }

  &-img {
     width: 40px;
     height: 40px;
   }

  &-back {
     font-size: 23px;
     color: #8e0303;
     font-weight: bold;
     border-bottom: 2px solid #8e0303;
     width: 97px;
     padding: 2px;
     text-align: center;
     cursor: pointer;
   }

  &-pay-btn {
     margin-top: 20px;
     margin-bottom: 40px;
     text-align: center;
   }

  &-btn {
  .el-button {
    padding: 17px 20px !important;
  }
  }

  .el-button--info {
    background-color: black !important;
    min-width: 290px;
    padding: 20px;
  }

  .pink.el-button--info {
    background-color: rgb(219, 56, 149) !important;
  }

  &-qrcode {
     font-size: 16px;
     color: #000;
     line-height: 1.5;
   }

  .line.el-divider--horizontal {
    margin: 23px !important;
  }

  .body-sec {
    margin-bottom: 48.7px;
    font-weight: bold;
  }

  }

  @media (min-width: 576px) {
    .container {
      max-width: 700px;
    }
  }

  @media(max-width: 550px) {
    .show-body-business {
      font-size: 16px;
    }

    .show-body-amount {
      font-size: 18px;
    }
  }

  apple-pay-button {
    --apple-pay-button-width: 142px;
    --apple-pay-button-height: 42px;
    --apple-pay-button-border-radius: 7px;
    --apple-pay-button-padding: 0px 0px;
    --apple-pay-button-box-sizing: border-box;
    margin-left: 20px;
    display: block;
    margin: 0 auto;
  }
  .payment-valid-until {
    display: inline-block;
    vertical-align: middle;
    color: #9D2323;
    font-size: 16px;
    font-weight: 600;
  }

  .clock-img {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin: 1px 5px;
  }

  .tb-txt {
    display: inline-block;
    vertical-align: middle;
    color: #8F918E;
    font-size: 16px;
    font-weight: 600;
  }
</style>
