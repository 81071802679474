<template>
  <!--掃描QR Code Google Pay-->
  <div class="payment grey-bg">
    <div class="container">
      <!--頭-->
      <div class="show-top">
        <img :src="titleIcon" alt="" class="scanning-head-icon">
      </div>
      <div class="container box" style="margin-top: 20px;background-color: #fff;
    padding: 30px 39px 50px 39px;
    border-radius: 38px;">
        <div class="show-body-inner">
          <div style="text-align:center; margin-top:20px; margin-bottom: 20px" v-if="expirationTime">
            <div class="payment-valid-until">
              付款有效時間至 Payment valid until
              <span style="white-space: nowrap;">{{ expirationTime}}</span>
            </div>
            <div style="display: inline-block; vertical-align: middle; white-space: nowrap;">
              <el-image class="clock-img" :src="clockImgUrl"></el-image>
              <div class="tb-txt">台北 (GMT+8)</div>
            </div>
          </div>
          <!--商店信息...-->
          <div style="display: flex;justify-content: center;">
            <div class="mb-2">
              <div class="show-body-business mb-2">商店名稱／ Merchant Name：{{dataForm.businessName}}</div>
              <div class="show-body-business mb-2">商店訂單編號／ Merchant Order No.：{{dataForm.orderCode}}</div>
              <div class="show-body-business mb-2">訂單金額／ Amount：NT$<span class="black" style="font-weight: bold;"
                                                                         v-format="'#,##0'">{{dataForm.amount}}</span></div>
            </div>
          </div>
          <!--Google Pay-->
          <div class="show-body-pay-btn" v-if="type==='google'">
            <div v-if="showQRCode">
              <div style="color: crimson;font-size: 16px;font-weight: 600;margin-bottom: 15px;">請使用Android手機裝置掃描下方QR Code，</div>
              <div style="color: crimson;font-size: 16px;font-weight: 600;margin-bottom: 20px;">或直接點選「Buy with Google Pay」，以利進行支付步驟</div>
              <div style="display: flex;justify-content: center;">
                <div class="qrcode" ref="qrCodeDiv"></div>
              </div>
            </div>
          </div>

          <!--Google Pay-->
          <div class="show-body-pay-btn">
            <!-- <el-button type="info">
              <div style="font-size:32px" class="d-flex align-items-center justify-content-between">
                <span>Buy with </span><img :src="googleIcon" style="width: 32px;">Pay
              </div>
            </el-button> -->
            <div id="container"></div>
            <div id="result"></div>
          </div>

          <div class="font-size-18 text-center mb-4">SSL認證／ Secure Socket Layer</div>
          <div class="text-center">
            <img :src="greenIcon" alt="" style="width:141px;">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import QRCode from 'qrcodejs2'
  const paymentsClient = null;
  export default {
    name: "google-pay-info",
    data() {
      return {
        titleIcon: require("../../assets/img/payment/pay-icon-3.png"),
        appleIcon:require("../../assets/img/payment/pay-icon-6.png"),
        googleIcon:require("../../assets/img/payment/pay-icon-7.png"),
        twIcon:require("../../assets/img/payment/pay-icon-8.png"),
        greenIcon:require("../../assets/img/payment/pay-icon-1.jpg"),
        clockImgUrl: require("../../assets/img/new-payment/clock-01.png"),
        dataForm: {
          //商店名稱
          businessName: '',
          //訂單編號
          orderCode: '',
          //交易金額
          amount:''
        },
        applePayConfig: {
          merchantIdentifier: "merchant.com.redsunrise.applepay",
          countryCode: "TW",
          currency: "TWD"
        },
        id: '',
        type:'',
        showApplePay: false,
        baseRequest: {
          apiVersion: 2,
          apiVersionMinor: 0,
          shippingAddressRequired: true,
        },
        allowedCardNetworks: ["AMEX", "DISCOVER", "JCB", "MASTERCARD", "VISA"],
        allowedCardAuthMethods: ["PAN_ONLY","CRYPTOGRAM_3DS"],
        billingAddressParameters: { phoneNumberRequired: true },
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            'gateway': 'esafe',
            'gatewayMerchantId': '14527973359676447335'
            // 'gateway': 'example',
            // 'gatewayMerchantId': 'exampleGatewayMerchantId'
          }
        },
        baseCardPaymentMethod: {},
        isRTPCardPaymentMethod: {},
        cardPaymentMethod: null,
        paymentsClient: null,
        expirationTime: "",
        showQRCode: false,
      }
    },
    created() {
      this.baseCardPaymentMethod = {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: this.allowedCardAuthMethods,
          allowedCardNetworks: this.allowedCardNetworks,
          billingAddressRequired: true,
          billingAddressParameters: this.billingAddressParameters,
          //cvcRequired: true,
          assuranceDetailsRequired: true,
        }
      }
      this.isRTPCardPaymentMethod = {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: this.allowedCardAuthMethods,
          allowedCardNetworks: this.allowedCardNetworks,
          billingAddressRequired: true,
          billingAddressParameters: this.billingAddressParameters,
        }
      };
      this.cardPaymentMethod = Object.assign(
              {},
              this.baseCardPaymentMethod,
              {
                tokenizationSpecification: this.tokenizationSpecification
              }
      );

      if (paymentsClient == null) {
        // load google pay script
        const googlePayScript = document.createElement('script')
        googlePayScript.setAttribute('src', 'https://pay.google.com/gp/p/js/pay.js')
        googlePayScript.setAttribute('type', 'text/javascript')
        googlePayScript.setAttribute('async', true)
        googlePayScript.setAttribute('defer', true)
        googlePayScript.onload = () => this.onGooglePayLoaded()
        document.head.appendChild(googlePayScript)
      }
    },
    mounted() {
      this.type = this.$route.query.type;
      this.id = this.$route.query.id;
      window.ApplePaySession === undefined ? (this.showApplePay = false) : (this.showApplePay = true);
      this.bindQRCode();
      this.showInfo();
      this.isPC();
    },
    methods: {
      isPC() {
        if (this.type == 'google') {
          if (this.global.isPC()) {
            this.showQRCode = true;
          }
        }
      },
      //獲取支付信息並判斷訂單狀態
      showInfo() {
        this.$http({
          url: this.$http.adornUrl('/order/applepay/timeoutVerification'),
          method: 'post',
          data: this.$http.adornData({
            masterId: this.id
          })
        }).then(({data})=>{
          if (data && data.resultCode == 200) {
            this.dataForm = {
              businessName: data.body.merchantName,
              orderCode: data.body.orderCode,
              amount: data.body.amount
            };
            if(data.body.statusFlag == 1){
              this.$router.push({
                path: '/error',
                query:{
                  id: this.id
                }
              })
            }else if(data.body.timeoutFlag == 1){
              this.$router.push({
                path: '/orderovertime'
              })
            }else{
              this.expirationTime = data.body.expirationTime
            }
          }
        })
      },
      /**
       * Configure your site's support for payment methods supported by the Google Pay
       * API.
       */
      getGoogleIsReadyToPayRequest() {
        return Object.assign(
                {},
                this.baseRequest,
                {
                  allowedPaymentMethods: [this.isRTPCardPaymentMethod],
                  existingPaymentMethodRequired: true
                }
        );
      },

      /**
       * Configure support for the Google Pay API
       */
      getGooglePaymentDataRequest() {
        const paymentDataRequest = Object.assign({}, this.baseRequest);
        paymentDataRequest.allowedPaymentMethods = [this.cardPaymentMethod];
        paymentDataRequest.transactionInfo = this.getGoogleTransactionInfo();
        paymentDataRequest.merchantInfo = {
          // @todo a merchant ID is available for a production environment after approval by Google
          // See {@link https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist|Integration checklist}
          // merchantId: 'BCR2DN4TULA6RDCP',
          // merchantName: 'sunpaytest'
          merchantId: '14527973359676447335',
          merchantName: 'SunTech Co., Ltd.'
        };
        return paymentDataRequest;
      },

      /**
       * Return an active PaymentsClient or initialize
       */
      getGooglePaymentsClient() {
        if ( paymentsClient === null ) {
          paymentsClient = new google.payments.api.PaymentsClient({environment: 'PRODUCTION'});
          // paymentsClient = new google.payments.api.PaymentsClient({environment: 'TEST'});
        }
        return paymentsClient;
      },

      /**
       * Initialize Google PaymentsClient after Google-hosted JavaScript has loaded
       */
      onGooglePayLoaded() {
        const paymentsClient = this.getGooglePaymentsClient();
        paymentsClient.isReadyToPay(this.getGoogleIsReadyToPayRequest())
                .then((response) => {
                  if (response.result) {
                    this.addGooglePayButton();
                    // @todo prefetch payment data to improve performance after confirming site functionality
                    this.prefetchGooglePaymentData();
                  } else {
                    alert("false");
                  }
                })
                .catch(function(err) {
                  // show error in developer console for debugging
                  console.error(err);
                });
      },

      /**
       * Add a Google Pay purchase button alongside an existing checkout button
       */
      addGooglePayButton() {
        const paymentsClient = this.getGooglePaymentsClient();
        const button =
                paymentsClient.createButton(
                        {onClick: ()=> this.onGooglePaymentButtonClicked(),
                          buttonColor: 'black',
                          buttonType: 'buy',
                        });
        document.getElementById('container').appendChild(button);
      },

      /**
       * Provide Google Pay API with a payment amount, currency, and amount status
       */
      getGoogleTransactionInfo() {
        return {
          currencyCode: 'USD',
          totalPriceStatus: 'FINAL',
          // set to cart total
          totalPrice: '1.00'
        };
      },

      /**
       * Prefetch payment data to improve performance
       */
      prefetchGooglePaymentData() {
        const paymentDataRequest = this.getGooglePaymentDataRequest();
        // transactionInfo must be set but does not affect cache
        paymentDataRequest.transactionInfo = {
          totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
          currencyCode: 'USD'
        };
        const paymentsClient = this.getGooglePaymentsClient();
        paymentsClient.prefetchPaymentData(paymentDataRequest);
      },

      /**
       * Show Google Pay payment sheet when Google Pay payment button is clicked
       */
      onGooglePaymentButtonClicked() {
        const paymentDataRequest = this.getGooglePaymentDataRequest();
        paymentDataRequest.transactionInfo = this.getGoogleTransactionInfo();

        const paymentsClient = this.getGooglePaymentsClient();
        paymentsClient.loadPaymentData(paymentDataRequest)
                .then((paymentData) => {
                  // handle the response
                  console.log(9999999)
                  this.processPayment(paymentData);
                })
                .catch(function(err) {
                  console.log(888888)
                  // show error in developer console for debugging
                  console.error(err);
                  alert(JSON.stringify(err));
                });
      },

      /**
       * Process payment data returned by the Google Pay API
       */
      processPayment(paymentData) {
        console.log(222)
        console.log(paymentData)
        console.log(222)
        this.$http({
          url: this.$http.adornUrl('/order/googlepay/paymentProcess'),
          method: 'post',
          data: this.$http.adornData({
            masterId: this.id,
            paymentTokenObject: paymentData.paymentMethodData.tokenizationData.token
          })
        }).then((response)=>{
          if (response.data.resultCode == 200) {
            console.log(response.data.body)
            if (response.data.body) {
              //3D授權
              let newWin = window.open('', '_self')
              newWin.document.write(response.data.body)
              newWin.document.close()
            } else {
              //非3D授權
              this.$router.push({
                name: 'card-info',
                query: {
                  masterId: this.id,
                  transactionAmount: this.dataForm.amount
                }
              })
            }
          } else {
            this.$message.error("支付失敗請刷新後重試！")
          }
        }).catch(err => {
          this.$message.error("支付失敗請刷新後重試！")
        })
        // $("#result").text(JSON.stringify(paymentData));
        // $('#result').append("<br/><br/>");
        // $("#result").append(JSON.stringify(paymentData['paymentMethodData']));
        // $('#result').append("<br/><br/>");
        // $("#result").append(JSON.stringify(JSON.parse(paymentData.paymentMethodData.tokenizationData.token)));

        // document.getElementById("result").text = (JSON.stringify(paymentData));
        // document.getElementById("result").append("<br/><br/>");
        // document.getElementById("result").append(JSON.stringify(paymentData['paymentMethodData']));
        // document.getElementById("result").append("<br/><br/>");
        // document.getElementById("result").append(JSON.stringify(JSON.parse(paymentData.paymentMethodData.tokenizationData.token)));
      },
      bindQRCode() {
        let url = window.location.href;
        console.log(url);
        this.$nextTick(() => {
          new QRCode(this.$refs.qrCodeDiv, {
            text: url,
            width: 200,
            height: 200,
            correctLevel: 3
          })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .show-body{
    text-align: left!important;
  &-inner{
     max-width: 700px;
     width: 100%;
     margin:0 auto;
   }
  &-title{
     font-size:32px;
     color: #8e0303;
     margin-bottom:32px;
   }
  &-amount{
     font-size:32px;
     color: #707070;
     margin-bottom:14px;
     font-weight: bold;
   }
  &-business{
     font-size: 20px;
     color: #707070;
   }

  &-bottom{
     font-size:21px;
     color: #030303;
     margin-bottom:31px;
   }
  &-pcode{
     font-size: 32px;
     color: #030303;
   }
  &-img-box{
     margin-bottom:63px;
   }
  &-img{
     width:40px;
     height:40px;
   }
  &-back{
     font-size: 23px;
     color: #8e0303;
     font-weight: bold;
     border-bottom:2px solid #8e0303;
     width: 97px;
     padding: 2px;
     text-align: center;
     cursor: pointer;
   }
  &-pay-btn{
     margin-top:20px;
     margin-bottom:40px;
     text-align: center;
   }
  &-btn{
  .el-button{
    padding: 17px 20px!important;
  }
  }
  .el-button--info {
    background-color: black!important;
    min-width:290px;
    padding:20px;
  }
  .pink.el-button--info{
    background-color: rgb(219,56,149) !important;
  }

  &-qrcode{
     font-size: 16px;
     color: #000;
     line-height:1.5;
   }
  .line.el-divider--horizontal {
    margin: 23px!important;
  }
  .body-sec{
    margin-bottom:48.7px;
    font-weight:bold;
  }

  }
  @media (min-width: 576px){
    .container {
      max-width: 700px;
    }
  }
  @media(max-width: 550px){
    .show-body-business{
      font-size: 16px;
    }
    .show-body-amount{
      font-size: 18px;
    }
  }
  apple-pay-button {
    --apple-pay-button-width: 142px;
    --apple-pay-button-height: 42px;
    --apple-pay-button-border-radius: 7px;
    --apple-pay-button-padding: 0px 0px;
    --apple-pay-button-box-sizing: border-box;
    margin-left: 20px;
    display: block;
    margin: 0 auto;
  }
  .payment-valid-until {
    display: inline-block;
    vertical-align: middle;
    color: #9D2323;
    font-size: 16px;
    font-weight: 600;
  }

  .clock-img {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin: 1px 5px;
  }

  .tb-txt {
    display: inline-block;
    vertical-align: middle;
    color: #8F918E;
    font-size: 16px;
    font-weight: 600;
  }
</style>
