<template>

  <div class="payment grey-bg" style="padding-top: 1px;" ref="content">
    <div class="container box">
      <el-row style="text-align: center;">
        <el-col>
          <el-image style="width: 100px;" :src="errorIconImgUrl"></el-image>
        </el-col>
      </el-row>
      <el-row style="text-align: center;" class="m-top-20">
        <el-col>
          <span class="top-txt">付款失敗</span>
        </el-col>
      </el-row>
      <el-row class="txt-cen m-top-15">
        <el-col>
          <span class="top-txt">Payment Fail!</span>
        </el-col>
      </el-row>
      <el-row class="txt-cen m-top-25">
        <el-col>
          <span class="top-txt-r" v-if="dataForm.transactionAmount">NT <span v-format="'$#,##0'">{{dataForm.transactionAmount}}</span></span>
        </el-col>
      </el-row>
      <el-row class="txt-cen m-top-20">
        <el-col>
          <el-image class="line" style="max-width: 100%;" :src="lineImgUrl"></el-image>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="m-top-20">
        <el-col :span="12" >
          <span class="bot-txt-g">特店名稱 Merchant Name</span>
        </el-col>
        <el-col :span="12" class="txt-end">
          <span class="bot-txt-b">{{dataForm.merchantName}}</span>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="m-top-20" >
        <el-col :span="12" >
          <span class="bot-txt-g">紅陽交易編號 Sunpay Transaction No.</span>
        </el-col>
        <el-col :span="12" class="txt-end">
          <span class="bot-txt-b">{{dataForm.orderId}}</span>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="m-top-20" >
        <el-col :span="12" >
          <span class="bot-txt-g">特店訂單編號 Merchant Order No.</span>
        </el-col>
        <el-col :span="12" class="txt-end">
          <span class="bot-txt-b">{{dataForm.merchantOrderId}}</span>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="m-top-20" >
        <el-col :span="12" >
          <span class="bot-txt-g">交易時間 Payment Time</span>
        </el-col>
        <el-col :span="12" class="txt-end">
          <span class="bot-txt-b">{{dataForm.createOrderTime}}</span>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="m-top-20">
        <el-col :span="12" >
          <span class="bot-txt-g">交易金額 Payment Amount</span>
        </el-col>
        <el-col :span="12" class="txt-end">
          <span class="bot-txt-b" v-if="dataForm.transactionAmount">NT<span v-format="'$#,##0'">{{dataForm.transactionAmount}}</span></span>
        </el-col>
      </el-row>

      <el-row style="text-align: center;" class="m-top-40">
        <el-col>
<!--          <el-image style="width: 600px; max-width: 100%;" :src="footerFinishImgUrl"></el-image>-->
          <el-image style="width: 200px; max-width: 100%;" :src="sLogImgUrl"></el-image>
        </el-col>
      </el-row>
    </div>
  </div>

  <!-- <div class="member" >
    <el-result icon="error" title="付款失敗">
      <template slot="extra">
        <div class="sub-title">
        </div>
      </template>
    </el-result>
  </div> -->
</template>

<script>
import html2canvas from 'html2canvas'
import storLogoImg from '../assets/img/new-payment/store-logo.png'
import errorIconImg from '../assets/img/new-payment/error-icon.png'
import lineImg from '../assets/img/new-payment/line.png'
import sLogImg from '../assets/img/new-payment/s-logo.png'
import footerFinishImg from '../assets/img/new-payment/footer-finish.png'
import imageDownImg from '../assets/img/new-payment/image-down.png'
import sLineImg from '../assets/img/new-payment/s-line.png'
  export default {
    name: 'error',
    data() {
      return {
        count: '',
        isShowTimeHeader: 1,
        dataForm: {
          orderId: '',
          masterId: '',
          successAllPath: '',
          failAllPath: '',
          merchantName: '',
          merchantOrderId: '',
          createOrderTime: '',
          transactionAmount: ''
        },
        storLogoImgUrl: storLogoImg,
        errorIconImgUrl: errorIconImg,
        lineImgUrl: lineImg,
        sLogImgUrl: sLogImg,
        imageDownImgUrl: imageDownImg,
        sLineImgUrl: sLineImg,
        footerFinishImgUrl: footerFinishImg,
      }
    },
    mounted(){
      this.dataForm.orderId = this.$route.query.id
      console.log(this.dataForm.orderId)
      if (typeof this.dataForm.orderId === 'undefined' || this.dataForm.orderId === null || this.dataForm.orderId === '') {
      } else {
        this.getOrderIdToMasterId()
      }
    },
    methods: {
      getOrderIdToMasterId () {
        // 增加 根據訂單ID獲取masterId
        this.$http({
          url: this.$http.adornUrl('/merchant/url/changeMasterIdByOrderId'),
          method: 'get',
          params: this.$http.adornParams({
            // 訂單ID
            id: this.dataForm.orderId
          })
        }).then(({data}) => {
          console.log('merchant/url/changeMasterIdByOrderId', data)
          if (data && data.resultCode === 200) {
            this.dataForm.masterId = data.body.masterId
            this.dataForm.noticeStatus = data.body.noticeStatus
            this.dataForm.merchantName = data.body.merchantName
            this.dataForm.createOrderTime = data.body.createOrderTime
            this.dataForm.merchantOrderId = data.body.merchantOrderId
            this.dataForm.orderId = data.body.orderId
            this.dataForm.transactionAmount = data.body.orderAmount
            if (this.dataForm.noticeStatus === '1'){
              this.setPaymentResult(() => {
                if (this.failAllPath) {
                  this.noticePaymentResultToMerchant(this.failAllPath)
                }
              })
            }
          }
        })
      },
      setPaymentResult (call) {
        this.$http({
          url: this.$http.adornUrl('/merchant/url/info'),
          method: 'post',
          data: this.$http.adornData({
            // 訂單ID
            masterId: this.dataForm.masterId
          })
        }).then(({data}) => {
          console.log('merchant/url/info', data)
          if (data && data.resultCode === 200) {
            this.successAllPath = data.body.successAllPath
            this.failAllPath = data.body.failAllPath
            if (call) {
              call()
            }
          }
        })
      },
      noticePaymentResultToMerchant (url) {
        this.$http({
          url: this.$http.adornUrl('/receive/return/data'),
          method: 'post',
          data: this.$http.adornData({
            // 訂單ID
            masterId: this.dataForm.masterId
          })
        }).then(({data}) => {
          if (data && data.resultCode === 200) {
            this.sendForm(url, data.body)
          }
        })
      },
      sendForm: function (url, data) {
        let newWin = window.open('', '_self')
        let text = '<!DOCTYPE HTML>'
        text = text + '<html lang="en">'
        text = text + '<head>'
        text = text + '  <meta charset="UTF-8">'
        text = text + '  <meta name="viewport" content="width=device-width, initila-scale=1.0">'
        text = text + '  <title></title>'
        text = text + '</head>'
        text = text + '<script type="text/javascript">'
        text = text + '    function autoSubmit(){'
        text = text + '      document.getElementById("sendForm").submit();'
        text = text + '    }'
        text = text + '<\/script>'
        text = text + '<body onload="autoSubmit();">'
        text = text + '<form action="'
        text = text + url
        text = text + '" method="post" id="sendForm">'
        text = text + '  <input type="hidden" id="web" name="web" value="' + data.web + '"/>'
        text = text + '  <input type="hidden" id="send_time" name="send_time" value="' + data.sendTime + '"/>'
        text = text + '  <input type="hidden" id="rsamsg" name="rsamsg" value="' + data.rsaMsg + '"/>'
        text = text + '  <input type="hidden" id="check_value" name="check_value" value="' + data.checkValue + '"/>'
        text = text + '</form>'
        text = text + '</body>'
        text = text + '</html>'
        newWin.document.write(text)
        newWin.document.close()
      },
      goPage(page){
        this.$router.push({
          name:page
        })
      },
      captrue() {
        const ref = this.$refs.content
        html2canvas(ref, {
          backgroundColor: '#e8f4ff',
          useCORS: true
        }).then((canvas) => {
          const dataURL = canvas.toDataURL('image/png')
          const creatDom = document.createElement('a')
          document.body.appendChild(creatDom)
          creatDom.href = dataURL
          creatDom.download = 'image'
          creatDom.click()
        })
      }

    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/payment.scss";

  .show-body{
    text-align: left!important;
    &-inner{
      width: 700px;
      margin:0 auto;
    }
    &-title{
      font-size:32px;
      color: #8e0303;
      margin-bottom:32px;
    }
    &-amount{
      font-size:32px;
      color: #707070;
      margin-bottom:14px;
      font-weight: bold;
    }
    &-business{
      font-size: 20px;
      color: #707070;
    }
    &-bottom{
      font-size:21px;
      color: #030303;
      margin-bottom:31px;
    }
    &-pcode{
      font-size: 32px;
      color: #030303;
    }
    &-img-box{
      margin-bottom:63px;
    }
    &-img{
      width:40px;
      height:40px;
    }
    &-back{
      font-size: 23px;
      color: #8e0303;
      font-weight: bold;
      border-bottom:2px solid #8e0303;
      width: 97px;
      padding: 2px;
      text-align: center;
      cursor: pointer;
    }
    .line.el-divider--horizontal {
      margin: 23px 0  53px 0 !important;
    }
    .body-sec{
      margin-bottom:48.7px;
    }
  }

  .txt-end {
   text-align: end;
  }
  .back-btn {
    font-size: 26px;
    color: #8F0304;
    font-weight: bold;
    border-bottom: 2px solid #8F0304;
    padding: 2px;
    cursor: pointer;
    text-align: center;
  }

  .next-btn {
    background: #8F0304;
    width: 400px;
  }

  .box-txt-bl-s {
    font-weight: 600;
    font-size: 12px;
    color: #000000;
    line-height: 20px;
  }

  .box-txt-bl-w {
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    line-height: 25px;
  }

  .box-txt-rd {
    font-weight: 600;
    color: #8F0304;
    font-size: 12px;
    line-height: 20px;
  }

  .box-txt-rd-w {
    font-weight: 400;
    color: #8F0304;
    font-size: 12px;
    line-height: 20px;
  }

  .bot-txt-g {
    font-weight: 400;
    font-size: 18px;
    color: #707070;
  }

  .bot-txt-b {
    font-weight: 400;
    font-size: 18px;
    color: #121212;
    word-wrap: break-word;
  }

  .m-top-15 {
    margin-top: 15px;
  }
  .m-top-20 {
    margin-top: 20px;
  }
  .m-top-25 {
    margin-top: 25px;
  }
  .m-top-40 {
    margin-top: 40px;
  }

  .txt-cen {
    text-align: center;
  }

  .left-txt-g {
    font-weight: 600;
    font-size: 26px;
    color: #707070;
  }

  .top-txt-r {
    font-weight: 600;
    font-size: 36px;
    color: #9D2323;
  }
  .left-txt-r {
    font-weight: 600;
    font-size: 26px;
    color: #8F0304;
  }

  .left-txt-b {
    font-weight: 600;
    font-size: 26px;
    color: #121212;
  }

  .line {
    width: 600px;
    height: 2px;
  }

  .notice {
    border-radius:10px;
    background: #FAF0CA;
    margin: 30px 0;
    padding: 20px;
  }

  .transactionAmount {
    font-weight: 600;
    font-size: 36px;
  }
  .top-txt {
    font-weight: 400;
    font-size: 22px;
  }
  .top-logo {
    width: 100px;
  }
  .w-b {
    padding: 60px 140px !important;
    margin-top: 20px;
    border-radius: 38px;
  }
</style>
