<template>
  <!--填寫驗證碼-->
  <el-dialog title="" :custom-class="['my-dialog','my-dialog700', loadingBtn?'no-close-btn':'']" :visible.sync="dialogFormVisible"
             append-to-body

             @closed="closeAfterDialog" :close-on-click-modal="false" :close-on-press-escape="false">
    <el-form ref="dataForm" :label-position="'top'" v-loading="dataLoading" :model="dataForm"
             label-width="70px" :rules="rules" @submit.native.prevent>
      <el-row class="m-top-40 txt-cen">
        <div class="top-title">
          交易金額
          <span class="en"> Payment Amount</span>
        </div>
      </el-row>
      <el-row class="m-top-10 txt-cen">
        <div class="amount-num">
          NT <span v-format="'$#,##0'">{{orderAmount}}</span>
        </div>
      </el-row>
      <el-row type="flex" justify="center" class="m-top-30">
        <el-col :span="21">
          <el-image :src="dialogLineImgUrl"></el-image>
        </el-col>
      </el-row>

          <el-form-item
              style="font-weight: 400;"
              label="請輸入驗證碼 Please Enter Verification Code"
              prop="captcha"
              :rules="[
                { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
              ]">
              <el-row :gutter="20" type="flex" justify="center" class="m-top-10">
                <el-col :span="16">
                  <div style="display: flex; position: relative;">
                      <el-input v-model="dataForm.captcha" @keyup.enter.native="inputSubmit" class="right-image-input"/>
                      <el-image :src="shieldCheckImgUrl" class="right-image"/>
                  </div>
                </el-col>
                  <el-col  :span="8" class="login-captcha">
                      <img style="max-width: 100%" :src="captchaPath" @click="getCaptcha()" alt="">
                  </el-col>

              </el-row>

              <span slot="error">
                <div class="error-msg">
                  <el-image :src="annotationAlertImgUrl" class="img"/>
                  <span class="txt">{{ formErrorMsg }}</span>
                </div>
              </span>
            </el-form-item>

      </el-row>
    </el-form>
    <div style="width: 100%; text-align: center; margin-top: 10px; margin-bottom: 80px;">
      <!--:loading="loadingBtn"-->
<!--         <el-button type="danger" @click="confirmPay" v-re-click>確認付款/Confirm</el-button>-->
      <!--非信用卡付款-->
      <template v-if="modeCode==='SUPER_MARKET_CODE'||modeCode==='SUPER_MARKET_BAR'||modeCode==='VIRTUAL_ACCOUNT'||modeCode==='SUPER_PAY_PICKUP'" >
         <el-button style="width: 400px;padding: 26px;" type="danger" :loading="loadingBtn" @click="confirmPay" v-re-click>確認付款 Confirm</el-button>
      </template>
      <!--信用卡付款-->
      <el-button v-else type="danger" :loading="loadingBtn" @click="confirmPayCard" v-re-click>確認付款 Confirm</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {getUUID} from '@/utils'
  import shieldCheckImg from '../../assets/img/new-payment/shield-check.png'
  import annotationAlertImg from '../../assets/img/new-payment/annotation-alert.png'
  import dialogLineImg from '../../assets/img/new-payment/dialog-line.png'

  export default {
    name: 'newVerCodeDialog',
    props: {
      // 用於區分非信用卡繳費時的不同繳費方式
      // 1.超商代碼繳費 2.超商條碼繳費 3.虛擬帳號轉帳 4.超商取貨
      modeCode: String,
      // 確認支付接口的 入參：提交ID
      masterId: String,
      // 交易金額
      orderAmount: String,
      // 付款人姓名
      creditCardUserName: String,
      // 付款人電話
      creditCardPhone: String,
      // 付款人email
      creditCardEmail: String
    },
    data () {
      return {
        row: {},
        // orderAmount:'50',
        dialogFormVisible: false,
        dataLoading: false,
        dataForm: {
          // 提交ID
          masterId: '',
          // 驗證碼uuid
          uuid: '',
          // 驗證碼
          captcha: '',
          // 付款人姓名
          creditCardUserName: '',
          // 付款人電話
          creditCardPhone: '',
          // 付款人email
          creditCardEmail: '',
          orderInfo: '',
          //城市
          city:'',
          //區域
          area:'',
          //地址
          address:'',
          //買受人統編
          buyerIdentifier:'',
          //手機條碼
          carrierId:'',
          //捐贈碼
          poban:'',
          //完整地址
          invoiceAddress:'',
          //發票狀態radio選項
          invoiceStatus:'',
          // 備註
          note:'',
          //發票抬頭
          invoiceHead:'',
		  //收件人
		  recipientName:'',
		  recipientPhoneNumber:'',
		  recipientEmail:'',
		  //寄送方式 10.超商店到店 20.超商取貨付款 30.超商取貨
		  shippingMethod:'',
		  //物流編碼
		  logisticsModeCode:'',
		  //取货地址-門市店名
		  pickUpStoreName:'',
		  //取货地址-門市地址
		  pickUpAddress:'',
		  //取货地址-門市店號
		  pickUpStoreCode:'',
        },
        captchaPath: '',
        rules: {
          captcha: [
            {required: true, message: '請輸入驗證碼', trigger: 'blur'}
          ]
        },
        dataSubmitButtonDisabled: false,
        loadingBtn: false,
        shieldCheckImgUrl: shieldCheckImg,
        annotationAlertImgUrl: annotationAlertImg,
        dialogLineImgUrl: dialogLineImg,
        formErrorMsg: '必填欄位不得為空白 Field required',
        closeFlg: 1
      }
    },
    methods: {
      init (row) {
        this.closeFlg = 1;
        this.row = row;
        debugger
        this.dialogFormVisible = true
      // 確認支付 接口入參
        this.dataForm.creditCardUserName = row.creditCardUserName
        this.dataForm.creditCardPhone = row.creditCardPhone
        this.dataForm.creditCardEmail = row.creditCardEmail
        this.dataForm.orderInfo = row.orderInfo
        this.dataForm.city = row.city
        this.dataForm.area = row.area
        this.dataForm.address = row.address
        this.dataForm.buyerIdentifier = row.buyerIdentifier
        this.dataForm.carrierId = row.carrierId
        this.dataForm.poban = row.poban
        this.dataForm.invoiceAddress = row.invoiceAddress
        this.dataForm.invoiceStatus = row.invoiceStatus
        this.dataForm.remark = row.desc

		this.dataForm.invoiceHead =  row.invoiceHead
		this.dataForm.invoicePaper = row.invoicePaper
		this.dataForm.invoicePrintMode = row.invoicePrintMode

		this.dataForm.invoiceHead =  row.invoiceHead
		this.dataForm.invoicePaper = row.invoicePaper
		this.dataForm.invoicePrintMode = row.invoicePrintMode

		this.dataForm.recipientName = row.recipientName
		this.dataForm.recipientPhoneNumber = row.recipientPhoneNumber
		this.dataForm.recipientEmail = row.recipientEmail
		this.dataForm.pickUpStoreName = row.pickUpStoreName
		this.dataForm.pickUpAddress = row.pickUpAddress
		this.dataForm.pickUpStoreCode = row.pickUpStoreCode
		this.dataForm.shippingMethod = row.shippingMethod
		this.dataForm.logisticsModeCode = row.logisticsModeCode

		this.dataForm.cellphoneNumber = row.cellphoneNumber
		this.dataForm.verificationCode = row.verificationCode
		this.dataForm.idCardNumber = row.idCardNumber
		this.dataForm.issuanceLocation = row.issuanceLocation
		this.dataForm.issuanceDate = row.issuanceDate
		this.dataForm.issuanceType = row.issuanceType
		this.dataForm.verificationFlag = row.verificationFlag

      // 獲取右側驗證碼
        this.getCaptcha();
        if(!this.dataForm.masterId || this.dataForm.masterId == ''){
            this.getMasterId();
        }
      },
      // 獲取驗證碼
      getCaptcha () {
        this.dataForm.uuid = getUUID()
        this.captchaPath = this.$http.adornUrl(`/order/captcha.jpg?uuid=${this.dataForm.uuid}`)
      },
      closeAfterDialog () {
        this.$nextTick(() => {
          if (this.$refs['dataForm']) {
            this.$refs['dataForm'].resetFields()
          }
          this.dialogFormVisible = false
          if(this.closeFlg == 1){
            this.$emit('dialogClosed')
          }
        })
      },
      /**
       * 獲取masterId
       */
      getMasterId(){
         let lgs_flag = '0';
         let cardType = '00';
         let storeType = ''
         switch (this.modeCode){
            case 'DOMESTIC_CREDIT_CARD':
                cardType = '01'
                break
            case 'UNION_CARD':
                cardType = '02'
                break
            case 'SUPER_MARKET_CODE':
                cardType = '06'
                break
            case 'SUPER_MARKET_BAR':
                cardType = '07'
                break
            case 'VIRTUAL_ACCOUNT':
                cardType = '08'
                break
			case 'SUPER_PAY_PICKUP':
				cardType = '09'
                lgs_flag = '1';
				break
         }
          if(this.dataForm.logisticsModeCode=='OK_PU_CODE'){
              storeType = '3'
              lgs_flag = '1';
          }else if(this.dataForm.logisticsModeCode=='LYLFU_PU_CODE'){
              storeType = '4'
              lgs_flag = '1';
          }else if(this.dataForm.logisticsModeCode=='FAMILY_PU_CODE'){
              storeType = '2'
              lgs_flag = '1';
          }else if(this.dataForm.logisticsModeCode=='7-ELEVEN_PU_CODE'){
              storeType = '1'
              lgs_flag = '1';
          }
          let form = {
              quickpayId:this.row.quickpayId,
              web: this.row.merchantCode,
              mn: this.row.transactionAmount,
              td: this.row.orderId,
              chkValue: '123',
              sna: this.row.creditCardUserName,
              sdt: this.row.creditCardPhone,
              email: this.row.creditCardEmail,
              note1: this.row.note,
              cardType: cardType,
              term: this.row.creditCardInstallment,
              orderSource: '05', // 00：收銀台 01：API 02:管理後臺 03:商戶後臺 / 04：swipy / 05：網址付
              productName: this.row.productName,
              productPrice: this.row.productPrice,
              productQuantity: this.row.productQuantity,
              remark: this.row.desc,
              orderInfo: this.row.orderInfo,
              store_type: storeType,
              lgs_flag:lgs_flag,
              result:[
                  {
                    productName: this.row.productName,
                    productPrice: this.row.productPrice,
                    productQuantity: this.row.productQuantity
                  }
              ]
          }
          if(!form.note1){
            form.note1 = this.row.desc;
          }
          this.$http({
            url: this.$http.adornUrl('/order/saveOrderMaster'),
            method: 'post',
            data: this.$http.adornData(form)
          }).then(({data}) => {
            console.log(data)
            this.dataForm.masterId = data.body.masterId
          })
      },
      /**
       * 確認付款
       */
      confirmPay () {
        if (this.dataSubmitButtonDisabled) {
          return
        }
        this.dataSubmitButtonDisabled = true
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.loadingBtn = true
            let url
            // 超商條碼繳費
            if (this.modeCode === 'SUPER_MARKET_BAR') {
              url = '/order/payByMarketBarCode'
            }
            // 虛擬帳號轉帳
            if (this.modeCode === 'VIRTUAL_ACCOUNT') {
              url = '/order/payByVirtualAccount'
            }
            // 超商代碼
            if (this.modeCode === 'SUPER_MARKET_CODE') {
              url = '/order/payByMarketCode'
            }
			// 超商取貨
			if (this.modeCode === 'SUPER_PAY_PICKUP') {
			  url = '/order/paySuperPickup'
			}
            let form = this.global.formatObj(this.dataForm)

            this.$http({
              url: this.$http.adornUrl(url),
              method: 'post',
              data: this.$http.adornData(form)
            }).then(({data}) => {
              this.dataSubmitButtonDisabled = false
              this.loadingBtn = false
              if (data && data.resultCode === 200) {
                //超商代碼交付、超商條碼繳費、虛擬帳號繳費時候，無需提示信息
                if(this.modeCode=='SUPER_MARKET_CODE'||this.modeCode=='SUPER_MARKET_BAR'||
				this.modeCode=='VIRTUAL_ACCOUNT'||this.modeCode=='SUPER_PAY_PICKUP'){
                  // 跳轉到不同的繳費顯示頁
                  this.closeFlg = 2;
                  this.$emit('goPay', this.dataForm);
                  this.dialogFormVisible = false
                }else {
                  this.$message({
                    message: '付款成功',
                    type: 'success',
                    duration: 1500,
                    onClose: () => {
                      // 跳轉到不同的繳費顯示頁
                      this.closeFlg = 2;
                      this.$emit('goPay', this.dataForm);
                      this.dialogFormVisible = false
                    }
                  })
                }

              } else {
                // 獲取驗證碼
                this.getCaptcha()
                this.dataForm.captcha = ''
                this.$message.error(data.resultMessage)
              }
            })
          } else {
            this.dataSubmitButtonDisabled = false
          }
        })
      },
      /**
       * 信用卡付款
       */
      confirmPayCard () {
        this.closeFlg = 2;
        this.$emit('goPay', this.dataForm)
        this.dialogFormVisible = false
      },
      inputSubmit(){
        if(this.modeCode==='SUPER_MARKET_CODE'|| this.modeCode==='SUPER_MARKET_BAR'|| this.modeCode==='VIRTUAL_ACCOUNT'|| this.modeCode==='SUPER_PAY_PICKUP'){
          this.confirmPay()
        }else {
          this.confirmPayCard()
        }
      }
    }
  }
</script>

<style lang="scss">
  .my-dialog {
    width: 95% !important;
    max-width: 700px !important;
    // min-width: 400px;
    border-radius: 20px !important;
    @media (max-width: 767.98px){
      width: 90%;
      min-width: auto;
    }
    .el-form--label-top .el-form-item__label {
      padding: 0 0 6px;
      line-height: 20px;
    }
    .el-icon-close:before {
      font-size: 20px!important;
    }
    .el-button--danger {
      background-color: #8e0303;
      border-color: #8e0303;
      span{
        font-weight:bold;
        font-size:18px;
      }
    }

  .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
    border: 1px solid #8F0304;
    border-radius: 50%;
    background: #8F0304;
  }
  }
</style>
<style scoped lang="scss">
  .el-button {
    padding: 20px;
    width: 100%;
    max-width: 400px;
  }

  .login-captcha {
    margin-top: 0px;
    > img {
      height: 40px;
      cursor: pointer;
    }
  }

  .el-icon-close:before {
    content: "\E6DB";
    font-size: 27px;
  }

  ::v-deep .el-form--label-top {
    .el-form-item {
      margin-top: 10px !important;
      .el-input__inner{
        font-size: 16px !important;
        font-weight: 400 !important;
        color: #525252;
        background-color: #EDEDED;
        border-color: #EDEDED;
      }

      &__label {
        line-height: 23.12px !important;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.9px;
        padding: 0 0 5px !important;
        color: #525252 !important;

      }

      .el-form-item__error {
        color: #FF0000 !important
      }
    }
  }

  ::v-deep .right-image-input {
    .el-input__inner {
      padding-right: 60px;
    }
  }
  .right-image {
    width: 30px;
    position: absolute;
    right: 13px;
    top: 5px;
  }
  .error-msg {
    display: flex;
    flex-direction: row;
    line-height: 20px;
    position: absolute;
    .img {
      width: 14px;
      height: 14px;
      margin-top: 3px;
    }
    .txt {
      margin-left: 5px;
      color: #FF0000;
      font-size: 8px;
      font-weight: 400;
      width: 200px;
    }
  }

  .m-top-10 {
    margin-top: 10px;
  }
  .m-top-40 {
    margin-top: 40px;
  }
  .m-top-30 {
    margin-top: 30px;
  }

  .txt-cen {
    text-align: center;
  }

  .top-title {
    font-size: 20px;
    font-weight: 600;
    color: #8F918E;
    .en {
      font-weight: 400
    }
  }

  .amount-num {
    font-size: 36px;
    font-weight: 600;
    color: #9D2323;
  }
</style>
