import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import Element from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css';
import VueQr from 'vue-qr'

import http  from '@/utils/httpRequest'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from '@/element-ui/zh-TW'
Vue.use(ElementUI, {locale});


import '@/assets/scss/index.scss'
import '@/assets/scss/style.scss'

import global from '@/utils/global_variable'
Vue.prototype.global = global;

import htmlToPdf from '@/utils/htmlToPdf';
Vue.use(htmlToPdf);

Vue.config.productionTip = false
// Vue.use(Element, {
//   size: 'default',
//   i18n: (key, value) => i18n.t(key, value)
// })
Vue.use(VueQr)
Vue.prototype.$http = http;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
