<template>
<!--消費者個資移轉商店使用同意書-->
  <div style="border-radius:10px; border: 1px solid #000000;background: #FFFFFF;margin: 10px 0 20px 0;padding: 15px;">
    <el-button @click="()=>{ agreement3Lng = !agreement3Lng}" type="danger" style="width: 80px; height: 25px;background: #8F0304; font-size: 12px; font-weight: 600;border-radius: 25px; padding: 3px;">{{ agreement3Lng ? 'English' : '中文' }}</el-button>
    <div style="color:#8F918E;margin-top: 20px; height: 96px; overflow: auto;">
      <div v-if="agreement3Lng">
        <div>
          您同意紅陽科技在營運期間內為履行金流契約所需之範圍(含身分驗證及核銷等)，得於中華民國境內蒐集、處理及利用您的姓名、電話號碼及電子郵件等個資。您復同意本公司得將您個人資料移轉予合作商家分享，使該商家得以履行對您的訂單出貨、售後服務等義務。若商家涉及將您的個人資料為前述特定目的外之利用，請洽本公司客服電話：02-25026969。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "agreement3",
  data(){
    return{
        agreement3Lng: true
    }
  },
  methods:{
    
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/agreement.scss";

</style>
