<template>
  <!--轉帳匯款-->
  <div class="mod-home" v-loading="dataLoading">
    <div class="down-btn" >
      <div style="width: 841px;margin: 0 auto;">
        <el-button @click="downLoadPdf">下載PDF</el-button>
      </div>
    </div>
    <div class="container box" div id="pdfDom">
      <div class="home-header text-right">
        <img class="home-header-img" :src="headerImg" alt="">
      </div>
      <div class="home-body mb-4">
        <div class="home-middle mb-2">
          <!--頭部表單-->
          <div class="home-middle-head">
            <el-form class="form" ref="dataForm" :label-position="labelPosition" :model="dataForm"
                     label-width="110px">
              <el-row type="flex">
                <el-col :span="12">
                  <el-form-item label="繳款期限：" prop="paymentDeadLine">
                    <div>{{dataForm.paymentDeadLine}}</div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="商店自訂編號：" prop="merchantOrderId">
                    <div>{{dataForm.merchantOrderId}}</div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="用戶編號：" prop="userNo">
                    <div>{{dataForm.userNo}}</div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="紅陽訂單編號：" prop="orderId">
                    <div>{{dataForm.orderId}}</div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="列帳日期：" prop="createOrderTime">
                    <div>{{dataForm.createOrderTime}}</div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="繳款人姓名：" prop="sna">
                    <div>{{dataForm.sna}}</div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <el-divider></el-divider>
          <!--產品 表格-->
          <div class="home-middle-table">
            <!--表格-->
            <el-table
              :data="tableList"
              stripe
              fit
              style="width: 100%" empty-text=" ">
              <el-table-column
                header-align="left"
                label="產品名稱"
                prop="productName"
              >
              </el-table-column>
              <el-table-column
                header-align="left"
                label="數量"
                prop="productQuantity"
              >
              </el-table-column>
              <el-table-column
                header-align="left"
                label="單價金額（元）"
                prop="productPrice"
              >
              </el-table-column>
            </el-table>
          </div>
          <div class="mt-6 text-right">合計：{{total}}元</div>
          <el-divider></el-divider>
          <!--商店網站名稱...-->
          <el-form ref="dataForm2" class="form2" :label-position="labelPosition" :model="dataForm2"
                   label-width="110px">
            <el-row type="flex" :gutter="20">
              <el-col :span="19">
                <el-col :span="24">
                  <el-form-item label="商店網站名稱：" prop="webSite">
                    <div>{{dataForm2.webSite}}</div>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="客服聯絡電話：" prop="phoneNumber">
                    <div>{{dataForm2.phoneNumber}}</div>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="交易內容：" prop="content">
                    <div>{{dataForm2.content}}</div>
                  </el-form-item>
                </el-col>
              </el-col>
              <el-col :span="4">
                <div class="home-middle-chapter">
                  收執章
                  <el-divider class="chapter-line"></el-divider>
                </div>
              </el-col>
              <el-col :span="1" class="tips pr-0">
                <span class="circle">◎</span>
                <span class="font-size-14">第一聯—繳款人保留</span>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <!--銀行或郵局ATM自動提款機轉帳-->
        <h1 class="mb-1">銀行或郵局ATM自動提款機轉帳</h1>
        <div class="home-middle">
          <el-form ref="bankForm" class="form2" :label-position="labelPosition" :model="bankForm"
                   label-width="110px">
            <el-row type="flex" :gutter="20">
              <el-col :span="12">
                <el-form-item label="匯款方式：" prop="type">
                  <div>{{bankForm.type}}</div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="戶名：" prop="accountName">
                  <div>{{bankForm.accountName}}</div>
                </el-form-item>
              </el-col>
              <el-col :span="12" class="d-flex">
                <el-row type="flex" justify="space-between" class="w-100 bankCode">
                  <el-col :span="12">
                    <el-form-item class="mr-4" label="第一銀行代碼：" prop="bankCode">
                      <div style="word-break: break-all;">{{bankForm.bankCode}}</div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="分行代碼：" prop="branchCode">
                      <div style="word-break: break-all;">{{bankForm.branchCode}}</div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-form-item label="虛擬帳號：" prop="virtualAccount">
                  <div>{{bankForm.virtualAccount}}</div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="繳款截止日：" prop="closingDate">
                  <div>{{bankForm.closingDate}}</div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="繳款金額：" prop="orderAmount">
                  <div>{{bankForm.orderAmount}}元</div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="home-footer">
        <div>{{footer.merchant}}</div>
        <div v-if="footer.fax">傳真：{{footer.fax}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'transfer-remittance-doc',
    data () {
      return {
        headerImg: require('@/assets/img/doc-1.png'),
        labelPosition: 'left',
        exportPDFtitle: '轉帳單',
        dataForm: {
          // 繳款期限
          paymentDeadLine: this.$route.query.createOrderTime,
          // 商店自訂編號
          merchantOrderId: '',
          // 用戶編號
          userNo: '',
          // 紅陽訂單編號
          orderId: '',
          // 列帳日期
          createOrderTime: '',
          // 繳款人姓名
          sna: this.$route.query.creditCardUserName

        },
        dataForm2: {
          // 商店網站名稱
          webSite: '紅陽科技網站',
          // 客服聯絡電話
          phoneNumber: '0225026969',
          // 交易內容
          content: this.$route.query.orderInfo
        },
        bankForm: {
          // 匯款方式
          type: '虛擬賬號',
          // 戶名
          accountName: this.$route.query.merchantName,
          // 第一銀行代碼
          bankCode: this.$route.query.bankCode,
          // 分行代碼
          branchCode: this.$route.query.bankCode,
          // 虛擬賬號
          virtualAccount: this.$route.query.virtualAccount,
          // 繳費截止日期
          closingDate: this.$route.query.createOrderTime,
          // 繳款金額
          orderAmount: this.$route.query.transactionAmount
        },
        tableList: [],
        // 合計
        total: this.$route.query.transactionAmount,
        footer: {
          merchant: this.$route.query.merchantName,
          fax: ''
        },
        dataLoading: false

      }
    },
    mounted () {
      this.showInfo()
    },
    methods: {
      downLoadPdf () {
        this.$nextTick(() => {
          this.getPdf(this.exportPDFtitle, '')
        })
      },
      showInfo () {
        this.dataLoading = true
        this.$http({
          url: this.$http.adornUrl(`/order/getFeeBill`),
          method: 'get',
          params: this.$http.adornParams({
            // 提交ID
            masterId: this.$route.query.masterId
          })
        }).then(({data}) => {
          if (data && data.resultCode === 200) {
            this.dataForm.merchantOrderId = data.body.merchantOrderId
            this.dataForm.userNo = data.body.merchantCode
            this.dataForm.orderId = data.body.orderId
            this.dataForm.createOrderTime = data.body.createOrderTime
            this.tableList = data.body.productNameList
            this.footer.fax = data.body.faxNumber
          } else {
            this.$message.error(data.resultMessage)
          }
          this.dataLoading = false
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/variables.scss";

  .mod-home {
    line-height: 1.5;
    padding-top: 80px;
    padding-bottom: 80px;


    .down-btn {
      position: fixed;
      z-index: 1000;
      width: 100%;
      background: #fff;
      padding: 10px 0;
      top: 0;
    }

    .box{
      padding:60px;
      box-shadow: 0 2px 12px 0 $grey-3;
    }
    .home-header {
      margin-bottom: 20px;

      &-img {
        width: 300px;
      }
    }

    .home-middle {
      border: 1px solid #000;
      padding: 13px 30px;

      .bankCode{
        .el-form-item__content{
          line-height:27px;
        }
      }

      &-head {
        margin-bottom: 20px;

        .el-form-item {
          margin-bottom: 0 !important;
        }
      }

      .form2 {
        .el-form-item {
          margin-bottom: 0 !important;
        }
      }

      &-chapter {
        font-size: 16px;
        font-weight: bold;
        padding-top: 10px;
        border: 1px solid #000;
        text-align: center;
        height: 100%;
        position: relative;
      }

      .el-divider--horizontal.chapter-line {
        margin: 6px 0 !important;
      }
      .chapter-line.el-divider {
        background-color: #000;
      }

      .tips {
        writing-mode: tb;
        font-size: 16px;
        font-weight: bold;
      }

      .circle {
        font-size: 27px;
      }

    }

    .home-footer {
      display: flex;
      justify-content: space-between;
      border-top: 16px solid red;
    }

  }
</style>
