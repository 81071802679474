<template>
  <!--一開始進入畫面 無物流-->
  <div class="payment grey-bg g-b" v-loading="mainLoading">
    <!--白色區域 -->
    <el-form ref="dataForm" :model="dataForm" :rules="rules" label-position="top">
      <div class="container box w-b" style="border-bottom-left-radius:0;border-bottom-right-radius:0;">
        <div class="top-logo">
          <el-image style="width: 80%; max-width:400px;  height: auto;" :src="logoImgUrl"></el-image>
        </div>
        <!-- 付款有效時間 -->
        <!-- 來源類型【0商店後台】【1API】 -->
        <div v-if="dataForm.sourceType=='1'">
          <div style="text-align:center;margin-top:20px;">
            <div class="payment-valid-until">
              付款有效時間至 Payment valid until
              <span style="white-space: nowrap;">{{ dataForm.dueDate }}</span>
            </div>
            <div style="display: inline-block; vertical-align: middle; white-space: nowrap;">
              <el-image class="clock-img" :src="clockImgUrl"></el-image>
              <div class="tb-txt">台北 (GMT+8)</div>
            </div>
          </div>
        </div>
        <!-- 订单資訊 -->
        <div class="or-information">
          <div style="display: flex;">
            <div class="title-24">訂單資訊</div>
            <div class="title-16">Order Information</div>
          </div>
          <el-image class="bk-img" :src="rectangleOiUrl"></el-image>
          <!-- 定期定額-->
          <div>
            <div class="mer-name-l">商店名稱 Merchant Name：</div>
            <div class="mer-name-r">{{ dataForm.merchantName }}</div>
            <div class="order-name">{{ dataForm.productName }}</div>
            <div class="quota-style">
              <div class="paymentFormItem0">
                <div class="quota-info">授權資訊 Authorization Info</div>
                <div style="padding-left: 20px;">
                  <el-form-item label="授權期數:" prop="totalTerm">
                    <div>
                      <el-input v-if="dataForm.paymentTermType=='1'" v-model="dataForm.totalTerm" prop="totalTerm"
                                style="width: 100px;"
                                @input="(v) => (dataForm.totalTerm = v.replace(/[^0-9.]/g,''))" @blur="getSumAmount()">期
                      </el-input>
                      <span style="margin-left: 20px;" v-else>{{ dataForm.totalTerm }}期</span>
                      <!-- <span slot="error">
						            <div class="error-msg">
						              <el-image :src="annotationAlertImgUrl" class="img"/>
						              <span class="txt">{{ transactionAmountMsg }}</span>
						            </div>
						          </span> -->
                    </div>
                  </el-form-item>
                  <el-form-item label="授權金額:" prop="paymentAmount">
                    <div>
                      <el-input v-if="dataForm.paymentAmountType=='1'" v-model="dataForm.paymentAmount"
                                prop="paymentAmount" style="width: 100px;"
                                @input="(v) => (dataForm.paymentAmount = v.replace(/[^0-9.]/g,''))"
                                @change="checkedIsVerification()" @blur="getSumAmount()"
                      ></el-input>
                      <span style="margin-left: 20px;" v-format="'#,##0'" v-else>{{ dataForm.paymentAmount }}</span>
                      <!-- <span slot="error">
						            <div class="error-msg">
						              <el-image :src="annotationAlertImgUrl" class="img"/>
						              <span class="txt">{{ transactionAmountMsg }}</span>
						            </div>
						          </span> -->
                    </div>

                  </el-form-item>
                  <el-form-item label="授權區間:">
                    <span style="margin-left: 20px;">{{
                        global.filterObj(paymentPeriodList, 'code', dataForm.paymentPeriod).name
                      }}</span>
                  </el-form-item>
                  <el-form-item label="授權日期:">
                    <!--                        <span style="margin-left: 20px;">{{dataForm.paymentDate}}</span>-->
                    <span style="margin-left: 20px;">{{ dataForm.paymentDateShow }}</span>
                  </el-form-item>
                </div>
              </div>
              <div class="paymentFormItem1">
                <div class="quota-amount-title">總額：</div>
                <!-- 指定日首期 & 商店設定金額-->
                <div v-if="dataForm.initialSettingType=='1' && dataForm.paymentAmountType=='0'">
                  <div class="quota-amount-a">NT $<span v-format="'#,##0'">{{ sumAmount }}</span></div>
                  <div class="quota-amount-b">
                    <el-button type="primary">每期金額：NT $<span
                        v-format="'#,##0'">{{ dataForm.eachIssueAmount }}</span>
                    </el-button>
                  </div>
                </div>
                <div v-else>
                  <div class="quota-amount-a">NT $<span v-format="'#,##0'">{{ sumAmount }}</span></div>
                  <div class="quota-amount-b">
                    <el-button type="primary">每期金額：NT $<span
                        v-format="'#,##0'">{{ dataForm.paymentAmount }}</span>
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="dataForm.initialSettingType=='1'">
            <div class="mer-name-span">※定期定額為確保付款安全及資訊正確，將發動一筆10元之授權交易，授權成功之後將立即取消授權，信用卡帳單上不會出現此筆授權交易紀錄</div>
          </div>
        </div>
        <!-- 付款方式 -->
        <!-- <div class="tit-no" v-if="matchingLogistics==1 && isExistIdentity">Step. 1 of 4</div>
	      <div class="tit-no" v-else-if="matchingLogistics!=1 && !isExistIdentity">Step. 1 of 2</div>
	      <div class="tit-no" v-else>Step. 1 of 3</div> -->
        <div class="tit-no">Step. 1 of 2</div>
        <div class="pay-method">
          <div class="tit-area">
            <div class="tit-cn">選擇付款方式</div>
            <div class="tit-en">Payment Method</div>
          </div>
          <el-image class="tit-bk" :src="rectangleOiUrl"></el-image>
          <div class="pay-form-radio">
            <el-radio-group v-model="payMethod" style="width: 100%;">
              <div class="paymentMethod">
                <el-radio label="DOMESTIC_CREDIT_CARD" class="pay-rad-txt  self-font">
                  信用卡 Credit Card
                  <el-image class="cre-card-img-1" :src="masterImgUrl"></el-image>
                  <el-image class="cre-card-img-2" :src="visaImgUrl"></el-image>
                  <el-image class="cre-card-img-3" :src="jcbImgUrl"></el-image>
                </el-radio>
              </div>
            </el-radio-group>
          </div>
        </div>
        <!-- 付款資訊 -->
        <!-- <div class="tit-no" v-if="matchingLogistics==1 && isExistIdentity" style="margin-top: 30px;">Step. 2 of 4</div>
	  <div class="tit-no" v-else-if="matchingLogistics!=1 && !isExistIdentity" style="margin-top: 30px;">Step. 2 of 2</div>
	  <div class="tit-no" v-else style="margin-top: 30px;">Step. 2 of 3</div> -->
        <div class="tit-no" style="margin-top: 30px;">Step. 2 of 2</div>
        <div class="pay-information">
          <div class="tit-area">
            <div class="tit-cn">付款資訊</div>
            <div class="tit-en">Payment Information</div>
          </div>
          <el-image class="tit-bg" :src="rectangleOiUrl"></el-image>
          <div style="margin-top: 10px;">
            <div>
              <div class="paymentFormItem">
                <!-- 信用卡卡號 -->
                <el-row>
                  <el-form-item
                      class=""
                      style="font-weight: 400;"
                      label="信用卡卡號 Card Number"
                      prop="creditCardNumber"
                      :rules="[
                        { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                        { validator: validateCreditCardNumber}
                      ]">
                    <div style="display: flex; align-items: center;">
                      <el-input
                          v-model="creditCardNumber1"
                          type="text"
                          size="small"
                          ref="creditCardNumber1"
                          @input="checkCreditCardNumberInput($event,'creditCardNumber1')"
                          style="margin-right: 5px;"
                      />
                      -
                      <el-input
                          v-model="creditCardNumber2"
                          maxlength="4"
                          size="small"
                          :type="eyeShow ? 'text' : 'password'"
                          ref="creditCardNumber2"
                          @input="checkCreditCardNumberInput($event,'creditCardNumber2')"
                          style="margin: 0px 5px;"
                      />
                      -
                      <el-input
                          v-model="creditCardNumber3"
                          maxlength="4"
                          size="small"
                          :type="eyeShow ? 'text' : 'password'"
                          ref="creditCardNumber3"
                          @input="checkCreditCardNumberInput($event,'creditCardNumber3')"
                          style="margin: 0px 5px;"
                      />
                      -
                      <el-input
                          v-model="creditCardNumber4"
                          maxlength="4" type="text"
                          size="small"
                          ref="creditCardNumber4"
                          @input="checkCreditCardNumberInput($event,'creditCardNumber4')"
                          style="margin-left: 5px;"
                      />
                      <!-- 眼睛睜眼圖 -->
                      <el-image v-show="eyeShow" @click="changeEyeShow" :src="eye" class="card-image"/>
                      <!-- 眼睛閉眼圖 -->
                      <el-image v-show="!eyeShow" @click="changeEyeShow" :src="eyeSlash" class="card-image"/>
                    </div>
                    <!-- 錯誤訊息區 -->
                    <span slot="error">
                        <div class="error-msg">
                          <!-- 驚嘆號圖片 -->
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <!-- 錯誤訊息 -->
                          <span class="txt">{{ creditCardNumberMsg }}</span>
                        </div>
                      </span>
                  </el-form-item>
                </el-row>
                <el-row :gutter="30">
                  <!-- 有效月年 -->
                  <el-col :sm="12" :xs="24">
                    <el-form-item
                        style="font-weight: 400;"
                        label="有效月年 Expiry Date"
                        prop="effectiveDate"
                        :rules="[
                          // { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                          { required: true, message: EffectiveDateErMsg, trigger: 'blur' },
                          { validator: validateEffectiveDate}
                        ]">
                      <!-- <div style="display: flex;">
                          <el-cascader
                            placeholder=""
                            class="right-image-input"
                            v-model="dataForm.effectiveDate"
                            :options="optMYList"
                            @change="handleChange"></el-cascader>
                          <el-image :src="calendarTickImgUrl" class="right-image"/>
                      </div> -->
                      <div style="display: flex;">
                        <el-input
                            placeholder="mm / yy"
                            v-model="dataForm.effectiveDate"
                            maxlength="5"
                            @input="effectiveDateCheck"
                            size="small"
                            class="right-image-input"/>
                        <!-- icon -->
                        <!--                          <el-image :src="dataForm.effectiveDate ? calendarTickImgUrl : calendarTickRImgUrl"  class="right-image"/>-->
                      </div>

                      <span slot="error">
                      <div class="error-msg">
                        <el-image :src="annotationAlertImgUrl" class="img"/>
                        <span class="txt">{{ EffectiveDateErMsg }}</span>
                      </div>
                    </span>
                    </el-form-item>
                  </el-col>
                  <!-- 末三碼 -->
                  <el-col :sm="12" :xs="24">
                    <el-form-item
                        style="font-weight: 400;"
                        label="末三碼 CVV/CVC"
                        prop="creditCardSecurityCode"
                        :rules="[
                          { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                        ]">
                      <div style="display: flex;">
                        <el-input
                            v-model="dataForm.creditCardSecurityCode"
                            maxlength="3"
                            size="small"
                            @input="(v) => (dataForm.creditCardSecurityCode = v.replace(/[^\d]/g,''))"
                            class="right-image-input"/>
                        <!-- icon -->
                        <!--                          <el-image :src="dataForm.creditCardSecurityCode ? frameImgUrl : frameRImgUrl" style="width: 33px; margin-top: 3px" class="right-image"/>-->
                      </div>
                      <span slot="error">
                          <div class="error-msg">
                            <el-image :src="annotationAlertImgUrl" class="img"/>
                            <span class="txt">{{ formErrorMsg }}</span>
                          </div>
                        </span>
                    </el-form-item>
                    <!-- <el-col :span="24" class="box-payForm-card" v-if="dataForm.merchantSaveCard == '1'">
                        <el-checkbox v-model="dataForm.saveCard" false-label="0" true-label="1" tabindex="0">
                          <div class="txt">同意記住此結帳資訊，以利下次支付</div>
                          <el-image class="img" :src="rememberTxtImgUrl"></el-image>
                        </el-checkbox>
                      </el-col> -->
                  </el-col>
                </el-row>
              </div>

              <div class="paymentFormItem">
                <!-- 付款人姓名 -->
                <el-row>
                  <el-form-item
                      style="font-weight: 400;"
                      label="付款人姓名 Payer Name"
                      prop="cardholderName"
                      :rules="[
                        { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                      ]">
                    <div style="display: flex;">
                      <el-input v-model="dataForm.cardholderName" class="right-image-input" size="small"/>
                      <!-- icon -->
                      <!--                          <el-image :src="dataForm.cardholderName ? profileTickImgUrl : profileTickRImgUrl" class="right-image"/>-->
                    </div>
                    <span slot="error">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ formErrorMsg }}</span>
                        </div>
                      </span>
                  </el-form-item>
                </el-row>
                <!-- 行動電話 -->
                <el-row>
                  <el-form-item
                      style="font-weight: 400;"
                      label="行動電話 Mobile Number"
                      prop="contactNumber"
                  >
                    <div style="display: flex;">
                      <el-input
                          @input="(v) => (dataForm.contactNumber = v.replace(/[^\d]/g,''))"
                          @change="validatePhoneNumber()"
                          v-model="dataForm.contactNumber"
                          size="small"
                          maxlength="10"
                          :rules="[
							  { validator: validateCellphoneNumber}
							]"
                          class="right-image-input">
                        <!--							<template slot="prepend">+886</template>-->
                      </el-input>
                      <!-- icon -->
                      <!--                          <el-image :src="dataForm.contactNumber ? componentImgUrl : componentRImgUrl" class="right-image"/>-->

                    </div>
                    <span slot="error">
					    <div class="error-msg">
					      <el-image :src="annotationAlertImgUrl" class="img"/>
					      <span class="txt">{{ cellphoneNumberMsg }}</span>
					    </div>
					  </span>
                  </el-form-item>
                </el-row>
                <!-- 信箱 -->
                <el-row>
                  <el-form-item
                      style="font-weight: 400;"
                      label="信箱 Email"
                      prop="email"
                      :rules="[
                        { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                        { type: 'email', message: '請輸入正確的信箱', trigger: ['blur', 'change'] }
                      ]">
<!--                    <div style="position: absolute; top: -25px; right: 0px;">-->
<!--                      <div-->
<!--                          style="display: flex; flex-direction: row; line-height: 12px; position: absolute; right: 0px;">-->
<!--                        <el-image style="width: 14px; height: 14px;" :src="annotationCheckImgUrl"/>-->
<!--                        &lt;!&ndash; <el-image style="width: 230px;" :src="emailTxtCNImgUrl"/> &ndash;&gt;-->
<!--                        <div style="color: #1DB145; font-size: 0.6rem; font-weight: 400; margin-left: 2px; ">-->
<!--                          紅陽科技將會寄發交易結果通知信至此Email-->
<!--                        </div>-->
<!--                      </div>-->

<!--                      <div style="line-height: 8px; text-align: end; margin-top: 14px;">-->
<!--                        <div style="color: #1DB145; font-size: 0.6rem; font-weight: 400; margin-left: 2px;">SUNPAY will-->
<!--                          Send a Payment Notification to this mail.-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
                    <div style="display: flex;">
                      <el-input v-model="dataForm.email" class="right-image-input" size="small"/>
                      <!-- icon -->
                      <!--                          <el-image :src="dataForm.email ? mailImgUrl : mailRImgUrl" class="right-image"/>-->
                    </div>
                    <!-- 提示訊息 -->
                    <div style="line-height: 14px; font-size: 12px; margin: 5px 0">
                      <div style="display: flex; flex-direction: row;">
                        <el-image style="width: 16px; height: 16px;" :src="annotationCheckImgUrl"/>
                        <div style="color: #1DB145; font-weight: 400; margin-left: 2px; ">
                          紅陽科技將會寄發交易結果通知信至此Email
                        </div>
                      </div>
                      <div style="color: #1DB145; font-weight: 400; margin-left: 18px;">SunPay will
                        send a transaction notification to this email address.
                      </div>
                    </div>
                    <!-- 錯誤訊息 -->
                    <span slot="error" v-if="!dataForm.email">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ formErrorMsg }}</span>
                        </div>
                      </span>
                    <span slot="error" v-else>
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">請輸入正確的信箱</span>
                        </div>
                      </span>
                  </el-form-item>
                </el-row>
              </div>
            </div>

            <!-- 發票 invoiceType發票開立狀態【0不開立】【1開立】-->
            <div v-if="dataForm.invoiceType == '1'">
              <el-row :gutter="50" style="top: -10px;">
                <el-col :span="24">
                  <el-form-item
                      style="font-weight: 400;"
                      label="發票 Invoice"
                      prop="payMethods"
                  >
                    <div class="pay-form-radio">
                      <el-radio-group style="width: 100%;" v-model="dataForm.invoiceStatus"
                                      @change="changeInvoiceStatus">
                        <el-radio label="0" :checked="dataForm.invoiceStatus == 0" class="pay-rad-txt ">個人發票</el-radio>
                        <el-radio label="2" :checked="dataForm.invoiceStatus == 2" class="pay-rad-txt ">手機條碼</el-radio>
                        <el-radio label="1" :checked="dataForm.invoiceStatus == 1" class="pay-rad-txt ">自然人憑證條碼
                        </el-radio>
                        <el-radio label="3" :checked="dataForm.invoiceStatus == 3" class="pay-rad-txt ">公司統編</el-radio>
                        <el-radio label="4" :checked="dataForm.invoiceStatus == 4" class="pay-rad-txt ">捐贈</el-radio>
                      </el-radio-group>
                    </div>
                    <div v-if="dataForm.invoiceStatus == 0 || dataForm.invoiceStatus == 3" class="pay-form-check">

                      <el-form-item label="" v-if="invoicePaperShow == '1'">
                        <el-checkbox v-model="invoicePaperChecked" @change="changePaperCheck()"
                                     style="font-weight: 400;">索取紙本發票
                        </el-checkbox>
                      </el-form-item>

                      <div style="margin-top: -10px;"
                           v-if="dataForm.invoiceStatus == 0 && dataForm.invoiceShowFlg == 2">
                        <el-form-item
                            style="font-weight: 400;"
                            label="若無勾選索取紙本發票，個人發票預設存入會員載具，若中獎將另行通知"
                        >
                        </el-form-item>
                      </div>

                      <div style="margin-top: 0px;" v-if="dataForm.invoiceStatus == 3">
                        <el-form-item
                            style="font-weight: 400;"
                            label="紅陽科技將寄發電子發票開立通知至您的Mail，可點擊信件内明細連結執行發票列印"
                        >
                        </el-form-item>
                        <div style="display: flex; flex-direction: row;">
                          <el-form-item
                              style="font-weight: 400; width: 140px;"
                              label=""
                              label-position="left"
                          >
                            <div style="font-size: 16px; color: #525252;"><span
                                style="color:#D41831 !important;margin-right: 4px;">*</span>發票抬頭
                            </div>
                          </el-form-item>
                          <el-form-item
                              prop="invoiceHead"
                              style="width:420px;margin-top: 20px!important;"
                          >
                            <el-input class="ol-el-input" style="max-width: 420px" maxlength="60"
                                      v-model="dataForm.invoiceHead"></el-input>
                            <span slot="error" v-if="!dataForm.invoiceHead">
                              <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">{{ formErrorMsg }}</span>
                              </div>
                            </span>
                          </el-form-item>
                        </div>
                        <div style="display: flex; flex-direction: row;">
                          <el-form-item
                              style="font-weight: 400; width: 140px;"
                              label=""
                              label-position="left"
                          >
                            <div style="font-size: 16px; color: #525252;"><span
                                style="color:#D41831 !important;margin-right: 4px;">*</span>買受人統編
                            </div>
                          </el-form-item>
                          <el-form-item
                              prop="identifier"
                              style="width:420px;margin-top: 20px!important;"
                          >
                            <el-input class="ol-el-input" style="max-width: 420px" maxlength="8"
                                      v-model="dataForm.identifier"></el-input>
                            <span slot="error" v-if="!dataForm.identifier">
                              <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">{{ formErrorMsg }}</span>
                              </div>
                            </span>
                            <span slot="error" v-else>
                              <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">請輸入正確買受人統編格式</span>
                              </div>
                            </span>
                          </el-form-item>
                        </div>
                      </div>

                      <div style="display: flex; flex-direction: row;"
                           v-if="invoicePaperChecked && invoicePaperShow == '1'">
                        <el-form-item
                            style="font-weight: 400; width: 140px; margin-top: 12px;"
                            label=""
                            label-position="left"
                        >
                          <div style="font-size: 16px; color: #525252;"><span
                              style="color:#D41831 !important;margin-right: 4px;">*</span>發票地址
                          </div>
                          <span v-if="!dataForm.city || !dataForm.area || !dataForm.address">
                              <div class="error-msg">
                                <!-- <el-image :src="annotationAlertImgUrl" class="img"/> -->
                                <span class="txt">必填欄位不能為空</span>
                              </div>
                            </span>
                        </el-form-item>
                        <div style="width: 100%; max-width: 420px;margin-top: 20px;">
                          <div class="invoiceItem">
                            <el-select v-model="dataForm.city" clearable placeholder="選擇城市" filterable
                                       @clear="clearSelect('dataForm','area')"
                                       @change="choseCity($event)">
                              <el-option v-for="(item,index) in cityList" :key="item.cityId" :label="item.cityName"
                                         :value="item.cityId"></el-option>
                            </el-select>
                          </div>
                          <div class="invoiceItem" style="margin-left: 10px;">
                            <el-select v-model="dataForm.area" clearable filterable placeholder="選擇地區">
                              <el-option v-for="(item,index) in areaList" :key="item.areaId" :label="item.areaName"
                                         :value="item.areaId"></el-option>
                            </el-select>
                          </div>
                          <el-input v-model="dataForm.address" type="text" style="margin-top: 20px;"></el-input>
                        </div>
                      </div>

                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div v-if="dataForm.invoiceStatus">

              <div style="margin-top: 0px;" v-if="dataForm.invoiceStatus == 1">
                <!-- 1自然人憑證條碼 -->
                <div style="display: flex; flex-direction: row;">
                  <el-form-item
                      style="font-weight: 400; width: 140px;"
                      label=""
                      label-position="left"
                  >
                    <div style="font-size: 16px; color: #525252;"><span
                        style="color:#D41831 !important;margin-right: 4px;">*</span>載具號碼
                    </div>
                  </el-form-item>
                  <el-form-item
                      prop="carrierIdPerson"
                      style="margin-top: 0!important;width:420px;"
                  >
                    <el-input class="ol-el-input" style="max-width: 420px;" :maxlength="16"
                              v-model="dataForm.carrierIdPerson"></el-input>

                    <span slot="error" v-if="!dataForm.carrierIdPerson">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ formErrorMsg }}</span>
                        </div>
                      </span>
                    <span slot="error" v-else>
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">請輸入正確載具號碼格式</span>
                        </div>
                      </span>
                  </el-form-item>
                </div>
              </div>

              <div style="margin-top: 0px;" v-if="dataForm.invoiceStatus == 2">
                <!-- 2手機條碼 -->
                <div style="display: flex; flex-direction: row;">
                  <el-form-item
                      style="font-weight: 400; width: 140px;"
                      label=""
                      label-position="left"
                  >
                    <div style="font-size: 16px; color: #525252;"><span
                        style="color:#D41831 !important;margin-right: 4px;">*</span>載具號碼
                    </div>
                  </el-form-item>
                  <el-form-item
                      prop="carrierIdMobile"
                      style="margin-top: 0!important;width:420px;"
                  >
                    <el-input class="ol-el-input" style="max-width: 420px;" :maxlength="8"
                              v-model="dataForm.carrierIdMobile"></el-input>

                    <span slot="error" v-if="!dataForm.carrierIdMobile">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ formErrorMsg }}</span>
                        </div>
                      </span>
                    <span slot="error" v-else>
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">請輸入正確載具號碼格式</span>
                        </div>
                      </span>
                  </el-form-item>
                </div>
              </div>

              <div style="margin-top: 0px;" v-if="dataForm.invoiceStatus == 4">
                <!-- 4捐贈碼 -->
                <div style="display: flex; flex-direction: row;">
                  <el-form-item
                      style="font-weight: 400; width: 140px;"
                      label=""
                      label-position="left"
                  >
                    <div style="font-size: 16px; color: #525252;"><span
                        style="color:#D41831 !important;margin-right: 4px;">*</span>捐贈號碼
                    </div>
                  </el-form-item>
                  <el-form-item
                      prop="poban"
                      style="width: 420px;"
                  >
                    <el-select clearable filterable v-model="dataForm.poban" style="width:420px;">
                      <el-option :key="item.poban" :label='item.poban+"-"+item.pobanName'
                                 :value=item.poban
                                 v-for="(item,index) in pobanList"></el-option>
                    </el-select>
                    <span slot="error">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ formErrorMsg }}</span>
                        </div>
                      </span>
                  </el-form-item>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div v-if="isExistIdentity" style="display: flex;flex-direction: column; margin-top: 20px;">
          <div class="tit-no">Step. 3 of 3</div>
          <div class="pay-method">
            <div class="tit-area" style="display: flex;">
              <div style="font-size: 24px; font-weight: 600; color: #03328D;">身分驗證資訊</div>
              <div style="font-size: 16px; font-weight: 600; color: #03328D; line-height: 42px; margin-left: 10px;">
                Identity Verification
              </div>
            </div>
            <el-image style="width: 296px; height: 24px; margin-top: 16px; margin-left: 12px; position: absolute;"
                      :src="rectangleOiUrl"></el-image>
            <div>
              <div class="identity-tips">
                <div>依「第三方支付服務業防制洗錢及打擊資恐辦法」之規定，訂單已達法定金額，請完成身分驗證。</div>
                <div>In accordance with the "Regulations
                Governing Anti-Money Laundering and Countering the Financing of Terrorism for the Third-Party Payment
                  Enterprises", the order has reached the legal amount, please complete the identity verification.</div>
              </div>
              <div class="identity-tips">
                <div>您的身分證資料僅用於內政部戶役司之查驗作業，不會用於其他目的或提供給第三方。</div>
                <div>Your ID information will only be used for
                verification purposes by the Ministry of the Interior, Household Registration Office, and will not be
                used for any other purposes or provided to third parties.</div>
              </div>
              <div class="identity-title">手機號碼驗證(OTP)　Cellphone Number Verification</div>
              <div style="margin-bottom: 20px;" class="paymentFormItem">
                <el-row>
                  <el-form-item
                      style="font-weight: 400;"
                      label="持卡人手機 Cardholder Cellphone Number"
                      prop="cellphoneNumber"
                      :rules="isExistIdentity?[
                         { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                         { validator: validateCellphoneNumber}

                       ]:''"
                  >
                    <div style="display: flex;">
                      <el-input
                          v-model="dataForm.cellphoneNumber"
                          @input="changeCellphoneNumber($event)"
                          maxlength="10"
                          size="small"
                          class="right-image-input"/>
                      <!-- icon -->
                      <!--                    <el-image :src="dataForm.cellphoneNumber.length==10 ? componentImgUrl : componentRImgUrl" class="right-image"/>-->
                    </div>
                    <span slot="error">
                         <div class="error-msg">
                           <el-image :src="annotationAlertImgUrl" class="img"/>
                           <span class="txt">{{ cellphoneNumberMsg }}</span>
                         </div>
                       </span>
                  </el-form-item>
                  <div style="text-align: end;" v-if="verificationType == 1">
                    <!-- sendCodeStatus:0默認可點 1xx秒後不可點  2重發-可點 3次數已過-->
                    <el-button style="background: #8F0304;padding: 14px 10px;border-radius: 10px;color:white;"
                               @click="getVerificationCode()" v-if="sendCodeStatus!='3'"
                               :disabled="dataForm.cellphoneNumber==''|| checkPhone(dataForm.cellphoneNumber) || sendCodeStatus=='1' || cellphoneNumberErr">
                      {{ sendCodeStatus == '0' ? '發送驗證碼 Send Verification Code' : sendCodeStatus == '1' ? count + '秒後重新發送 Resend in ' + count + ' Sec' : '重新發送驗證碼 Resend verification Code' }}
                    </el-button>
                    <el-button v-else
                               style="color: #8F0304;background: transparent;padding: 14px 10px;border: 0px;font-weight: 600;"
                               disabled>發送次數已滿 Verification limit reached
                    </el-button>
                  </div>
                </el-row>
              </div>
              <template v-if="verificationType == 1">
                <div style="margin-bottom: 20px;" class="paymentFormItem">
                  <el-row>
                    <el-form-item
                        style="font-weight: 400;"
                        label="簡訊驗證碼 SMS Verification Code"
                        prop="verificationCode"
                        :rules="isExistIdentity?[
                             { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                             { validator: validateVerificationCode}
                           ]:''"
                    >
                      <div style="display: flex;">
                        <el-input
                            @input="verificationCodeCheck($event)"
                            maxlength="6"
                            size="small"
                            v-model="dataForm.verificationCode"
                            class="right-image-input"/>
                        <!-- icon -->
                        <!--                      <el-image :src="dataForm.verificationCode.length==6 ? smsVerificationImgUrl : smsNoVerificationImgUrl" class="right-image"/>-->
                      </div>
                      <span v-if="verificationCodeErr">
                             <div class="error-msg">
                               <el-image :src="annotationAlertImgUrl" class="img"/>
                               <span class="txt">驗證碼錯誤{{ errCount }}次，累計錯誤3次此筆交易將無效</br>
                                 Verification failed once, if there are 3 times in total, the transaction will be invalid.</span>
                             </div>
                           </span>
                      <span slot="error" v-else>
                             <div class="error-msg">
                               <el-image :src="annotationAlertImgUrl" class="img"/>
                               <span class="txt">{{ verificationCodeMsg }}</span>
                             </div>
                           </span>
                    </el-form-item>
                  </el-row>
                </div>
                <div class="identity-title2">身分驗證　ID Verification</div>
                <div class="error-msg" v-if="verificationIDErr">
                  <el-image :src="annotationAlertImgUrl" class="img"/>
                  <span class="txt">身份驗證失敗，請重新確認 Identity verification failed, please reconfirm.</span>
                </div>
                <div class="paymentFormItem2">
                  <el-row>
                    <el-form-item
                        class=""
                        style="font-weight: 400;"
                        label="身分證字號 ID Card Number"
                        prop="idCardNumber"
                        :rules="isExistIdentity?[
                           { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                           { validator: validateIdCardNumber}
                         ]:''">
                      <div style="display: flex;">
                        <el-input
                            v-model="dataForm.idCardNumber"
                            maxlength="10"
                            size="small"
                            @input="idCardNumberCheck($event)"
                            class="right-image-input"/>
                        <!-- icon -->
                        <!--                      <el-image :src="dataForm.idCardNumber.length==10 ? cardImgUrl : cardRImgUrl" class="right-image"/>-->
                      </div>
                      <span slot="error">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ idCardNumberMsg }}</span>
                           </div>
                         </span>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item
                        style="font-weight: 400;"
                        label="發證日期 Issuance Date"
                        prop="issuanceDate"
                        :rules="isExistIdentity?[
                           { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' }
                         ]:''"
                    >
                      <div style="display: flex;">
                        <el-select v-model="selectedYear" size="small" placeholder="年Year" @change="changeYear()">
                          <el-option
                              v-for="(item,index) in yearOptions"
                              :key="item"
                              :label="item"
                              :value="item">
                          </el-option>
                        </el-select>
                        <span>&nbsp/&nbsp</span>
                        <el-select v-model="selectedMonth" size="small" clearable placeholder="月Month"
                                   @change="mothChange(dataForm.selectedMonth)">
                          <el-option v-for="(item,index) in monthList" :key="item.value" :label="item.label"
                                     :value="item.value" :disabled="item.disable"></el-option>
                        </el-select>
                        <span>&nbsp/&nbsp</span>
                        <el-select v-model="selectedDay" size="small" clearable placeholder="日Day">
                          <el-option v-for="(item,index) in dayList" :key="item.value" :label="item.label"
                                     :value="item.value" :disabled="item.disable"></el-option>
                        </el-select>
                      </div>
                      <span v-if="issuanceDateIsEmpty==''">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ formErrorMsg }}</span>
                           </div>
                         </span>

                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item
                        style="font-weight: 400;"
                        label="身分證正反面照片 ID Card Photo"
                        prop="idCardPhotoFrontResourceId"
                        :rules="isExistIdentity?[
                           { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' }
                         ]:''"
                    >
                      <div style="font-size: 16px;font-weight: 400;letter-spacing: 0.9px;">正面 Front</div>
                      <div style="background-color: #EDEDED; border-radius: 8px; width: 100%; text-align: center;">
                        <el-image :src="idCard1" style="width: 80%; margin: 0 auto;" v-if="idCard1"></el-image>
                        <el-upload
                            class="upload-demo"
                            :action="$http.adornUrl('/common/resource/uploadResource')"
                            :on-success="icCardFrontSuccess"
                            accept=".jpg,.png,.jpeg"
                            multiple>
                          <el-button size="small" type="danger" style="background-color: #8F0304" v-if="idCard1">變更
                            Change
                          </el-button>
                          <el-button size="small" type="danger"
                                     style="background-color: #8F0304; margin: 100px 0 90px 0;" v-else>上傳 Upload
                          </el-button>
                          <div slot="file" slot-scope="{file}"></div>
                        </el-upload>
                      </div>
                      <span slot="error">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ formErrorMsg }}</span>
                           </div>
                         </span>
                    </el-form-item>
                  </el-row>
                </div>
                <div class="paymentFormItem" style="margin-top: 12px;">
                  <el-row>
                    <el-form-item
                        style="font-weight: 400;"
                        label="發證地點 Issuance Location"
                        prop="issuanceLocation"
                        :rules="isExistIdentity?[
                           { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                         ]:''">
                      <div style="display: flex;">
                        <el-select v-model="dataForm.issuanceLocation" placeholder="" style="width: 100%;">
                          <el-option
                              v-for="item in issuanceLocationList"
                              :key="item.code"
                              :label="item.name"
                              :value="item.code">
                          </el-option>
                        </el-select>
                      </div>
                      <span slot="error">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ formErrorMsg }}</span>
                           </div>
                         </span>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item
                        style="font-weight: 400;"
                        label="領補換類別 Issuance Type"
                        prop="issuanceType"
                        :rules="isExistIdentity?[
                         { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                       ]:''"
                    >
                      <div style="display: flex;">
                        <el-select v-model="dataForm.issuanceType" placeholder="" style="width: 100%;">
                          <el-option
                              v-for="item in issuanceTypeList"
                              :key="item.code"
                              :label="item.name"
                              :value="item.code">
                          </el-option>
                        </el-select>
                      </div>
                      <span slot="error">
                         <div class="error-msg">
                           <el-image :src="annotationAlertImgUrl" class="img"/>
                           <span class="txt">{{ formErrorMsg }}</span>
                         </div>
                       </span>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item
                        style="font-weight: 400;"
                        label=""
                        prop="idCardPhotoBackResourceId"
                        :rules="isExistIdentity?[
                           { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' }
                         ]:''"
                    >
                      <div style="font-size: 16px;font-weight: 400;letter-spacing: 0.9px; margin-top: 28px;">反面 Back
                      </div>
                      <div style="background-color: #EDEDED; border-radius: 8px; width: 100%; text-align: center;">
                        <el-image :src="idCard2" style="width: 80%; margin: 0 auto;" v-if="idCard2"></el-image>
                        <el-upload
                            class="upload-demo"
                            :action="$http.adornUrl('/common/resource/uploadResource')"
                            :on-success="icCardBackSuccess"
                            accept=".jpg,.png,.jpeg"
                            multiple>
                          <el-button size="small" type="danger" style="background-color: #8F0304" v-if="idCard2">變更
                            Change
                          </el-button>
                          <el-button size="small" type="danger"
                                     style="background-color: #8F0304; margin: 100px 0 90px 0;" v-else>上傳 Upload
                          </el-button>
                          <div slot="file" slot-scope="{file}"></div>
                        </el-upload>
                      </div>
                      <span slot="error">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ formErrorMsg }}</span>
                           </div>
                         </span>
                    </el-form-item>
                  </el-row>
                </div>
              </template>
              <template v-if="verificationType == 2">
                <div style="margin-bottom: 20px;" class="paymentFormItem">
                  <el-row>
                    <el-form-item
                        style="font-weight: 400;"
                        label="持卡人身分證字號末三碼 Last three digits of ID"
                        prop="idCardNumberSecurityCode"
                        :rules="isExistIdentity?[
                             { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                           ]:''"
                    >
                      <div style="display: flex;">
                        <el-input
                            @input="verificationCodeCheck($event)"
                            maxlength="3"
                            v-model="dataForm.idCardNumberSecurityCode"
                            class="right-image-input"/>
                        <!-- icon -->
                        <!--                      <el-image :src="dataForm.creditCardNumber ? cardImgUrl : cardRImgUrl" class="right-image" />-->
                      </div>
                      <span slot="error">
                             <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">{{ formErrorMsg }}</span>
                             </div>
                         </span>
                      <div class="error-msg" v-if="verificationIDThreeErr">
                        <el-image :src="annotationAlertImgUrl" class="img"/>
                        <span class="txt">身分證資訊錯誤 ID information incorrect</span>
                      </div>
                    </el-form-item>
                  </el-row>
                </div>
              </template>
            </div>
          </div>
        </div>
        <!-- 服務條款 -->
        <div>
          <!-- 警示語框框 -->
          <div style="border-radius:10px; background: #FAF0CA;margin: 30px 0;padding: 20px;">
            <div class="box-txt-rd">請主動求證付款資訊來源，切勿將個人資料提供給來路不明的第三方，若發現有不明管道之訊息可撥打165反詐騙諮詢專線。</div>
            <div class="box-txt-rd">Please proactively verify the source of payment information and refrain from
              providing personal information to unknown third parties. If you suspect you could be victim of a scam,
              protect your rights by calling the "165 Anti-Fraud Hotline".
            </div>
          </div>
          <div style="margin-bottom: 50px;">
            <div class="information-form-check">
              <!-- 同意服務條款勾選框 -->
              <el-checkbox v-model="informationCk">
                <div class="box-txt-bl-b" style="color: #4F4F4F;">本人理解且同意<a
                    href="#" @click.prevent="showChineseDetail('privacy-law')" class="link">個人資料提供</a>及<a
                    href="#" @click.prevent="showChineseDetail('service-terms')" class="link">服務契約</a>之內容。
                </div>
                <div class="box-txt-bl-b" style="color: #4F4F4F;">I agree to <a
                    href="#" @click.prevent="showEnglishDetail('privacy-law')" class="link">the Contents for Provision
                  of Personal Data</a> and <a
                    href="#" @click.prevent="showEnglishDetail('service-terms')" class="link">the Service Agreement</a>.
                </div>
              </el-checkbox>

              <!-- 服務條款浮動視窗 -->
              <el-dialog :visible.sync="showPopup">
                <!-- 標籤、標題 -->
                <div class="fixed-tabs">
                  <el-tabs v-model="activeTab" type="card">
                    <!-- 個人資料提供同意書 -->
                    <el-tab-pane :label="getTabLabel('privacy-law')" name="privacy-law">
                      <h2 class="title">{{ getTitle('privacy-law') }}</h2>
                      <el-button @click="switchLanguage" class="language-btn" type="info">
                        {{ currentLanguage === 'chinese' ? 'English' : '中文' }}
                      </el-button>
                    </el-tab-pane>
                    <!-- 紅陽科技網路代理收付服務契約 -->
                    <el-tab-pane :label="getTabLabel('service-terms')" name="service-terms">
                      <h2 class="title">{{ getTitle('service-terms') }}</h2>
                      <el-button @click="switchLanguage" class="language-btn" type="info">
                        {{ currentLanguage === 'chinese' ? 'English' : '中文' }}
                      </el-button>
                    </el-tab-pane>
                    <!-- 消費者個資移轉商店使用同意書 -->
                    <!--                  <el-tab-pane :label="getTabLabel('data-transfer')" name="data-transfer">-->
                    <!--                    <h2 class="title">{{ getTitle('data-transfer') }}</h2>-->
                    <!--                    <el-button @click="switchLanguage" class="language-btn" type="info">-->
                    <!--                      {{ currentLanguage === 'chinese' ? 'English' : '中文' }}-->
                    <!--                    </el-button>-->
                    <!--                  </el-tab-pane>-->
                  </el-tabs>
                </div>
                <!-- 服務條款詳細內容 -->
                <div class="popup-content">
                  <!-- 個人資料提供同意書 -->
                  <div v-if="activeTab === 'privacy-law'" class="details">
                    <div v-if="currentLanguage === 'chinese'">
                      <div>
                        紅陽科技股份有限公司(下稱「本公司」)，為提供您與本公司間各項往來之交易或服務、依中華民國法令及本公司主管機關法令規定及金融監理需要，依法定義務、依契約、類似契約關係或其他法律關係等，所為個人資料之蒐集、處理及利用及國際傳輸，茲依據個人資料保護法（下稱「個資法」）及其施行細則之規定，由本公司告知下列事項：(一)蒐集之目的
                        (二)個人資料之類別 (三)個人資料利用之期間、地區、對象及方式 (四)當事人依個資法第三條規定得行使之權利及方式 (五)當事人得自由選擇提供個人資料時，不提供將對其權益之影響。
                      </div>
                      <br>
                      <div>
                        一、有關本公司蒐集您個人資料之目的、類別及個人資料利用之期間、地區、對象及方式等內容分述如下。但本公司實際蒐集、處理及利用資料之類別、使用目的、地區、對象及方式，仍以您與本公司實際往來之相關業務或服務所涉者為準。
                      </div>
                      <div>
                        <span style="font-weight: bolder">(一) 蒐集目的</span><br>
                        本公司於蒐集您個人資料之特定目的為「契約、類似契約或其他法律關係事務」、「消費者、客戶管理與服務」、「商業與技術資訊」、「資（通）訊服務」、「網路購物及其他電子商務服務」、「調查、統計與研究分析」、「營業登記項目或章程所定業務或其他法令所准許之各項目的」、「業務或營運管理之目的(包括但不限於客戶管理、稅務、諮詢顧問、行政研究、統計調查分析、資訊與資料庫管理、輔助性與後勤支援、風險控管、執行洗錢防制作業及配合全球打擊恐怖份子調查等目的」、「進行認識客戶（Know
                        Your Customers）之程序」、及「委託他人處理開立電子發票事宜」，而蒐集、處理、利用及國際傳輸涉及您的個人資料。
                      </div>
                      <div>
                        <span style="font-weight: bolder">(二) 蒐集類別</span><br>
                        姓名、出生年月日、性別、地址、電話、電子郵件、網路位址、交易地點、交易時間、交易內容、信用卡號碼(個人資料類別：
                        C001、C002、C003、C091、C092、C093、C101、C102)。
                      </div>
                      <div>
                        <span style="font-weight: bolder">(三) 個人資料利用期間、地區、對象及方式</span><br>
                        本公司依相關法令所定(例如商業會計法等)或因執行業務所必須之保存期間，將於本公司及合作夥伴的營運所及地區(含中華民國境內及未經主管機關禁止國際傳輸的境外地區)利用您的個人資料向你寄送上述訂購產品訊息、付款資訊、收據、活動資訊及您授權提供的信用卡相關資料透過自動扣款方式繳付相關帳款、暨就您所提出之詢問或申請需求，核對您的個人資料，並依個案透過電子郵件、電話、簡訊或其他您提供之連絡方式，使用、處理您的個人資料以回覆您的需求；或於前開第一條第(一)項「蒐集目的」之「必要的範圍內」，將您的資料提供予本公司或與本公司因業務需要訂有契約關係或業務往來機構或依法有權機關或金融監理機關；包含本公司次特約商店、發卡銀行、收單機構、往來合作廠商等。以達上述蒐集之目的。
                      </div>
                      <div>
                        <span style="font-weight: bolder">(四) 當事人權利及行使方式</span><br>
                        依據個資法相關規定，您就本公司保有之個人資料得行使下列權利：<br>
                      </div>
                      <div class="cn-numbered-list">
                        1、除有個資法第十條所規定之例外情形外，得向本公司查詢、請求閱覽或請求製給複製本，惟本公司依個資法第十四條規定得酌收必要成本費用。<br>
                      </div>
                      <div class="cn-numbered-list">
                        2、得向本公司請求補充或更正，惟依個資法第十九條規定，您應適當釋明其原因及事實。<br>
                      </div>
                      <div class="cn-numbered-list">
                        3、本公司如有違反個資法規定蒐集、處理或利用個人資料，依個資法第十一條第四項規定，您得向本公司請求停止蒐集、處理或利用。<br>
                      </div>
                      <div class="cn-numbered-list">
                        4、依個資法第十一條第二項規定，個人資料正確性有爭議者，得向本公司請求停止處理或利用個人資料。惟依該項但書規定，本公司因執行業務所必須並註明其爭議或經您書面同意者，不在此限。<br>
                      </div>
                      <div class="cn-numbered-list">
                        5、依個資法第十一條第三項規定，個人資料蒐集之特定目的消失或期限屆滿時，得向本公司請求刪除、停止處理或利用個人資料。惟依該項但書規定，本公司因執行業務所必須或經您書面同意者，不在此限。
                      </div>
                      <br>
                      <div>
                        二、若您擬行使上述任一權利，請檢附相關證明文件向本公司客服信箱(service@esafe.com.tw)提出請求，本公司就該書面請求之決定，將於收到書面請求之次日起15日內以書面通知申請人。前述15日期間於必要時，得予再延長15日，本公司並將以書面通知申請人。如係請求查詢或閱覽文件，申請人應於收受本公司通知後，於通知指定之期間內，至本公司通知函指定之地點查詢或閱覽。如申請人逾期未查詢或閱覽文件者，需向本公司重新提出書面請求。
                      </div>
                      <br>
                      <div>
                        三、您得自由選擇是否提供有關個人資料，惟若拒絕提供相關個人資料係本公司辦理業務審核或作業所需之資料，本公司可能無法進行必要之業務審核或作業而無法提供您相關服務，本公司有權依其判斷決定是否同意處理您的申請。
                      </div>
                      <br>
                      <div>
                        四、本公司有權修訂本同意書，並於修訂後，得以言詞、書面、電話、簡訊、電子郵件、傳真、電子文件或其他足以使您知悉或可得知悉之方式（包括但不限於以前述方式告知提供詳載本同意書內容之網站連結），告知修訂要點及指定網頁，屆時，請詳閱指定網頁內容。
                      </div>
                      <br>
                      <div>
                        五、如您與本公司先前簽訂之契約或文件所定與個人資料之蒐集、處理、利用、國際傳輸有關條款與本同意書有所歧異者，以本同意書為準。
                      </div>
                    </div>
                    <div v-else>
                      <div>
                        Sun Tech Co., Ltd (hereinafter referred to as "the Company") hereby informs you of the following
                        matters in accordance with the provisions of the Personal Information Protection Act (the “Act”)
                        and its implementing regulations: (1) the purpose of collection (2) the type of personal
                        information (3) the period of utilization of personal information (4) the purpose of collection
                        In accordance with the provisions of the Personal Information Protection Act (“PDPA”) and its
                        implementing regulations, the Company hereby informs you of the following: (1) the purpose of
                        collection (2) the type of personal information (3) the period, region, target and method of
                        utilization of the personal information (4) the rights and methods the subject may exercise in
                        accordance with Article 3 of the PDPA and the manner of exercising the rights and benefits; and
                        (5) the impact on the rights and interests of the subject if the subject may choose to provide
                        the personal information, and if the subject may choose to provide the information but not to
                        provide the information.
                      </div>
                      <br>
                      <div>
                        I. The purposes and types of personal information collected by the Company and the period,
                        region, target and manner of utilization of your personal information are described below.
                        However, the types of data collected, processed and utilized by the Company, the purpose of use,
                        the region, the target group and the manner of use shall be based on the actual business or
                        service you have with the Company.
                      </div>
                      <div>
                        <span style="font-weight: bolder">(1) Purposes of Collection</span><br>
                        The specific purposes for which we collect your personal information are “contractual,
                        contract-like or other legal relationship matters”, “consumer, customer management and
                        services”, “business and technical information”, “information (communication) services”, “online
                        shopping and other e-commerce services”, “surveys, statistics and research and analysis”,
                        “business as defined in the business registration or articles of association or for purposes
                        permitted by other laws and regulations”, “business or operation management purposes (including
                        but not limited to customer management, tax, consulting, administrative research, statistical
                        surveys and analysis, information and databases, information and database)”, and “business or
                        operation management purposes (including but not limited to customer management, tax,
                        consulting, administrative research, statistical surveys and analysis, information and database,
                        information and database, information and communication services)”. business or operational
                        management purposes (including, but not limited to, customer management, tax, consulting,
                        administrative research, statistical research and analysis, information and database management,
                        auxiliary and logistical support, risk control, enforcement of money laundering prevention
                        operations, and cooperating with the Global Counterterrorism Investigation),” ‘Know Your
                        Customers’, and ”Delegating the Process of To collect, process, utilize and internationally
                        transfer personal information about you for the purposes of “Know Your Customers”, and
                        “Delegating the Process of Issuing Electronic Invoices”.
                      </div>
                      <div>
                        <span style="font-weight: bolder">(2) Types of Data Collected</span><br>
                        Name, date of birth, gender, address, telephone number, e-mail address, Internet address, place
                        of transaction, time of transaction, content of transaction, credit card number (Categories of
                        personal information: C001, C002, C003, C091, C092, C093, C101, C102).
                      </div>
                      <div>
                        <span style="font-weight: bolder">(3) Period, area, target and method of utilization of personal information</span><br>
                        In accordance with relevant laws and regulations (e.g., the Business Accounting Law, etc.) or
                        during the retention period necessary for the execution of business, the Company will use your
                        personal information to send you the above product ordering information, payment information,
                        receipts, activity information, and credit card related information that you have authorized to
                        provide by means of automatic debit, to pay related bills, and to verify your personal
                        information in response to your inquiries or application needs, as well as to verify your
                        personal information. (b) to use your personal information to send you the above product order
                        information, payment information, receipts, event information and credit card related
                        information you authorized to provide to make payment by automatic debit, and to verify your
                        personal information in response to your inquiries or applications, and to use and process your
                        personal information to respond to your requests on a case-by-case basis via e-mail, telephone,
                        SMS or other contact methods you provided; or (c) to provide your information to the Company or
                        to have a contractual relationship or a business relationship with the Company due to a business
                        need or a contractual relationship with a business organization that has a contractual
                        relationship or a contractual relationship with a business organization that has a contractual
                        relationship with a business organization due to a business need or a contractual relationship
                        with the Company. In the “scope of necessity” of the “Purpose of Collection” in the preceding
                        Article I , Paragraph (1), your information will be provided to the Company or to any
                        organization that has a contractual relationship or business relationship with the Company for
                        business purposes, or to any organization that has the authority under the law, or to any
                        financial supervisory authority, including the Company's sub-specialized stores, card-issuing
                        banks, acquirers, and past partners. The purpose of the collection is to fulfill the above
                        mentioned purposes.
                      </div>
                      <div>
                        <span
                            style="font-weight: bolder">(4) Rights of the person concerned and how to exercise them</span><br>
                        In accordance with the relevant provisions of the Personal Information Act, you may exercise the
                        following rights with respect to the personal information held by the Company:
                      </div>
                      <div class="numbered-list">
                        A. Except for the exceptions provided for in Article 10 of the Personal Information Act, you may
                        inquire, request access to, or request the production of copies from the Company, provided that
                        the Company may charge a fee for necessary costs in accordance with the provisions of Article 14
                        of the Personal Information Act.
                      </div>
                      <div class="numbered-list">
                        B. You may request the Company to make additions or corrections, provided that, in accordance
                        with Article 19 of the Enforcement Rules of the Personal Information Act, you shall
                        appropriately explain the reasons for and the facts of the request.
                      </div>
                      <div class="numbered-list">
                        C. If the Company collects, processes or utilizes personal information in violation of the
                        provisions of the Personal Information Act, you may request the Company to stop collecting,
                        processing or utilizing personal information in accordance with Article 11, Paragraph 4 of the
                        Personal Information Act.
                      </div>
                      <div class="numbered-list">
                        D. In accordance with Article 11, Paragraph 2 of the Personal Information Act, if the accuracy
                        of personal information is in dispute, you may request the Company to stop processing or
                        utilizing the personal information. However, in accordance with the proviso of this paragraph,
                        if the Company is necessary for the execution of its business and states the dispute or with
                        your written consent, it is not subject to this limitation.
                      </div>
                      <div class="numbered-list">
                        E. In accordance with Article 11, Paragraph 3 of the Personal Information Act, when the specific
                        purpose for which the personal information was collected disappears or the period of time
                        expires, you may request the Company to delete, stop processing or utilizing the personal
                        information. However, in accordance with the proviso, the Company may request the deletion,
                        suspension of processing or utilization of personal information when it is necessary for the
                        Company to perform its business or when you have given your written consent.
                      </div>
                      <br>
                      <div>
                        II. If you wish to exercise any of the above rights, please submit a request to the Company's
                        customer service mailbox (service@esafe.com.tw) with relevant supporting documents. The Company
                        will notify the applicant in writing of its decision on the written request within 15 days from
                        the day following the date of receipt of the written request. The aforesaid 15-day period may be
                        extended for another 15 days if necessary, and the Company will notify the applicant in writing.
                        In the case of a request for inspection or reading of documents, the applicant should go to the
                        place specified in the notification letter of the Company within the period specified in the
                        notification letter after receiving the notification letter from the Company for inspection or
                        reading. If the applicant fails to make an inquiry or view the document after the deadline,
                        he/she shall make a new request in writing to the Company.
                      </div>
                      <br>
                      <div>
                        III. You are free to choose whether or not to provide the relevant personal information.
                        However, if the refusal to provide the relevant personal information is necessary for the
                        Company's business audit or operation, the Company may not be able to carry out the necessary
                        business audit or operation and may not be able to provide you with the relevant services, and
                        the Company reserves the right to decide whether or not to agree to process your application in
                        accordance with its judgment.
                      </div>
                      <br>
                      <div>
                        IV. The Company reserves the right to amend this Consent Form and, after such amendment, to
                        notify you of the revised key points and the designated webpage by word of mouth, in writing, by
                        telephone, by newsletter, by e-mail, by facsimile, by electronic mail, by fax, by electronic
                        document, or by any other means sufficient to make you aware of the revised key points and the
                        designated webpage (including, but not limited to, by notifying the provision of a link to the
                        webpage detailing the contents of the Consent Form in the manner aforesaid), and, at that time,
                        please refer to the content of the designated webpage for further details.
                      </div>
                      <br>
                      <div>
                        V. If there is any discrepancy between the terms and conditions relating to the collection,
                        processing, utilization and international transmission of personal information as set forth in
                        any previous contract or document between you and the Company and this Consent Form, this
                        Consent Form shall prevail.
                      </div>
                    </div>
                  </div>
                  <!-- 紅陽科技網路代理收付服務契約 -->
                  <div v-if="activeTab === 'service-terms'" class="details">
                    <div v-if="currentLanguage === 'chinese'">
                      <div>
                        <span style="font-weight: bolder">第一條 關於「紅陽科技」</span><br><br>
                        一、本網路代理收付服務係由紅陽科技股份有限公司（以下簡稱本公司）向買（店）家提供的系統服務平台暨代收轉付的服務（以下簡稱本服務）。為了保障買（店）家的權益，請於註冊（如有）及使用本服務前，詳細閱讀了本服務條款所有內容。當使用本服務時，即表示買（店）家已閱讀、理解並同意接受本服務所定之所有內容。若買（店）家未滿二十歲，並應於買（店）家的法定代理人閱讀、瞭解且同意本服務條款之所有內容後，方得使用本服務。但若買（店）家已接受本服務，即視為已取得法定代理人之同意，並應遵守以下所有規範。<br><br>
                        二、如買（店）家所屬的國家或地區排除本服務條款內容之全部或部分，則應立即停止使用本服務。<br><br>
                        三、公司代表人：呂寶麟。<br><br>
                        四、客服信箱：service@esafe.com.tw。<br><br>
                        五、客服電話：02-2502-6969<br><br>
                        六、公司網址：https://www.sunpay.com.tw<br><br>
                        七、服務時間：週一至週五，9:30~18:30<br><br>
                        八、營業地址：台北市大安區敦化南路二段65號13樓。<br>
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第二條 以電子文件為表示方法</span><br><br>
                        本公司與買（店）家間以電子文件為表示方法時，依本服務條款交換之電子文件，如其內容可完整呈現且可於日後取出供查驗者，其效力與書面文件相同。但依法令或行政機關之公告排除適用者，不在此限。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第三條 買家及店家帳號、密碼及安全（如有）</span><br><br>
                        買（店）家瞭解並同意，確保密碼及帳號的機密安全是自身的責任。買（店）家將對利用該密碼、帳號所進行的一切行為及言論，負適當的義務，並同意以下事項：<br><br>
                        一、買（店）家同意妥善保管帳號、密碼及或任何個人資料。在買（店）家完成購物或購物等程序後，應採取登出帳戶的動作，尤其是買（店）家是與他人共用電腦或使用公共電腦，務必要關閉瀏覽器視窗，以防止他人讀取買（店）家信件或其他個人資料。<br><br>
                        二、買（店）家同意於發現有第三人冒用或盜用帳號及密碼，或其他任何未經合法授權之情形，應即刻通知本公司，同意暫停其使用本服務並採取防範之措施。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第四條 買家及店家的守法義務及承諾</span><br><br>
                        一、買（店）家承諾絕不為任何非法目的或以任何非法方式使用本服務，也不將本服務用於禁止或限制物品的交易，並承諾遵守中華民國相關法律、法規及一切使用網際網路之國際慣例。買（店）家若係中華民國以外之使用者，買（店）家同意同時遵守所屬國家或地域之法令。<br><br>
                        二、買（店）家同意並保證不得利用本服務從事侵害他人權益或違法之行為，若有違反者應負所有法律責任，包含但不限於：<br>
                        （一）侵害他人名譽、隱私權、商業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利。<br>
                        （二）違反依法律或條款所應負之保密義務。<br>
                        （三）冒用他人名義使用本服務。<br>
                        （四）從事任何不法交易行為，如洗錢等。<br>
                        （五）提供賭博資訊或以任何方式引誘他人參與賭博。<br>
                        （六）使用無效信用卡號碼，或非使用者本人的信用卡號碼進行交易。<br>
                        （七）使用他人銀行帳號或無效銀行帳號交易。<br>
                        （八）利用本服務進行無真實交易背景的虛假交易。<br>
                        （九）從事任何可能含有電腦病毒或是可能侵害本服務系統、資料之行為。<br>
                        （十）其他本公司有正當理由認為不適當之行為。<br><br>
                        三、店家使用本服務時，應依本公司及發卡銀行規定負責檢查信用卡真偽及核對買家持卡人身分；店家自發卡銀行所取得之授權碼，僅為發卡銀行所提供信用卡號碼之有效性之判別，並非對於信用卡真偽或持卡人身分之判別，亦不代表店家已完成對買家應履行之債務。<br><br>
                        四、店家經由本服務接受其交易相對人以信用卡線上刷卡方式支付交易款項時，店家與其交易相對人間之整筆交易金額，須一次取得授權及請款，店家不得將其交易攤分作兩筆或多筆金額進行分刷交易或個別取得交易授權號碼，且店家擔保不得利用本服務從事任何非法交易、非實際消費之簽帳、融資性墊付現款（俗稱調現）或其他變相之融資等交易、或涉及無實質交易基礎之資金傳輸，亦不得從事代其交易相對人先行墊款繳交相關費用再透過本公司向發卡機構請款之行為。店家違反本項約定者，本公司得保留該等交易款項，如該等交易款項已撥付予店家，店家應返還予本公司，本公司並得自店家於本服務之帳戶內扣還相當於該等交易金額之款項。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第五條 對「會員」服務的使用</span><br><br>
                        一、「買（店）家會員」服務說明<br>
                        （一）一旦買（店）家註冊成為本公司客戶，本公司將代理買家在買家或店家符合指定條件或狀態時，支付款項給店家。<br>
                        （二）買（店）家在使用本服務過程中，畫面上出現的關於交易操作的提示是買（店）家會員交易的規則，買（店）家對於交易過程中任何一個頁面的確認表示對買（店）家會員交易規則的認同。<br>
                        （三）買家使用本服務進行交易，即表示同意並授權本公司將您經遮罩之個人資訊以及交易資訊回傳通知所指定的交易店家。<br><br>
                        二、銷貨退款之處理流程<br>
                        交易完成後，買家如欲退貨或取消該筆交易，本公司應協助買家與店家溝通處理相關退貨事項。<br><br>
                        三、購物糾紛之處理<br>
                        買（店）家都應保留交易憑證等相關文件，以備糾紛爭議時供查證之用。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第六條 商標、知識產權的保護</span><br><br>
                        一、買（店）家會員平台上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由本公司或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、商業秘密等。<br><br>
                        二、非經本公司或其他權利人書面同意任何人不得擅自使用、修改、複製、公開傳播、改變、散佈、發行或公開發表本網站程式或內容。<br><br>
                        三、如有違反，買（店）家應對本公司負損害賠償責任。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第七條 本服務契約解釋原則</span><br><br>
                        本服務契約條款如有疑義時，應為有利買（店）家之解釋。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第八條 本服務內容及費用</span><br><br>
                        一、支付工具：信用卡、金融卡、超商代收等服務。<br><br>
                        二、收費項目：交易手續費、帳務處理費。<br><br>
                        三、收費方式：撥款予店家時內扣手續費。<br><br>
                        四、收費標準：依實際交易金額計收。<br><br>
                        五、收費金額：各項服務費之項目、計算方式及金額請參閱《申請費用》說明。<br><br>
                        六、應收費之對象：與本公司簽約之店家。<br><br>
                        七、支付流程：<br>
                        （一）信用卡：買家至店家網站選購商品→買家輸入信用卡付款資訊→取得買家發卡銀行之授權碼→成功授權後店家出貨→收單銀行撥款至本公司與信託銀行簽訂信託契約所約定之信託專戶→交易完成後8~10天由本公司指示信託銀行整批匯款至店家指定戶頭。<br>
                        （二）金融卡：買家至店家網站選購商品→買家使用金融卡轉帳→收單銀行進行扣款→扣款成功後店家出貨→收單銀行撥款至本公司指定專戶→交易完成後二工作日由本公司整批匯款至店家指定戶頭。<br>
                        （三）超商代收：買家至店家網站選購商品→買家選擇超商代收方式付款→買家至超商付款→代收款機構通知本公司買家已付款→本公司將付款成功訊息通知店家，店家出貨→代收款機構撥款至本公司指定專戶→交易完成後5~25天由本公司整批匯款至店家指定戶頭。<br><br>
                        八、支付帳戶款項提領方式：依店家與本公司之約定期間（如上述支付流程內說明），由本公司主動指示信託銀行辦理匯撥。<br><br>
                        九、支付款項專用存款帳戶的存款銀行：華泰銀行、第一銀行、合作金庫、彰化銀行、凱基銀行。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第九條 匯率之計算</span><br><br>
                        本服務買（店）家所有交易帳款均應以新臺幣結付，不涉及匯率換算。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第十條 支付款項之保障</span><br><br>
                        本服務支付款項已經分別全部存入與華泰銀行、第一銀行、合作金庫、彰化銀行、凱基銀行簽訂信託契約所約定之信託專戶，專款專用。所稱專用，指本公司為履行網路代理收付服務契約之義務所使用。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第十一條 支付指示之再確認及事後核對</span><br><br>
                        一、本公司於支付完成前，就買家之支付指示，應顯示「確定付款嗎?」之選擇機制（含確定或取消），俾供買家得以再確認支付指示內容是否正確。<br><br>
                        二、本公司應於每筆款項支付完成後，以電子郵件或發送簡訊之方式通知買家支付明細，俾供買家事後核對。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第十二條 支付錯誤之處理</span><br><br>
                        一、因不可歸責於買家之事由而發生支付錯誤時，本公司應協助買家更正，並提供其他必要之協助。<br><br>
                        二、因可歸責於本公司之事由而發生支付錯誤時，本公司應於知悉時，立即更正，並同時以電子郵件或雙方約定之方式通知買家及店家。<br><br>
                        三、因可歸責於買家之事由而發生支付錯誤時，例如買家輸入錯誤之金額或輸入錯誤之收款方，經買家通知後，本公司應立即協助處理。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第十三條 資訊安全</span><br><br>
                        一、本服務是經由信用卡國際組織規定的PCIDSS認證再加上國際認證標準SSL安全加密機制，確保買（店）家的任何資料絕不外洩。<br><br>
                        二、本公司、買（店）家應各自確保所使用資訊系統之安全，防止非法入侵、取得、竄改、毀損業務紀錄或買家之個人資料。<br><br>
                        三、第三人破解本公司資訊系統之保護措施或利用資訊系統之漏洞爭議，由本公司就該事實不存在負舉證責任。<br><br>
                        四、第三人入侵網路代理收付服務資訊系統對買（店）家所造成之損害，由本公司負擔。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第十四條 本公司之終止契約或暫停服務</span><br><br>
                        本公司於下列情形，得片面終止契約或暫時停止本服務：<br>
                        一、有相當事證足認買（店）家有利用支付服務為洗錢、詐欺等犯罪行為或不法行為者。<br><br>
                        二、支付款項經法院裁定或檢察官命令扣押者。<br><br>
                        三、買（店）家提交虛偽之身分認證資料，經查證屬實者。<br><br>
                        四、買（店）家由於不履行本合約規定之義務者，視作單方面終止本合約，本公司有權向買（店）家請求損害賠償，並有權終止本合約。<br><br>
                        五、買（店）家未配合第十九條第三項再次進行識別及確認身分者。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第十五條 帳號密碼被冒用之處理</span><br><br>
                        一、本公司或買（店）家於知悉帳號密碼被冒用時，應立即通知他方並暫停該帳號所指示之支付行為暨暫停該帳號後續之支付指示。<br><br>
                        二、本公司應於服務網頁明顯處載明帳號密碼被冒用時的連絡電0509-11-2277，除有不可抗力或其他重大事由，通知電話之服務時間應為全日（24小時）全年（365日）無休。<br><br>
                        三、買（店）家辦理帳號密碼被冒用手續完成後所發生之損失，概由本公司負擔；其辦理帳號密碼被冒用手續前所發生之損失，於有下列情事時，悉由買（店）家負擔：<br>
                        （一）買（店）家未妥善保管帳號密碼。<br>
                        （二）買（店）家自行將帳號密碼提供與他人。<br>
                        （三）買（店）家未使用本公司所提供的帳號安全機制。<br>
                        （四）因買（店）家之故意或重大過失所致之事由。<br><br>
                        四、調查買（店）家帳號密碼被冒用所生之費用，由本公司負擔。<br><br>
                        五、本公司基於保護買（店）家安全之理由，於發現或接獲買（店）家通知有本條第一項情形時，立即停止該帳號使用本服務，直至該異常狀況解除為止，本公司並得要求買（店）家出具相關文件（包含但不限於：警政機關報案證明、切結書、身分證明文件等），以恢復該帳號於本服務之使用。<br><br>
                        六、買（店）家同意於使用本服務時，本公司得就使用者登入資訊（包括網路IP位置與時間）、所為之行為及其他依法令應留存之紀錄予以詳實記錄。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第十六條 買家或店家之終止契約</span><br><br>
                        買（店）家得依雙方約定之方式隨時終止契約。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第十七條 契約條款變更</span><br><br>
                        一、本公司如欲變更契約內容，應於網站明顯處公告，並以電子郵件或雙方約定之方式通知買（店）家。<br><br>
                        二、本公司未依前項進行公告及通知者，其契約之變更無效。但有利於買（店）家的變更不在此限。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第十八條 爭議處理</span><br><br>
                        一、消費爭議之客服電話：02-25026969及電子郵件信：service@esafe.com.tw。<br><br>
                        二、申訴、爭議處理機制及程序：由客服受理買（店）家爭議暨申訴→買（店）家填寫「線上客服處理表」→依買（店）家爭議暨申訴內容會辦相關單位→經辦單位先判斷是否可歸責本公司之疏失→若是，則依本服務條款檢討改善並採取補救措施→回覆客戶；若否，則逕回覆客戶→填覆客服人員「線上客服處理表」→客服人員與客戶確認是否接受？若接受，交行政部主管核決→客服人員結案歸檔；若不接受，則交回經辦單位重新處理。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第十九條 買（店）家之身分認證</span><br><br>
                        一、買（店）家應確認提交之身分認證資料正確且真實，不得有虛偽情事，如該等資料事後有變更，應立即通知本公司。<br><br>
                        二、本服務會員身分認證機制如下：<br>
                        （一）買家：身分認證資料填妥後，本服務自動帶出手機號碼後發送簡訊，買家將手機簡訊收到的四碼驗證碼輸入至驗證碼欄位（逾十分鐘後驗證碼即失效），經本服務伺服器判斷正確後即完成會員身分認證。<br>
                        （二）店家：身分認證資料填妥後，店家以手機撥語音專線，經本服務伺服器判斷為真後即完成會員身分認證。<br><br>
                        三、如有下列情形之一，本公司得要求買（店）家再次進行身分認證程序：<br>
                        （一）買（店）家申請變更基本身分資料。<br>
                        （二）買（店）家提供之身分證明文件或登記證照等相關文件疑似偽造或變造。<br>
                        （三）買（店）家交易時距前次交易已逾半年。<br>
                        （四）同一行動電話號碼、電子郵件信箱等遭不同買（店）家用於身分確認程序。<br>
                        （五）發現疑似洗錢或資助恐怖主義交易，或自洗錢或資助恐怖主義高風險國家或地區匯入款項之交易時。<br>
                        （六）對於所取得買（店）家身分資料之真實性或妥適性有所懷疑時。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第二十條 洗錢防制及打擊資助恐怖主義</span><br><br>
                        本公司為防制洗錢及打擊資助恐怖主義之目的，買（店）家同意本公司得依「洗錢防制法」、「中華民國銀行公會銀行防制洗錢及打擊資助恐怖主義注意事項範本」之規定，進行以下措施，本公司依本條辦理若致買（店）家發生損害或承受不利益者，均由買（店）家自行承擔，本公司不負損害賠償責任。<br><br>
                        （一）本公司於發現買（店）家及關聯人為受經濟制裁、外國政府或國際洗錢防制組織認定或追查之恐怖分子或團體者之時，得逕行暫時停止本服務之全部或一部而無須另通知買（店）家及關聯人；本公司並得以電子郵件或雙方約定之方式通知買（店）家終止本服務條款。<br><br>
                        （二）本公司於定期或不定期審查買（店）家及關聯人身分作業或認為必要時（包括但不限於：懷疑買（店）家涉及非法活動、疑似洗錢、資助恐怖主義活動、或媒體報導涉及違法之特殊案件等），得要求買（店）家於接獲本公司通知後60天（含）內提供審查所需之必要個人（含使用者及關聯人）或公司資料、法人戶之實際受益人或對其行使控制權之人的資訊或對交易性質與目的或資金來源進行說明，買（店）家逾期仍不履行者，本公司得以電子郵件或雙方約定之方式通知暫時停止本服務之全部或一部、或終止本服務。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第二十一條 賠償責任</span><br><br>
                        一、（店）家使用本公司金流服務時，應依據與消費者之約定確實履行義務。如（店）家出於任何原因與消費者發生糾紛致消費者向發卡銀行或收單機構請求拒付或索回其向本公司支付的全部或部分款項時，於發卡銀行拒付或對本公司強制扣款時，（店）家同意無條件授權本公司依實際情形先行賠償或補償消費者、發卡銀行或收單機構之損失或損害，（店）家並同意於本公司為前揭賠償或補償後，應無條件賠償或補償本公司因此所生之一切損失或損害（包括但不限於賠償、補償之金額、退還之款項、利息、律師費及其他因訴訟或非訴訟所支出之費用等），這些情況包括但不限於下述情節：<br>
                        （一）（店）家沒能提供服務或所提供的服務與消費者訂購明細不符或拒絕退貨；<br>
                        （二）（店）家未能提供交易對應的消費者消費紀錄或相關證據；<br>
                        （三）由於（店）家原因導致交易不合法或不可執行；<br><br>
                        二、（店）家未依本合約約定處理帳款，致本公司或收單機構或發卡銀行所受損害，（店）家應負賠償之責（包括但不限於律師費及其他訴訟或非訴訟所生之費用）。<br><br>
                        三、（店）家依本條前三項規定而應負賠償之責時，（店）家無條件同意並授權本公司於當期或未來應給付（店）家之貨款中逕行扣抵，不足部分並同意以（店）家交付之保證金作為扣抵使用（如有），扣抵後仍有差額者，（店）家應於本公司通知之日起三個日曆天內，以匯款或現金方式支付本公司該差額。若違反前揭規定者，（店）家所積欠之款項，本公司得加計自通知日起至清償日止，按年利率百分之十計算之利息。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第二十二條 準據法及管轄法院</span><br><br>
                        一、本服務之爭議應以中華民國法律為準據法。<br><br>
                        二、因本服務所生之一切爭議，立約人同意本誠信原則協議解決之；如有訴訟之必要時，除法律另有規定外，買（店）家同意以臺灣臺北地方法院為第一審管轄法院。
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">第二十三條 其他約定事項</span><br><br>
                        一、本服務代收款項，一律僅得撥付至買（店）家本人持有且已經認證之金融機構實體存款帳戶。買（店）家不得因該帳戶因故（包括但不限於遭凍結、設為警示帳戶等事由），而主張本公司應將款項撥付至其他未經認證之帳戶中。<br><br>
                        二、買（店）家同意本公司提供各項業務、商品或服務相關訊息，及寄送各項業務之消費、行銷或優惠活動訊息；如買（店）家以書面明示拒絕同意，本公司即應停止提供前述各項訊息。<br><br>
                        三、本服務之店家均有交易限額及額度限制，本公司保有依據店家之相關財力情形及檢附文件調整各項限制之權利。<br><br>
                        四、店家應隨時注意其於本服務之收款額度狀況，若有較高收款額度需求時，須另向本公司提出申請，惟店家對本公司所訂之收款額度上限不得異議。<br><br>
                        五、店家僅能於收款額度內進行收款，若因已達收款額度上限且未事先向本公司提出申請調高收款額度，將無法再進行收款，故店家不得以任何理由向本公司提出因此造成其收入減損、營業損失及請求損害賠償之主張。<br><br>
                        六、買家若因已達付款額度上限而無法進行付款，不得以任何理由向本公司提出因此造成其買賣、消費之不便利、或買賣雙方之糾紛等主張。
                      </div>
                    </div>
                    <div v-else>
                      <div>
                        <span style="font-weight: bolder">Article 1 About SunTech</span><br><br>
                        1. These online payment-processing services are the system service platform and payment
                        forwarding
                        services (hereinafter referred to as the "Services") provided by SunTech Co., Ltd. (hereinafter
                        referred to as the "Company") to the buyer (vendor). To protect the buyer's (vendor's) interest,
                        please read all the service terms and conditions hereunder carefully. The buyer's (vendor's) use
                        of
                        the Services shall constitute the buyer's (vendor's) reading, understanding and acceptance of
                        all
                        terms and conditions of the Services. For any buyer (vendor) below the age of 20, these Services
                        shall
                        only be used after the buyer's (vendor's) legal representative have read, understood and agreed
                        to
                        all
                        terms and conditions of the Services. However, the buyer's (vendor's) acceptance of the Services
                        shall
                        constitute the consent of its legal representative and the buyer (vendor) shall comply with all
                        terms
                        and conditions below.<br><br>
                        2. If the buyer's (vendor's) country or territory excludes the terms and condition the Services
                        in
                        whole or in part, such buyer (vendor) shall immediately stop the use of the Services.<br><br>
                        3. The Company's legal representative: Lu, Baolin.<br><br>
                        4. Customer service email address: service@esafe.com.tw.<br><br>
                        5. Customer Service Hotline: 02-2502-6969.<br><br>
                        6. Official website: https://www.esafe.com.tw<br><br>
                        7. Service Hours: Monday through Friday, 9:30 - 18:30.<br><br>
                        8. Business location: 13F, No.65, Sec. 2, Dunhua S. Rd., Da`an Dist., Taipei City 106, Taiwan
                        (R.O.C.)
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">Article 2 Electronic Documents</span><br><br>
                        When the Company exchanges an electronic document with a buyer (vendor) in accordance with the
                        terms
                        and conditions of the Services and if the provisions of the document can be displayed in a
                        complete
                        manner and are available for future verifications, such document shall have the same effect as a
                        written document, unless it is excluded by law or any public announcement by an administrative
                        authority.
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">Article 3 Buyer and Vendor Account, Password and Security (if any)</span><br><br>
                        The buyer (vendor) understands and agrees to be solely liable for the confidentiality and
                        security
                        of
                        its password and account. The buyer (vendor) shall be duly obliged for all conducts and
                        statements
                        through use of such password and account. The buyer (vendor) also agrees to the
                        following:<br><br>
                        1. The buyer (vendor) agrees to maintain the account, password or any personal information in a
                        proper
                        manner. After the buyer (vendor) completes shopping or shopping procedures, it shall log out of
                        the
                        account, particularly when the buyer (vendor) shares a computer with any other person or uses a
                        public
                        computer. Make sure that the browser window is closed to prevent others from reading the buyer's
                        (vendor's) correspondence or other personal information.<br><br>
                        2. The buyer (vendor) agrees to give immediate notice to the Company upon discovery of any third
                        party
                        fraudulent use or theft of its account, password or any other event without legal authorization.
                        The
                        buyer (vendor) agrees to suspend its use of the Services and shall undertake precautionary
                        measures.
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">Article 4 Buyer and Vendor's Obligation and Undertaking about Compliance with Law</span><br><br>
                        1. The buyer (vendor) undertakes to never use the Services for any illegal purposes or in any
                        illegal
                        manner, nor shall the Services be used to trade any contraband or restricted object. The buyer
                        (vendor) also undertakes to comply with applicable laws and regulations of the Republic of
                        China,
                        as
                        well as international practice for Internet use. If the buyer (vendor) is a user outside the
                        Republic
                        of China, the buyer (vendor) also agrees to comply with the laws of its country or
                        territory.<br><br>
                        2. The buyer (vendor) agrees and warrants not using the Services to engage in any act that
                        jeopardizes
                        any other person's interest or in any illegal act. In case of any breach, the buyer (vendor)
                        shall
                        bear all legal liabilities, including but not limited to:<br>
                        (1) Infringement against any other person's goodwill, privacy, trade secret, trademark,
                        copyright,
                        patent, other intellectual property rights or other rights.<br>
                        (2) Breach of any confidentiality obligation by law or provision.<br>
                        (3) Fraudulent use of any other person's name to use the Services.<br>
                        (4) Engagement of any unlawful transaction, such as money laundering.<br>
                        (5) Provision of any gambling information or enticing any other person to participate in
                        gambling
                        in
                        any manner.<br>
                        (6) Transaction through use of any invalid credit card number or the credit card number of any
                        other
                        person.<br>
                        (7) Transaction through use of any other person's bank account or any invalid bank account.<br>
                        (8) Engagement of false transactions without genuine transaction backgrounds through the use of
                        these
                        Services.<br>
                        (9) Engagement of any conduct that may include a computer virus or that may jeopardize the
                        system
                        or
                        data of the Services.<br>
                        (10) Any other conduct deemed improper by the Company with justification.<br><br>
                        3. In using these Services, the vendor is responsible for verifying the genuineness of the
                        credit
                        card
                        and the identity of the buyer/cardholder in accordance with the regulations of the Company and
                        the
                        card-issuing bank. The authorization code acquired by the vendor from the card issuing bank only
                        represents a verification by the card issuing bank about the validity of the credit card number
                        and
                        does not serve as a verification about the genuineness of the credit card or the identity of the
                        cardholder and thus shall not release the vendor of its obligation to the buyer.<br><br>
                        4. When a vendor accepts an online credit card payment by the transaction counterparty through
                        the
                        Services, an authorization and payment claim shall be acquired for the entire transaction amount
                        between the vendor and the transaction counterparty. The vendor shall not divide any single
                        transaction into two or more amounts to achieve multiple transactions or individual transaction
                        authorization codes. The vendor also warrants that the Services shall not be used to engage in
                        any
                        unlawful transaction, credit card transaction without actual consumption, financing cash advance
                        ("cash-out"), any other financing transaction or any fund transmission without underlying
                        transaction,
                        nor shall the vendor advance any funds to the transaction counterparty for payment, followed by
                        payment claim against the card issuer through the Company. In case of any breaches by the
                        vendor,
                        the
                        Company may withhold the transaction amount. If the transaction amount has been released to the
                        vendor, the vendor shall return the amount to the Company. The Company may also debit an amount
                        equivalent to the transaction amount from the vendor's account under the Services.
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">Article 5 Use of "Member" Services</span><br><br>
                        1. "Buyer (Vendor) Member" Services<br><br>
                        (1) Once the buyer (vendor) registers as a client to the Company, the Company shall make
                        payments
                        to
                        the vendor on behalf of the buyer when the buyer or the vendor meets the designated conditions
                        or
                        status.<br>
                        (2) When the buyer (vendor) uses the Services, the instructions related to transaction
                        operations
                        displayed on the screen represent the buyer (vendor) member transaction rules. The buyer's
                        (vendor's)
                        confirmation on any single page in the course of a transaction shall be deemed the buyer's
                        (vendor's)
                        agreement to the member transaction rules.<br>
                        (3) The buyer's engagement of a transaction through use of the Services shall be deemed its
                        agreement
                        and authorization to the Company to transmit your personal information that has been blinded and
                        the
                        transaction data to the designated transaction vendor.<br><br>
                        2. Cancellation and Refund Procedure<br>
                        If the buyer wishes to return the product or cancel the transaction after the transaction has
                        been
                        completed, the Company shall assist the buyer and the vendor to communicate and process relevant
                        return matters.<br><br>
                        3. Shopping Disputes<br>
                        Both the buyer and the vendor shall maintain relevant documents such as proof of transactions
                        for
                        verification in case of a dispute.
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">Article 6 Protection of Trademark and Intellectual Property Rights</span><br><br>
                        1. The intellectual property rights to all contents on the buyer (vendor) member platform,
                        including
                        but not limited to written work, pictures, files, data, information, website structure, webpage
                        arrangements, webpage design belongs to the Company or other rights holders in accordance with
                        the
                        law, including but not limited to trademark, patent, copyright and trade secrets.<br><br>
                        2. No one shall use, adapt, reproduce, publicly broadcast, disseminate, alter, issue or publish
                        any
                        program or content of the website without the written consent of the company or any other right
                        holder.<br><br>
                        3. In case of any breach, the buyer (vendor) shall be liable for compensating the Company.
                      </div>
                      <br><br>
                      <div>
                      <span
                          style="font-weight: bolder">Article 7 Rules of Interpretation of This Service Agreement</span><br><br>
                        In case of any ambiguities, the contract terms and conditions of the Services shall be
                        interpreted
                        to
                        the buyer's (vendor's) favor.
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">Article 8 Scope of Services and Fees</span><br><br>
                        1. Payment Tools: Credit card, debit card and convenience store payment services.<br><br>
                        2. Fee Items: Transaction processing fee, account management fee.<br><br>
                        3. Fee Payment: Processing fee is debited from payment to the vendor.<br><br>
                        4. Fee Schedule: Based on the actual transaction amount.<br><br>
                        5. Fee Amount: For the items, calculation method and amount of different service fees please
                        refer
                        to
                        "Application Fees".<br><br>
                        6. Payers: Vendors that have signed contracts with the Company.<br><br>
                        7. Payment Procedure:<br>
                        (1) Credit Card: Buyer selects product from the vendor's website → Buyer keys in credit card
                        payment
                        information → Acquire authorization code from the buyer's card issuing bank → Successful
                        authorization, followed by the vendor's shipping → Bank remits funds to the trust account
                        designated
                        under the trust agreement signed by the Company and the trust bank → The Company instructs the
                        trust
                        bank to make batch remittance to the vendor's designated account 8 to 10 days after transactions
                        are
                        completed.<br>
                        (2) Debit Card: Buyer selects product from the vendor's website → Buyer uses debit card to
                        transfer
                        funds → Bank debits the amount → Successful debit, followed by vendor's shipping → Bank remits
                        funds
                        to the Company's designated account → The Company makes batch remittance to the vendor's
                        designated
                        account 2 working days after transactions are completed.<br>
                        (3) Convenience Store Payment: Buyer selects product from the vendor's website → Buyer chooses
                        to
                        make
                        payment through a convenience store → Buyer goes to the convenience store to make payment →
                        Payment
                        processing organization gives notice to the Company about the buyer's payment → The Company
                        gives
                        notice about successful payment to the vendor, followed by the vendor's shipping → Payment
                        processing
                        organization remits funds to the Company's designated account → The Company makes batch
                        remittances to
                        the vendor's designated account 5 to 25 days after transactions are completed.<br><br>
                        8. Withdrawal from Payment Account: In accordance with the period agreed between the vendor and
                        the
                        Company (as provided in the above payment procedure), the company will give instruction to the
                        trust
                        bank for transfer.<br><br>
                        9. Deposit bank providing payment deposit accounts: Hwatai Bank, First Bank, Taiwan Cooperative
                        Bank,
                        Changhwa Bank, KGI Bank.
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">Article 9 Foreign Exchange</span><br><br>
                        All transaction amounts by the buyer (vendor) through the Services shall be in NT Dollars,
                        without
                        any
                        foreign exchange conversion.
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">Article 10 Secured Payment</span><br><br>
                        The payments through the Services have been fully deposited into trust accounts agreed under
                        trust
                        contracts signed with Hwatai Bank, First Bank, Taiwan Cooperative Bank, Changhwa Bank and KGI
                        Bank
                        for
                        exclusive purpose. Exclusive purpose means the use by the Company for the performance of its
                        obligations under the Online Payment Services Agreement.
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">Article 11 Re-Confirmation of Payment Instruction and Post-Transaction Verification</span><br><br>
                        1. Before payment is completed, the Company shall display an option mechanism showing "Do you
                        confirm
                        payment?" (offering options between "Confirm" or "Cancel") in relation to the buyer's payment
                        instruction, so that the buyer can re-confirm whether the payment instruction is
                        correct.<br><br>
                        2. After completion of each payment, the company shall send a notice to the buyer showing
                        payment
                        details by email or short message to allow the buyer's post-transaction verification.
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">Article 12 Payment Error</span><br><br>
                        1. In case of payment errors due to any reason that is not imputable to the buyer, the Company
                        shall
                        assist the buyer with the correction and shall provide other necessary assistance.<br><br>
                        2. In case of payment errors due to any reason that is imputable to the Company, the Company
                        shall
                        make immediate correction upon learning of the event and shall give notice to the buyer and the
                        vendor
                        by email or another manner agreed by both parties.<br><br>
                        3. In case of payment errors due to any reason that is imputable to the buyer, such as the
                        buyer's
                        input error in amount or payee, the Company shall provide immediate assistance upon the buyer's
                        notice.
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">Article 13 Information Security</span><br><br>
                        1. The Services are PCIDSS certified by the international credit card organizations, with
                        security
                        encryption mechanism under SSL international certification standard, in order to ensure no
                        disclosure
                        of any of the buyer's (vendor's) information.<br><br>
                        2. The Company and the buyer (vendor) shall each ensure the security of the information system
                        that
                        they use to prevent unlawful intrusion, acquisition, alteration or jeopardy to any business
                        record
                        of
                        the buyer's personal information.<br><br>
                        3. In case of any dispute about any third party's breaking into the protection measure of the
                        Company's information system or use of any loophole in the information system, the Company shall
                        bear
                        the burden of proof about the non-existence of such event.<br><br>
                        4. The Company shall be liable for any damage suffered by the buyer (vendor) caused by any third
                        party
                        hacking into the online payment processing services information system.
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">Article 14 The Company's Termination of Contract or Suspension of Services</span><br><br>
                        The Company may unilaterally terminate the agreement or suspend the Services in the following
                        events:<br><br>
                        1. Sufficient evidence showing that the buyer (vendor) uses the payment services to engage in
                        any
                        criminal offense or unlawful conduct such as money laundering or fraud.<br><br>
                        2. The payment amount has been seized by court ruling or prosecutor's order.<br><br>
                        3. The buyer (vendor) submits false identification information, as confirmed following
                        verification.<br><br>
                        4. The buyer (vendor) is deemed to have unilaterally terminated this agreement due to failure to
                        perform its obligations under this agreement. The Company shall be entitled to seek compensation
                        from
                        the buyer (vendor) and shall have the right to terminate this agreement.<br><br>
                        5. The buyer (vendor) fails to perform second identification and confirmation of identity in
                        accordance with Paragraph 3, Article 19.
                      </div>
                      <br><br>
                      <div>
                        <span
                            style="font-weight: bolder">Article 15 Fraudulent Use of Account and Password</span><br><br>
                        1. If the Company or the buyer (vendor) learns that its account and passcode have been subject
                        to
                        fraudulent use, it shall give immediate notice to the other party and suspend the payment
                        instructed
                        with such account and suspend any subsequent payment instruction with such account.<br><br>
                        2. The Company shall indicate in a clear location of the service webpage the contact number
                        0509-11-2277 in case of fraudulent use of account and password. Unless there is any even of
                        force
                        majeure or any other material event, the service hours of such number shall be full day (24
                        hours)
                        and
                        full year (365 days).<br><br>
                        3. The Company shall bear all losses incurred after the buyer (vendor) has completed the
                        procedure
                        for
                        fraudulent use of account and password. In case of the below events, the losses incurred before
                        the
                        completion of such procedure shall be borne by the buyer (vendor):<br>
                        (1) The buyer (vendor) fails to keep in due custody the account and password.<br>
                        (2) The buyer (vendor) provides its account and password to another person.<br>
                        (3) The buyer (vendor) fails to use the account security mechanism provided by the Company.<br>
                        (4) Any event caused by the buyer's (vendor's) willful conduct or gross negligence.<br><br>
                        4. The Company shall bear the cost incurred for the investigation of fraudulent use of the
                        buyer's
                        (vendor's) account and password.<br><br>
                        5. To protect the buyer's (vendor's) security, upon receipt of any buyer's (vendor's) notice
                        about
                        any
                        event under Paragraph 1 of this article, the Company shall immediately stop the use of the
                        Services
                        with such account until the anomaly has ceased to exist. The Company may also ask the buyer
                        (vendor)
                        to provide relevant documents (including but not limited to proof of report to the police
                        authority,
                        affidavit and proof of documentation) to reinstate the use of the Services with such
                        account.<br><br>
                        6. The buyer (vendor) agrees that, during its use of the Services, the Company may keep detailed
                        records of the user log-in information (including IP location and time), the activities and
                        other
                        records that should be maintained in accordance with the law.
                      </div>
                      <br><br>
                      <div>
                        <span
                            style="font-weight: bolder">Article 16 Termination of Contract by Buyer or Vendor</span><br><br>
                        The buyer (vendor) may terminate the contract at any time in the manner agreed by both parties.
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">Article 17 Amendment</span><br><br>
                        1. If the Company wishes to change the contract terms, it shall make a public announcement in a
                        clear
                        location on the website and shall give notice to the buyer (vendor) by email or any other manner
                        agreed by both parties.<br><br>
                        2. If the Company fails to make public announcement and give notice in accordance with the
                        previous
                        paragraph, the amendment shall be invalid, except if the amendment is to the buyer's (vendor's)
                        favor.
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">Article 18 Dispute Resolution</span><br><br>
                        1. Consumer dispute customer service hotline: 02-25026969 and email:
                        service@esafe.com.tw.<br><br>
                        2. Complaint, Dispute Resolution Mechanism and Procedure: The customer service receives buyer's
                        (vendor's) dispute and complaint → The buyer (vendor) completes the "Online Customer Service
                        Processing Form" → Inform relevant department according to the buyer's (vendor's) dispute and
                        nature
                        of complaint → Processing department makes a first determination about whether there is a
                        negligence
                        imputable to the Company → If yes, review and undertake remedy measures in accordance with these
                        service terms and conditions → Reply to the client; If No, reply to the client directly →
                        Complete
                        the
                        customer service staff "Online Customer Service Processing Form" → Customer service staff
                        confirms
                        whether the client accepts? If yes, submit to supervisor of administration department for
                        approval
                        →
                        Customer service staff closes and files the case; If not accepted, return the case to processing
                        department for further handling.
                      </div>
                      <br><br>
                      <div>
                      <span
                          style="font-weight: bolder">Article 19 Buyer's (Vendor's) Identity Certification</span><br><br>
                        1. The buyer (vendor) shall confirm that the identity certification information that its submits
                        is
                        correct and truthful, without any falsity. If any such information is changed subsequently, an
                        immediate notice shall be given to the Company.<br><br>
                        2. The Service member identity certification mechanism is as follows:<br>
                        (1) Buyer: After the identity certification information is completed, the Service will
                        automatically
                        bring out a mobile number and send a short message. The buyer should key in the 4-digit
                        verification
                        code received through mobile short message into the verification code box (verification code is
                        valid
                        for 10 minutes). Member identity certification will be completed after the server of the
                        Services
                        makes a positive determination.<br>
                        (2) Vendor: After the identity certification information is completed, the vendor should dial a
                        voice
                        hotline by mobile phone. The member identity certification will be completed after the server of
                        the
                        Services makes a positive detemrination.<br><br>
                        3. In case of any of the following events, the Company may ask the buyer (vendor) to complete
                        second
                        identity certification procedure:<br>
                        (1) The buyer (vendor) asks to change basic identity information.<br>
                        (2) Relevant document such as identification document or registration license provided by the
                        buyer
                        (vendor) may be forged or altered.<br>
                        (3) The buyer's (vendor's) last transaction was more than six months ago.<br>
                        (4) The same mobile phone number or email address was used by different buyers (vendors) in the
                        identity confirmation procedure.<br>
                        (5) Suspected trade involving money laundering or funding for terrorism, or remittance of
                        transaction
                        funds from a high-risk country or territory in terms of money laundering or terrorism
                        funding.<br>
                        (6) There is a concern about the genuineness or appropriateness of the buyer's (vendor's)
                        identity
                        information that was acquired.
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">Article 20 Money Laundering Prevention and Crackdown on Terrorism Funding</span><br><br>
                        To achieve the Company's purpose of money laundering prevention and crackdown on terrorism
                        funding,
                        the buyer (vendor) agrees that the Company may undertake the following measures in accordance
                        with
                        the
                        Money Laundering Prevention Act and the Template Guidelines for Money Laundering Prevention and
                        Crackdown on Terrorism Funding by the Banker's Association of the Republic of China. Any damage
                        or
                        jeopardy suffered by the buyer (vendor) due to the Company's performance in accordance with this
                        article shall be solely borne by the buyer (vendor) and the Company shall not be liable for
                        compensation.<br><br>
                        (1) If the Company discovers that the buyer (vendor) or its related party is a terrorist or
                        terrorist
                        group subject to economic sanction, determination or investigation by a foreign government or
                        international money laundering prevention organization, it may suspend the Services in whole or
                        in
                        part without notice to the buyer (vendor) or its related party. The Company may also terminate
                        these
                        service terms and conditions by notice to the buyer (vendor) through email or in any other
                        manner
                        agreed by both parties.<br><br>
                        (2) When the Company performs regular or aperiodic review of the identity of the buyer (vendor)
                        or
                        its
                        related party or as it deems it necessary (including but not limited to: suspicion about the
                        buyer's
                        (vendor's) involvement in unlawful activities, suspicion of money laundering, funding for
                        terrorist
                        activities or special cases of breach of law reported by the media), it may ask the buyer
                        (vendor)
                        to
                        provide individual (including the user and related parties) or company information, actual
                        beneficiary
                        of a corporate account, information about the person exercising control or explanations about
                        the
                        nature and purpose of the transaction and the source of funds that is required for review within
                        60
                        days (inclusive) from receipt of the Company's notice. If the buyer (vendor) fails to perform
                        before
                        the deadline, the Company may suspend the Services in whole or in party or terminate the
                        Services
                        by
                        email notice or in any other manner agreed by the parties.
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">Article 21 Compensation</span><br><br>
                        1. When the (vendor) uses any of the Company's cash flows services, it shall duly perform its
                        obligations in accordance with its agreements with the consumers. If the (vendor) has any
                        disagreement
                        with any consumer due to any reason and if the consumer asks the card issuing bank or card
                        accepting
                        organization to refuse payment or claims the refund of any amount paid to the Company in whole
                        or
                        in
                        part, upon the card issuing bank's refusal for payment or mandatory debit against the Company,
                        the
                        (vendor) agrees to unconditionally authorize the Company to first compensate or indemnify the
                        loss
                        or
                        damage suffered by the consumer, card issuing bank or card accepting organization first based on
                        the
                        actual situation. The (vendor) also agrees that, after the Company has provided such
                        compensation
                        or
                        indemnification, it shall unconditionally compensate or indemnify all losses or damages suffered
                        by
                        the Company (including but not limited to the amount of compensation or indemnification, the
                        amount of
                        refund, interest, attorney's fee or any other litigation or non-litigation cost incurred). These
                        events include but are not limited to the following:<br>
                        (1) The (vendor) fails to provide services or the services provided are inconsistent with the
                        consumer's order details or the product is rejected and returned;<br>
                        (2) The (vendor) fails to provide consumer purchase records or relevant proof corresponding to
                        the
                        transaction;<br>
                        (3) The transaction is unlawful or unenforceable due to the (vendor's) reason.<br><br>
                        2. If the (vendor) fails to process accounts in accordance with the requirements of this
                        agreement,
                        the (vendor) shall be liable for compensating the damages suffered by the Company, the card
                        accepting
                        organization or card-issuing bank (including but not limited to attorney's fee and other
                        litigation or
                        non-litigation cost).<br><br>
                        3. If the (vendor) is liable for compensation in accordance with the previous three paragraphs
                        of
                        this
                        article, the (vendor) unconditionally agrees and authorizes the Company to debit the amount from
                        product payment that is due to the (vendor) in the current period or in the future. It is also
                        agreed
                        that any shortfall may be set off against the security deposit provided by the vendor (if any).
                        If
                        any
                        shortfall remains after the setoff, the (vendor) shall pay the difference to the Company by
                        remittance
                        or in cash within 3 calendar days from the Company's notice. In case of any breach of the above,
                        the
                        Company may accrue interest at 10% per annum from the date of notice to the date of repayment on
                        the
                        overdue amount owed by the (vendor).
                      </div>
                      <br><br>
                      <div>
                      <span
                          style="font-weight: bolder">Article 22 Governing Law and Court of Jurisdiction</span><br><br>
                        1. Any dispute about the Services shall be governed by the laws of the Republic of
                        China.<br><br>
                        2. The undersigned agree that all disputes arising out of the Services shall be resolved through
                        negotiation based on the principle of good faith. If litigation is necessary, unless otherwise
                        provided by law, the buyer (vendor) agrees that the Taiwan Taipei District Court shall be the
                        first-instance court of jurisdiction.
                      </div>
                      <br><br>
                      <div>
                        <span style="font-weight: bolder">Article 23 Other Agreements</span><br><br>
                        1. All payments processed by the Services shall only be forwarded to the physical deposit
                        account
                        with
                        a certified financial institution in the name of the buyer (vendor). The buyer (vendor) shall
                        not
                        ask
                        the Company to pay the amount to any other non-certified account for any reason (including but
                        not
                        limited to seized or red-flagged account).<br><br>
                        2. The buyer (vendor) agrees for the Company to provide information about business activities,
                        products or services and to send information about business consumption, marketing or
                        promotional
                        event. If the buyer (vendor) expressly refuses to give this consent in writing, the Company
                        shall
                        immediately stop the provision of such information.<br><br>
                        3. Each vendor of the Services is subject to transaction limit and amount limit. The Company
                        reserves
                        the right to change any limit based on the vendor's relevant financial status and documents
                        submitted.<br><br>
                        4. The vendor shall exercise due care at all times about the status of payment acceptance limit
                        with
                        the Services. If the vendor needs a higher payment acceptance limit, it shall file another
                        application
                        with the Company. However, the vendor shall not object to any payment acceptance limit that is
                        established by the Company.<br><br>
                        5. The vendor shall only accept payments within the payment acceptance limit. If the limit has
                        been
                        reached and if there is no prior application filed with the Company to raise the limit, no
                        further
                        payment shall be accepted. Thus the vendor shall not file any claim against the Company for loss
                        of
                        income, loss of business or compensation based on any reason.<br><br>
                        6. If the buyer has reached its payment limit and cannot make further payment, it shall not file
                        any
                        claim against the Company for inconvenience of trade or consumption or dispute between the buyer
                        and
                        the vendor based on any reason.
                      </div>
                    </div>
                  </div>
                  <!-- 消費者個資移轉商店使用同意書 -->
                  <!--                <div v-if="activeTab === 'data-transfer'" class="details">-->
                  <!--                  <div v-if="currentLanguage === 'chinese'">-->
                  <!--                    <div>-->
                  <!--                      您同意紅陽科技在營運期間內為履行金流契約所需之範圍(含身分驗證及核銷等)，得於中華民國境內蒐集、處理及利用您的姓名、電話號碼及電子郵件等個資。您復同意本公司得將您個人資料移轉予合作商家分享，使該商家得以履行對您的訂單出貨、售後服務等義務。若商家涉及將您的個人資料為前述特定目的外之利用，請洽本公司客服電話：02-25026969。-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                  <div v-else>-->
                  <!--                    You agree that Sun Tech Co., Ltd (hereinafter referred to as "the Company") may collect, process and utilize your name, telephone number and email address in the Republic of China for the purpose of fulfilling the financial flow contract (including identity verification and verification, etc.) during the operation period. You further agree that we may transfer your personal information to the merchants to enable the merchants to fulfill their obligations to you with respect to orders, shipments, and after-sale services. If the merchant is involved in utilizing your personal information for purposes other than the aforementioned specific purposes, please contact our customer service number: 02-25026969.-->
                  <!--                  </div>-->
                  <!--                </div>-->
                </div>
              </el-dialog>
            </div>
          </div>
          <!-- 確認付款按鈕 -->
          <div style="text-align: center;">
            <el-button type="danger" :disabled="!informationCk" :class="informationCk ? 'next-btn' : 'next-btn-dis'"
                       @click="nextStep">確認付款 Confirm
            </el-button>
          </div>
        </div>
        <div>
<!--          <div style="text-align: center; margin: 20px 0">-->
<!--            <el-image style="max-width: 700px; margin-top: 20px;" :src="bannerImgUrl"></el-image>-->
<!--          </div>-->
          <!-- 舊footer -->
          <!--        <div style="text-align: center;">-->
          <!--          <el-image style="max-width: 900px; width: 100%; margin: 20px -30px" :src="footerImgUrl"></el-image>-->
          <!--        </div>-->
          <!-- 新footer -->
          <table style="width: 100%; text-align: center; margin-top: 30px;">
            <tr style="font-weight: bolder; font-size: 16px; height: 60px;">
              <td>收單銀行/ Acquiring Bank</td>
              <td>支付服務/ Payment Service</td>
              <td>SSL認證/ Secure Socket Layer</td>
            </tr>
            <tr>
              <td>
                <el-image class="footer-img" :src="selectedPayPlatformImg"></el-image>
<!--                <el-image class="footer-img" :src="cardIcon1"></el-image>-->
              </td>
              <td>
                <el-image class="footer-img" :src="logoImgUrl"></el-image>
              </td>
              <td>
                <el-image class="footer-img" :src="goDaddyIcon"></el-image>
<!--                <el-image class="footer-img" :src="cardIcon2"></el-image>-->
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div style="width: 100%; height: 40px; background: #8F0304;"></div>

      <!--填寫驗證碼-->
      <new-ver-code-dialog ref="verCodeDialog"
                           :orderAmount="dataForm.paymentAmount"
                           @goPay="goPay" @dialogClosed="dialogClosed"></new-ver-code-dialog>


    </el-form>
  </div>
</template>

<script>
import newVerCodeDialog from './compontents/periodic-ver-code-dialog'
// import imgSlide from './compontents/img-slide'
import agreement1 from './agreement/agreement1'
import agreement2 from './agreement/agreement2'
import agreement3 from './agreement/agreement3'

import {
  getCurrentMonth,
  getCurrentYear
} from '@/api/credit'

import logoImg from '../assets/img/new-payment/logo-sunpay.png'
import rectangleOi from '../assets/img/new-payment/rectangle-oi.png'
import clockImg from '../assets/img/new-payment/clock-01.png'
import commodityBgImg from '../assets/img/new-payment/commodity-bg.png'
import dividerImg from '../assets/img/new-payment/divider.png'
import bannerImg from '../assets/img/new-payment/banner.png'
import masterImg from '../assets/img/new-payment/master.png'
import visaImg from '../assets/img/new-payment/visa.png'
import jcbImg from '../assets/img/new-payment/jcb.png'
import unionPayImg from '../assets/img/new-payment/unionPay.png'
import piluPayImg from '../assets/img/new-payment/piluPay.png'
import monthlPayImg from '../assets/img/new-payment/monthlPay.png'
import googlePayImg from '../assets/img/new-payment/google-pay.png'
import applePayImg from '../assets/img/new-payment/apple-pay.png'
import sumsungPayImg from '../assets/img/new-payment/sumsung-pay.png'

import inputProfileTickImg from '../assets/img/new-payment/input-profile-tick.png'
import annotationCheckImg from '../assets/img/new-payment/annotation-check.png'
import emailTxtImg from '../assets/img/new-payment/email-txt.png'
import emailTxtCNImg from '../assets/img/new-payment/email-txt-cn.png'
import productTxtImg from '../assets/img/new-payment/product-txt.png'
import annotationAlertImg from '../assets/img/new-payment/annotation-alert.png'

import footerImg from '../assets/img/new-payment/footer.png'
import footerFtImg from '../assets/img/new-payment/footer-first.png'
import footerKgiImg from '../assets/img/new-payment/footer-kgi.png'
import footerTsImg from '../assets/img/new-payment/footer-ts.png'
import footerUbImg from '../assets/img/new-payment/footer-ub.png'
import rememberTxtImg from '../assets/img/new-payment/remember-txt.png'

import arrowDownImg from '../assets/img/new-payment/input/arrow-down.png'
import arrowDownRImg from '../assets/img/new-payment/input/arrow-down-r.png'
import calendarTickImg from '../assets/img/new-payment/input/calendar-tick.png'
import calendarTickRImg from '../assets/img/new-payment/input/calendar-tick-r.png'
import componentImg from '../assets/img/new-payment/input/component.png'
import componentRImg from '../assets/img/new-payment/input/component-r.png'
import frameImg from '../assets/img/new-payment/input/frame.png'
import frameRImg from '../assets/img/new-payment/input/frame-r.png'
import mailImg from '../assets/img/new-payment/input/mail.png'
import mailRImg from '../assets/img/new-payment/input/mail-r.png'
import mapImg from '../assets/img/new-payment/input/map.png'
import mapRImg from '../assets/img/new-payment/input/map-r.png'
import profileTickImg from '../assets/img/new-payment/input/profile-tick.png'
import profileTickRImg from '../assets/img/new-payment/input/profile-tick-r.png'
import cardRImg from '../assets/img/new-payment/input/card-r.png'
import cardImg from '../assets/img/new-payment/input/card.png'
import carRImg from '../assets/img/new-payment/input/car-r.png'
import carImg from '../assets/img/new-payment/input/car.png'

import arrowLeftImg from '../assets/img/new-payment/arrow-left.png'
import arrowRightImg from '../assets/img/new-payment/arrow-right.png'
import refreshImg from '../assets/img/new-payment/refresh.png'

import smsNoVerificationImg from '../assets/img/new-payment/input/shield-check.png'
import smsVerificationImg from '../assets/img/new-payment/input/shieldVerificatin-check.png'

// 眼睛圖示 睜眼/閉眼
import eye from '../assets/img/new-payment/eye.png'
import eyeSlash from '../assets/img/new-payment/eye_slash.png'
// 凱基銀行 P0001
import kgiBank from '../assets/img/new-payment/kgi-bank.png'
// 第一銀行 P0002
import firstBank from '../assets/img/new-payment/firstbank.png'
// 聯信 P0004
import nccBank from '../assets/img/payment/pay-icon-2.jpg'
// 聯邦銀行 P0005
import unionBank from '../assets/img/new-payment/union-bank.png'
import nccBank2 from '../assets/img/new-payment/ncc-logo.png'
// 中國信託 P0007
import ctbcBank from '../assets/img/new-payment/ctbc-bank.png'
// 台新銀行 P0009
import taishinBank from '../assets/img/new-payment/taishin-bank.png'
// SSL 認證 (GoDaddy)
import goDaddy from '../assets/img/new-payment/GoDaddy.png'

export default {
  name: 'init-no-logistics',
  components: {
    newVerCodeDialog,
    // imgSlide,
    agreement1,
    agreement2,
    agreement3
  },
  data() {
    var validateCreditCardNumber = (rule, value, callback) => {
      if (this.global.isEmpty(this.dataForm.cardNumber) || this.dataForm.changeCard == '1') {
        if (value == '' || value == undefined || value == null) {
          callback(new Error('此欄位為必填！'))
        } else {
          let val = value.replace(/\s*/g, '');
          // 信用卡類別為信用卡
          if (this.dataForm.cardCategory == '0' && val.length !== 16) {
            callback(new Error('請輸入16位卡號'))
          } else if (this.dataForm.cardCategory == '1' && (val.length > 19 || val.length < 16)) {
            callback(new Error('請輸入16位-19位卡號'))
          }
        }
      }
      callback()
    }
    const checkCarrierId = (rule, value, callback) => {
      if (this.dataForm.invoiceStatus == 2) { //手機載具
        let rg = /^\/[0-9A-Z.\-\+]{7}$/;
        if (rg.test(value)) {
          callback()
        } else {
          callback(new Error('"請輸入正確載具號碼格式！'))
        }
      } else {
        let rg = /[A-Z]{2}[0-9]{14}$/;
        if (rg.test(value)) {
          callback()
        } else {
          callback(new Error('"請輸入正確載具號碼格式！'))
        }
      }
    }
    return {
      payPlatformId: '',
      payPlatformImgs: {
        'P0001': kgiBank,
        'P0002': firstBank,
        'P0004': nccBank2,
        'P0005': unionBank,
        'P0007': ctbcBank,
        'P0009': taishinBank
      },
      selectedPayPlatformImg: '',
      creditCardNumber1: '',  // 信用卡號第一組
      creditCardNumber2: '',  // 信用卡號第二組
      creditCardNumber3: '',  // 信用卡號第三組
      creditCardNumber4: '',  // 信用卡號第四組
      eyeShow: '',            // 顯示眼睛閉眼或是睜眼
      //付款方式
      payMethod: 'DOMESTIC_CREDIT_CARD',
      paymentPeriodList: [{
        code: 'D',
        name: '固定天期',
      }, {
        code: 'W',
        name: '每週',
      }, {
        code: 'M',
        name: '每月',
      }, {
        code: 'Y',
        name: '每年',
      }],
      //捐贈號碼列表
      pobanList: [],
      mainLoading: false,
      payHeaderIcon: require('../assets/img/payment/pay-icon-5.png'),
      twIcon: require('../assets/img/payment/pay-icon-4.png'),
      cardIcon1: require('../assets/img/payment/pay-icon-2.jpg'),
      cardIcon2: require('../assets/img/payment/pay-icon-1.jpg'),
      goDaddyIcon: goDaddy,
      titleIcon: require('../assets/img/payment/pay-icon-3.png'),
      labelPosition: 'top',

      secondCode: '',
      threeCode: '',
      sumAmount: '', //總金額
      invoicePaperShow: 0,
      verificationType: 0,
      dataForm: {
        // 信用卡卡號
        creditCardNumber: '',
        masterId: '',
        invoicePaper: '',
        city: '',
        area: '',
        address: '',
        invoiceAddress: '',
        //身份驗證資訊
        //持卡人手機
        cellphoneNumber: '',
        //驗證碼
        verificationCode: '',
        //身份證
        idCardNumber: '',
        //發證地點
        issuanceLocation: '',
        //發證日期
        issuanceDate: '',
        //補換類別
        issuanceType: '',
        verificationFlag: '',
        idCardPhotoFrontResourceId: '',
        idCardPhotoBackResourceId: '',
        idCardNumberSecurityCode: '',
        successAllPath: '',
        failAllPath: '',
      },
      //紙本發票選中狀態
      invoicePaperChecked: false,
      rules: {
        carrierIdPerson: [
          {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'},
          {validator: checkCarrierId,}
        ],
        carrierIdMobile: [
          {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'},
          {validator: checkCarrierId,}
        ],
        //買受人統編
        identifier: [
          {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'},
          {pattern: /^\d{8}$/, message: "請輸入正確買受人統編格式"}
        ],
        poban: [
          {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'}
        ],
        invoiceHead: [
          {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'},
          {pattern: /^[^\s]+/, message: "請輸入正確發票抬頭格式"}
        ],
      },
      preventSubmitFlag: false,
      // 信用卡號是否顯示驗證信息
      showCardNoErrorText: false,
      // 校驗是否通過
      validatePass: false,
      binCode: '',
      agreeList: [false, false, false],
      agreeComponents: ['agreement1', 'agreement2', 'agreement3'],
      addCardType: '1',
      clockImgUrl: clockImg,
      logoImgUrl: logoImg,
      rectangleOiUrl: rectangleOi,
      commodityBgImgUrl: commodityBgImg,
      dividerImgUrl: dividerImg,
      bannerImgUrl: bannerImg,
      masterImgUrl: masterImg,
      visaImgUrl: visaImg,
      jcbImgUrl: jcbImg,
      unionPayImgUrl: unionPayImg,
      piluPayImgUrl: piluPayImg,
      monthlPayImgUrl: monthlPayImg,
      googlePayImgUrl: googlePayImg,
      applePayImgUrl: applePayImg,
      sumsungPayImgUrl: sumsungPayImg,
      idCard1: '',
      idCard2: '',

      arrowDownImgUrl: arrowDownImg,
      arrowDownRImgUrl: arrowDownRImg,
      calendarTickImgUrl: calendarTickImg,
      calendarTickRImgUrl: calendarTickRImg,
      componentImgUrl: componentImg,
      componentRImgUrl: componentRImg,
      frameImgUrl: frameImg,
      frameRImgUrl: frameRImg,
      mailImgUrl: mailImg,
      mailRImgUrl: mailRImg,
      mapImgUrl: mapImg,
      mapRImgUrl: mapRImg,
      profileTickImgUrl: profileTickImg,
      profileTickRImgUrl: profileTickRImg,
      cardRImgUrl: cardRImg,
      cardImgUrl: cardImg,
      carRImgUrl: carRImg,
      carImgUrl: carImg,
      smsNoVerificationImgUrl: smsNoVerificationImg,
      smsVerificationImgUrl: smsVerificationImg,
      // 眼睛圖示-睜眼
      eye: eye,
      // 眼睛圖示-閉眼
      eyeSlash: eyeSlash,

      inputProfileTickImgUrl: inputProfileTickImg,
      // calendarTickImgUrl: calendarTickImg,
      // frameImgUrl: frameImg,
      annotationCheckImgUrl: annotationCheckImg,
      emailTxtCNImgUrl: emailTxtCNImg,
      emailTxtImgUrl: emailTxtImg,
      productTxtImgUrl: productTxtImg,
      annotationAlertImgUrl: annotationAlertImg,

      footerImgUrl: footerImg,
      footerFtImgUrl: footerFtImg,
      footerKgiImgUrl: footerKgiImg,
      footerTsImgUrl: footerTsImg,
      footerUbImgUrl: footerUbImg,
      rememberTxtImgUrl: rememberTxtImg,

      formErrorMsg: '必填欄位不得為空白 Field required',

      checked: false,
      informationCk: false,
      showPopup: false,
      currentLanguage: '', // 用來判斷顯示中文或英文
      activeTab: '',    // 預設顯示的頁籤

      dueDate: '', // 付款有效時間
      merchantName: '', //商店名稱
      orderName: '', //訂單名稱

      optMYList: [],

      validateEffectiveDate: undefined,
      validateCreditCardNumber: undefined,
      validateCellphoneNumber: undefined,
      validateVerificationCode: undefined,
      validateIdCardNumber: undefined,
      EffectiveDateErMsg: '必填欄位不得為空白 Field required',
      creditCardNumberMsg: '必填欄位不得為空白 Field required',
      transactionAmountMsg: '必填欄位不得為空白 Field required',
      recipientPhoneMsg: '必填欄位不得為空白 Field required',
      idCardNumberMsg: '必填欄位不得為空白 Field required',
      cellphoneNumberMsg: '', //11111
      verificationCodeMsg: '必填欄位不得為空白 Field required',
      recipientPhoneErr: false,

      agreement1Box: false,
      agreement2Box: false,
      agreement3Box: false,

      productNameList: [],

      arrowLeftImgUrl: arrowLeftImg,
      arrowRightImgUrl: arrowRightImg,
      refreshImgUrl: refreshImg,

      cityList: [],
      areaList: [],
      //是否存在身份驗證
      isExistIdentity: false,
      selectedYear: '',
      selectedMonth: '',
      selectedDay: '',
      yearOptions: [],
      monthList: [],
      dayList: [],
      issuanceLocationList: [],
      issuanceTypeList: [],
      //驗證碼倒計時
      count: '',
      //發送驗證碼的狀態 0默認可點 1xx秒後不可點  2重發-可點 3次數已過
      sendCodeStatus: '0',
      clickCount: 0,
      verificationCodeErr: false,
      verificationIDErr: false,
      verificationIDThreeErr: false,
      errCount: 0,
      cellphoneNumberErr: false,
    }
  },
  computed: {
    // 跳轉頁面
    routerName: {
      get() {
        return this.$store.state.credit.routerName
      },
      set(val) {
        this.$store.commit('credit/updateRouterName', val)
      }
    },
  },
  mounted() {
    // 獲取捐贈碼列表
    this.getPobanDataList();
    // 獲取城市下拉選
    this.getCityCodeName();
    let path = this.$route.path;
    let pathList = [];
    if (!this.global.isEmpty(path)) {
      pathList = path.split('/');
      this.secondCode = pathList[2];
      this.threeCode = pathList[3];
    }
    this.getInfo();
    this.initSelectYearMonth();
    this.getLocationList();
    this.getIssuanceTypeList();
  },
  methods: {
    initSelectYearMonth() {
      let year = new Date().getFullYear();
      year = year - 1911;
      for (var i = year; i >= 0; i--) {
        this.yearOptions.push(i);
      }

      for (var i = 1; i <= 12; i++) {
        if (i < 10) {
          this.monthList.push({
            value: '0' + i,
            lable: '0' + i,
            disable: false
          });
        } else {
          this.monthList.push({
            value: i,
            lable: i,
            disable: false
          });
        }
      }
    },
    changeYear() {
      this.selectedMonth = '';
      this.selectedDay = '';
      let nowYear = new Date().getFullYear();
      let nowMonth = new Date().getMonth() + 1;
      let nowDay = new Date().getDate();
      if (this.selectedYear && this.selectedYear == nowYear - 1911) {
        this.monthList.forEach((item) => {
          item.disable = false;
          if (parseInt(item.value) > nowMonth) {
            item.disable = true;
          } else {
            item.disable = false;
          }
        })
      } else {
        this.monthList.forEach((item) => {
          item.disable = false;
        })
      }
    },
    mothChange(moth) {
      let daysInMonth = new Date(this.selectedYear + 1911, this.selectedMonth, 0).getDate();
      let days = [];
      for (var i = 1; i <= daysInMonth; i++) {
        if (i < 10) {
          days.push({
            value: '0' + i,
            lable: '0' + i,
            disable: false
          });
        } else {
          days.push({
            value: i,
            lable: i,
            disable: false
          });
        }
      }
      this.selectedDay = '';
      this.dayList = days;
      let nowYear = new Date().getFullYear();
      let nowMonth = new Date().getMonth() + 1;
      let nowDay = new Date().getDate();
      if (this.selectedYear + 1911 == nowYear && parseInt(this.selectedMonth) == nowMonth) {
        this.dayList.forEach((item) => {
          item.disable = false;
          if (parseInt(item.value) > nowDay) {
            item.disable = true;
          } else {
            item.disable = false;
          }
        })
      } else {
        this.dayList.forEach((item) => {
          item.disable = false;
        })
      }
    },
    getLocationList() {
      this.$http({
        url: this.$http.adornUrl('verify/issuanceLocationList'),
        method: 'get',
        params: this.$http.adornParams({})
      }).then(({
                 data
               }) => {
        if (data && data.resultCode == 200) {
          this.issuanceLocationList = data.body;
        }
      })
    },
    getIssuanceTypeList() {
      this.$http({
        url: this.$http.adornUrl('verify/issuanceTypeList'),
        method: 'get',
        params: this.$http.adornParams({})
      }).then(({
                 data
               }) => {
        if (data && data.resultCode == 200) {
          this.issuanceTypeList = data.body;
        }
      })
    },
    //計算總額
    getSumAmount() {
      //指定日首期 & 商店設定金額
      if (this.dataForm.initialSettingType == '1' && this.dataForm.paymentAmountType == '0' && !this.global.isEmpty(this.dataForm.totalTerm)) {
        this.sumAmount = parseInt(this.dataForm.eachIssueAmount) * parseInt(this.dataForm.totalTerm);
      }
      //1.指定日首期 & 消費者定 2.立即首期
      else if (!this.global.isEmpty(this.dataForm.paymentAmount) && !this.global.isEmpty(this.dataForm.totalTerm)) {
        this.sumAmount = parseInt(this.dataForm.paymentAmount) * parseInt(this.dataForm.totalTerm);
      }
    },
    checkedIsVerification() {
      if (parseInt(this.dataForm.paymentAmount) >= parseInt(this.dataForm.verificationAmount)) {
        this.dataForm.verificationFlag = 1;
        this.isExistIdentity = true
      } else {
        this.dataForm.verificationFlag = 0;
        this.isExistIdentity = false
      }
    },
    changeCellphoneNumber(val) {
      this.dataForm.verificationCode = '';
      this.cellphoneNumberCheck(this.dataForm.cellphoneNumber);
    },
    getVerificationType() {
      this.$http({
        url: this.$http.adornUrl('order/verificationInfo'),
        method: 'post',
        data: this.$http.adornData({
          phoneNumber: this.dataForm.cellphoneNumber
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          this.verificationType = data.body.verificationType
        }
      })
    },
    checkPhone(phone) {
      let regex = /^09\d{8}$/;
      if (!regex.test(phone)) {
        return true
      } else {
        return false
      }
    },
    cellphoneNumberCheck(val) {
      let rg = /^09\d{8}$/;
      if (this.global.isEmpty(val)) {
        this.validateCellphoneNumber = (rule, value, callback) => {
          this.cellphoneNumberMsg = '必填欄位不得為空白 Field required';
          callback(new Error())
        }
      } else {
        this.dataForm.cellphoneNumber = val.replace(/[^\d]/g, '');
        if (!rg.test(val)) {
          this.validateCellphoneNumber = (rule, value, callback) => {
            this.cellphoneNumberMsg = '手機號碼格式錯誤 Wrong mobile number';
            this.cellphoneNumberErr = true;
            callback(new Error())
          }
        } else {
          this.validateCellphoneNumber = (rule, value, callback) => {
            this.cellphoneNumberErr = false;
            this.getVerificationType();
            callback()
          }
        }
      }
      return
    },
    verificationCodeCheck(val) {
      if (this.global.isEmpty(val)) {
        this.validateVerificationCode = (rule, value, callback) => {
          this.verificationCodeMsg = '必填欄位不得為空白 Field required';
          callback(new Error())
        }
      } else {
        this.dataForm.verificationCode = val.replace(/[^\d]/g, '');
        if (val.length != 6) {
          this.validateVerificationCode = (rule, value, callback) => {
            this.verificationCodeMsg = '請輸入完整驗證碼 Please enter correct Verification Code';
            callback(new Error())
          }
        } else {
          this.validateVerificationCode = (rule, value, callback) => {
            callback()
          }
        }
      }
      return
    },
    idCardNumberCheck(val) {
      let reg = /^[A-Z]\d{9}$/;
      if (this.global.isEmpty(val)) {
        this.validateIdCardNumber = (rule, value, callback) => {
          this.idCardNumberMsg = '必填欄位不得為空白 Field required';
          callback(new Error())
        }
      } else {
        if (!reg.test(val)) {
          this.validateIdCardNumber = (rule, value, callback) => {
            this.idCardNumberMsg = '身分證字號錯誤 ID card number is incorrect';
            callback(new Error())
          }
        } else {
          this.validateIdCardNumber = (rule, value, callback) => {
            callback()
          }
        }
      }
    },
    //獲取短信驗證碼
    getVerificationCode() {
      this.$http({
        url: this.$http.adornUrl('verify/code/phone'),
        method: 'get',
        params: this.$http.adornParams({
          account: this.dataForm.cellphoneNumber,
          merchantCode: this.dataForm.merchantCode,
          merchantOrderId: this.dataForm.merchantOrderId,
          amount: this.dataForm.paymentAmount
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          this.sendCodeStatus = '1';
          let TIME_COUNT = 60;
          this.clickCount++;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.timer = setInterval(() => {
              if (this.count > 1 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                if (this.clickCount == 1) {
                  this.sendCodeStatus = '2';
                } else {
                  this.sendCodeStatus = '3';
                }
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
      })
    },
    switchLanguage() {
      this.currentLanguage = this.currentLanguage === 'chinese' ? 'english' : 'chinese'
    },
    showChineseDetail(tabLabel) {
      this.currentLanguage = 'chinese'  // 切換到中文
      this.activeTab = tabLabel
      this.showPopup = true
    },
    showEnglishDetail(tabLabel) {
      this.currentLanguage = 'english'  // 切換到英文
      this.activeTab = tabLabel
      this.showPopup = true
    },
    getTabLabel(tabName) {
      const labels = {
        'privacy-law': {
          chinese: '個人資料提供同意書',
          english: 'Contents for Provision of Personal Data'
        },
        'service-terms': {
          chinese: '紅陽科技網路代理收付服務契約',
          english: 'Service Agreement'
        },
        'data-transfer': {
          chinese: '消費者個資移轉商店使用同意書',
          english: 'Transfer of Personal Information'
        }
      }
      return labels[tabName][this.currentLanguage]
    },
    getTitle(tabName) {
      const titles = {
        'privacy-law': {
          chinese: '個人資料提供同意書',
          english: 'Consent for Provision of Personal Data'
        },
        'service-terms': {
          chinese: '紅陽科技網路代理收付服務契約',
          english: 'SunTech Online Payment Processing Service Agreement'
        },
        'data-transfer': {
          chinese: '消費者個資移轉商店使用同意書',
          english: 'Consumer Consent for Use of Personal Information Transfer Merchants'
        }
      }
      return titles[tabName][this.currentLanguage]
    },
    // 變更信用卡顯示模式
    changeEyeShow() {
      this.eyeShow = !this.eyeShow
    },
    //身份驗證 持卡人手機同步付款人電話
    validatePhoneNumber() {
      if (this.dataForm.contactNumber && this.isExistIdentity) {
        this.dataForm.cellphoneNumber = this.dataForm.contactNumber;
        this.dataForm.verificationCode = '';
        this.getVerificationType();
      }
      if (!this.global.isEmpty(this.dataForm.contactNumber)) {
        // 台灣手機號碼正則表達式
        const regex = /^09\d{8}$/;
        if (!regex.test(this.dataForm.contactNumber)) {
          this.phoneError = true;
        } else {
          this.phoneError = false;
        }
      } else {
        this.phoneError = false;
      }
    },
    changeAgree(index) {
      // 协议子组件默认初始化显示中文
      this.agreeComponents.forEach((agreeItemRef, aIndex) => {
        if (this.$refs[agreeItemRef]) {
          this.$refs[agreeItemRef].init()
        }
      })
      // 第几个协议展开
      this.agreeList[index] = !this.agreeList[index]
      // 当前未点击的协议收起
      this.agreeList.forEach((item, i) => {
        if (index !== i) {
          this.agreeList[i] = false
        }
      })
      this.$forceUpdate()
    },
    // 四碼卡號輸入時驗證
    checkCreditCardNumberInput(number, modelKey) {
      let newNumber = number.replace(/\s/g, '').replace(/[^\d]/g, '')

      // 若在第一格貼上完整信用卡號，自動分割填入四格
      if (modelKey === 'creditCardNumber1' && newNumber.length > 4) {
        // 將 newNumber 限制為最多 16 個數字
        newNumber = newNumber.slice(0, 16)

        this.creditCardNumber1 = newNumber.slice(0, 4)
        this.creditCardNumber2 = newNumber.slice(4, 8)
        this.creditCardNumber3 = newNumber.slice(8, 12)
        this.creditCardNumber4 = newNumber.slice(12, 16)
        this.$refs.creditCardNumber4.focus()
        this.changeCreditCardNumber(newNumber)
        return
      }

      // 塞入信用卡號(creditCardNumber1~4)
      this[modelKey] = newNumber
      // 四組卡號的列表，依序查詢
      let CardNumberList = ['creditCardNumber1', 'creditCardNumber2', 'creditCardNumber3', 'creditCardNumber4']
      // 如果輸入的卡號>=4 ，開始依照順序檢查檢查其餘卡號是否輸入完成，假如未輸入完成，則跳至下一個未完成之輸入框
      if (newNumber.length >= 4) {
        CardNumberList.forEach((val, key, array) => {
          // 設定下一個key
          let selectKey = key < 3 ? (key + 1) : 0
          if (modelKey === val) {
            // 檢查下一個輸入框是否有值，假如現在的key是3，那就切換回第一個
            // 如果下一個輸入框是空值，或是輸入長度未達四位，將就將焦點切換到下一個輸入框
            if (this[CardNumberList[selectKey]] === '' || this[CardNumberList[selectKey]].length < 4) {
              this.$refs[CardNumberList[selectKey]].focus()
            }
          }
        })
      }
      // 如果四組卡號都輸入完成
      // 將卡號同步到
      this.changeCreditCardNumber(this.creditCardNumber1 + this.creditCardNumber2 + this.creditCardNumber3 + this.creditCardNumber4)
    },
    // 格式化信用卡卡號
    changeCreditCardNumber(val) {
      // 驗證卡號
      this.creditCardNumberCheck(val)
      // 1.將所有空格移除 2.去除非數字的字元 3.信用卡號超過 19 位數時加上空格。
      let cardNumber = val.replace(/\s/g, '').replace(/[^\d]/g, '').replace(/(\d{19})(?=\d)/g, '$1 ')
      // 1.移除非數字字元。 2.每 4 位數字之後插入短橫線（-）。 3.確保最後一位的短橫線不會多餘地出現。
      cardNumber = cardNumber.replace(/\D+/g, '').replace(/(\d{4})/g, '$1-').replace(/-$/, '')

      this.dataForm['creditCardNumber'] = cardNumber
    },
    //判斷是否需要身分驗證
    checkAmountShowOtp(amount) {
      this.$http({
        url: this.$http.adornUrl('/verify/verificationFlag'),
        method: 'get',
        params: this.$http.adornParams({
          amount: amount
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          //是否需要身分驗證【0 不需要】【1 需要】
          this.dataForm.verificationFlag = data.body.verificationFlag;
          if (this.dataForm.verificationFlag == '1') {
            this.isExistIdentity = true;
          } else {
            this.isExistIdentity = false;
          }
        }
      })
    },

    getInfo() {
      this.loading = true
      this.$http({
        //url: 'http://192.168.18.53:8090/cashier-service/fixedTermAmountMaster/periodicAllData',
        url: this.$http.adornUrl('/fixedTermAmountMaster/periodicAllData'),
        method: 'post',
        data: this.$http.adornData({
          threeCode: this.threeCode,
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          this.dataForm = {
            ...this.dataForm,
            ...data.body
          }
          this.isExistIdentity = this.dataForm.verificationFlag == 1 ? true : false;
          this.getSumAmount();
          // 第一順位收單行
          this.payPlatformId = data.body.payPlatformId;
          this.updateSelectedPayPlatformImg();
        } else {
          this.$router.push({name: 'orderfinish', query: {msg: data.resultMessage}})
        }
        this.loading = false
        this.mainLoading = false
      })
    },
    updateSelectedPayPlatformImg() {
      this.selectedPayPlatformImg = this.payPlatformImgs[this.payPlatformId]
    },
    changePaperCheck() {
      if (this.invoicePaperChecked) { //紙本發票選中
        this.dataForm.invoicePaper = '1';
      } else {
        this.dataForm.invoicePaper = '0';
        this.dataForm.city = '';
        this.dataForm.area = '';
        this.dataForm.address = '';
      }
    },

    /**
     * 下一步
     */
    nextStep() {

      if (this.dataForm.invoicePaper == '1' && (this.dataForm.invoiceStatus == 0 || this.dataForm.invoiceStatus == 3)) {
        if (!this.dataForm.city || !this.dataForm.area || !this.dataForm.address) {
          return
        }
      }
      // //判斷付款人手機號格式
      // if(this.phoneError){
      // 	return
      // }
      // if(this.matchingLogistics && this.recipientPhoneErr){
      // 	return
      // }
      //手機條碼//捐贈
      if (this.dataForm.invoiceStatus == 2 || this.dataForm.invoiceStatus == 4) {
        this.dataForm.invoicePaper = '0';
      }
      if (this.dataForm.city != '') {
        this.dataForm.invoiceAddress = this.cityList.find(item => {
          return item.cityId == this.dataForm.city
        }).cityName
      }
      if (this.dataForm.city != '' && this.dataForm.area != '') {
        this.dataForm.invoiceAddress = this.cityList.find(item => {
          return item.cityId == this.dataForm.city
        }).cityName + this.areaList.find(item => {
          return item.areaId == this.dataForm.area
        }).areaName + this.dataForm.address
      }
      // //發證時間
      // if(this.isExistIdentity){
      // 	if(this.selectedYear && this.selectedMonth && this.selectedDay){
      // 	  this.dataForm.issuanceDate = this.selectedYear + this.selectedMonth + this.selectedDay;
      // 	  this.issuanceDateIsEmpty = '-';
      // 	}else{
      // 	  this.issuanceDateIsEmpty = '';
      // 	  return
      // 	}

      // }
      //發證時間
      if (this.isExistIdentity && this.verificationType == 1) {
        if (this.selectedYear && this.selectedMonth && this.selectedDay) {
          this.dataForm.issuanceDate = this.selectedYear + this.selectedMonth + this.selectedDay;
          this.issuanceDateIsEmpty = '-';
        } else {
          this.issuanceDateIsEmpty = '';
          return
        }
      }
      this.dataForm.smsVerificationCode = this.dataForm.verificationCode;
      this.dataForm.cardholderCellphoneNumber = this.dataForm.cellphoneNumber;

      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.checkedPaymentMethod();
        }
      })

    },
    checkedPaymentMethod() {
      this.loading = true
      this.mainLoading = true
      this.$http({
        url: this.$http.adornUrl('/quickPay/quickPayOrder/checkPaymentMethodStatus'),
        // url:'http://192.168.18.53:8090/cashier-service/quickPay/quickPayOrder/checkPaymentMethodStatus',
        method: 'get',
        params: this.$http.adornParams({
          quickPayUrl: this.secondCode,
          modeCode: this.payMethod,
          logisticsModeCode: '',
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          this.checkedAmount();
        } else {
          this.$message.error(data.resultMessage);
          this.loading = false
          this.mainLoading = false
        }
      })
    },
    checkedAmount() {
      this.$http({
        url: this.$http.adornUrl('/quickPay/quickPayOrder/checkAmount'),
        // url:'http://192.168.18.53:8090/cashier-service/quickPay/quickPayOrder/checkAmount',
        method: 'get',
        params: this.$http.adornParams({
          quickPayUrl: this.secondCode,  //第二段url
          modeCode: this.payMethod,
          amount: this.dataForm.paymentAmount,
          periodFlag: '1'  //交易來源標識 [空或0 網址付] [1定期定額]
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          if(!this.dataForm.masterId || this.dataForm.masterId == ''){
            this.getMasterId();
          }
          // this.openConfirmDialog(this.dataForm)
          // this.loading = false
          // this.mainLoading= false
        } else {
          this.$message.error(data.resultMessage);
          this.loading = false
          this.mainLoading = false
        }
      })
    },
    /**
     * 獲取masterId
     */
    getMasterId(){
      let modeCode = this.payMethod;
      let lgs_flag = '0';
      let cardType = '00';
      let storeType = ''
      switch (modeCode){
        case 'DOMESTIC_CREDIT_CARD':
          cardType = '01'
          break
        case 'UNION_CARD':
          cardType = '02'
          break
        case 'SUPER_MARKET_CODE':
          cardType = '06'
          break
        case 'SUPER_MARKET_BAR':
          cardType = '07'
          break
        case 'VIRTUAL_ACCOUNT':
          cardType = '08'
          break
        case 'SUPER_PAY_PICKUP':
          cardType = '09'
          break
      }
      if(this.dataForm.logisticsModeCode=='OK_PU_CODE'){
        storeType = '3'
        lgs_flag = '1';
      }else if(this.dataForm.logisticsModeCode=='LYLFU_PU_CODE'){
        storeType = '4'
        lgs_flag = '1';
      }else if(this.dataForm.logisticsModeCode=='FAMILY_PU_CODE'){
        storeType = '2'
        lgs_flag = '1';
      }else if(this.dataForm.logisticsModeCode=='7-ELEVEN_PU_CODE'){
        storeType = '1'
        lgs_flag = '1';
      }
      let form = {
        //
        periodFlag:'1',
        // periodTermType:this.dataForm.paymentTermType,
        periodTerm:this.dataForm.totalTerm,
        initialSettingType:this.dataForm.initialSettingType,
        quickpayId:this.dataForm.quickpayId,
        web: this.dataForm.merchantCode,
        mn: this.dataForm.paymentAmount,
        td: this.dataForm.merchantOrderId,
        chkValue: '123',
        sna: this.dataForm.creditCardUserName,
        sdt: this.dataForm.creditCardPhone,
        email: this.dataForm.creditCardEmail,
        note1: this.dataForm.note,
        cardType: cardType,
        term: this.dataForm.creditCardInstallment,
        orderSource: '06', // 00收銀台 01API 02管理後臺 03商戶後臺 04swipy 05網址付 06定期定額
        productName: this.dataForm.productName,
        productPrice: this.dataForm.productPrice,
        productQuantity: this.dataForm.productQuantity,
        remark: this.dataForm.desc,
        orderInfo: this.dataForm.orderInfo,
        store_type: storeType,
        lgs_flag:lgs_flag,
        result:[
          {
            productName: this.dataForm.productName,
            productPrice: this.dataForm.productPrice,
            productQuantity: this.dataForm.productQuantity
          }
        ]
      }
      if(!form.note1){
        form.note1 = this.dataForm.desc;
      }
      this.$http({
        url: this.$http.adornUrl('/order/saveOrderMaster'),
        // url: 'http://192.168.18.53:8090/cashier-service/order/saveOrderMaster',
        method: 'post',
        data: this.$http.adornData(form)
      }).then(({data}) => {
        // console.log(data)
        this.dataForm.masterId = data.body.masterId
        this.confirmPayCard()
      })
    },
    /**
     * 信用卡付款
     */
    confirmPayCard () {
      this.goPay(this.dataForm)
    },
    /**
     * 填寫驗證碼
     */
    openConfirmDialog(row) {
      console.log(row)
      debugger
      this.$nextTick(() => {
        this.$refs['verCodeDialog'].init(row)
      })
    },
    dialogClosed() {
      console.log('dialogClosed')
      this.loading = false
      this.mainLoading = false
    },
    goPay(form) {
      // console.log(form)
      // this.dataForm.masterId = form.masterId;
      this.submitForm();
    },
    submitForm() {
      let formData = this.global.formatObj(this.dataForm);
      if (!this.global.isEmpty(formData.creditCardNumber)) {
        formData.creditCardNumber = formData.creditCardNumber.replace(/\s*/g, '').replace(/-/g, '')
      }
      if (formData.city != '') {
        formData.invoiceAddress = this.cityList.find(item => {
          return item.cityId == formData.city
        }).cityName
      }
      if (formData.city != '' && this.dataForm.area != '') {
        formData.invoiceAddress = this.cityList.find(item => {
          return item.cityId == formData.city
        }).cityName + this.areaList.find(item => {
          return item.areaId == formData.area
        }).areaName + formData.address
      }

      formData.creditCardValidFrom = formData.effectiveDate.substr(3, 5) + formData.effectiveDate.substr(0, 2);
      // console.log(formData)
      debugger

      this.$http({
        url: this.$http.adornUrl(`fixedTermAmountMaster/periodicPaymentConfirm`),    //信用卡--驗證碼接口
        // url: `http://192.168.18.53:8090/cashier-service/fixedTermAmountMaster/periodicPaymentConfirm`,
        method: 'post',
        data: this.$http.adornData(formData)
      }).then(({data}) => {
        this.verificationCodeErr = false;
        this.verificationIDErr = false;
        this.verificationIDThreeErr = false;
        if (data && data.resultCode == 200) {
          console.log(data.body)
          if (data.body) {
            //3D授權
            let newWin = window.open('', '_self')
            newWin.document.write(data.body)
            newWin.document.close()
          } else {
            //非3D授權
            if (this.dataForm.successAllPath) {
              let newWin = window.open('', '_self')
              newWin.document.write(this.dataForm.successAllPath)
              newWin.document.close()
            } else {
              this.gotoSelfPaymentResult(1)
            }
          }
        } else if (data.resultCode == 98) { //驗證碼錯誤1(或2)次
          this.$message.error(data.resultMessage);
          this.errCount++;
          this.verificationCodeErr = true;
          this.loading = false
          this.mainLoading = false
        } else if (data.resultCode == 97) {  //驗證碼錯誤3次，交易失敗
          this.$message.error(data.resultMessage);
          this.gotoSelfPaymentResult(0)
          this.loading = false
          this.mainLoading = false
        } else if (data.resultCode == 96) {  //身分驗證失敗，請重新確認
          this.$message.error(data.resultMessage);
          this.verificationIDErr = true;
          this.loading = false
          this.mainLoading = false
        } else if (data.resultCode == 95) { // 身分證末三碼錯誤
          this.$message.error(data.resultMessage);
          this.verificationIDThreeErr = true;
          this.loading = false
          this.mainLoading = false
        } else {
          this.loading = false
          this.mainLoading = false
          if ('驗證碼錯誤，請重新輸入' == data.resultMessage) {
            this.$message.error(data.resultMessage)
          } else {
            if (this.dataForm.failAllPath) {
              let newWin = window.open('', '_self')
              newWin.document.write(this.dataForm.failAllPath)
              newWin.document.close()
            } else {
              this.gotoSelfPaymentResult(0)
            }
          }
        }
      })
    },
    gotoSelfPaymentResult(errorFlag) {
      if (errorFlag == 1) {  //信用卡-支付成功頁面
        this.$router.push({
          name: 'super-quato-info',
          query: {
            masterId: this.dataForm.masterId,
            transactionAmount: this.dataForm.paymentAmount,
            // orderInfo: this.dataForm.orderInfo,
            uuid: this.dataForm.uuid
          }
        })
      } else {
        this.$router.push({name: 'error', query: {id: this.dataForm.masterId}})
      }
    },
    effectiveDateCheck(val) {
      let jss = val.replace(
          /[^0-9]/g, '' // To allow only numbers
      ).replace(
          /^([2-9])$/g, '0$1' // To handle 3 > 03
      ).replace(
          /^(1{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
      ).replace(
          /^0{1,}/g, '0' // To handle 00 > 0
      ).replace(
          /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2' // To handle 113 > 11/3
      );

      this.dataForm.effectiveDate = jss
      console.log()

      this.EffectiveDateErMsg = ''

      if (val.length < 5) {
        console.log(val.length)
        this.validateEffectiveDate = (rule, value, callback) => {
          this.EffectiveDateErMsg = val.length > 0 ? '請輸入正確有效年月' : '必填欄位不得為空白 Field required'
          callback(new Error())
        }
      } else {
        if (val[0] + val[1] > 12) {
          this.validateEffectiveDate = (rule, value, callback) => {
            this.EffectiveDateErMsg = '請輸入正確有效年月'
            callback(new Error())
          }
        } else {
          this.validateEffectiveDate = (rule, value, callback) => {
            callback()
          }
        }
      }
    },
    creditCardNumberCheck(val) {
      this.creditCardNumberMsg = '必填欄位不得為空白 Field required'
      if (val.indexOf(" ") == -1 && val.length == 16) {
        this.validateCreditCardNumber = (rule, value, callback) => {
          callback()
        }
        return
      }
      if (val.length != 19) {
        this.validateCreditCardNumber = (rule, value, callback) => {
          if (val.length == 0) {
            this.creditCardNumberMsg = '必填欄位不得為空白 Field required'
          } else {
            this.creditCardNumberMsg = '卡號輸入不完整'
          }
          callback(new Error())
        }
      } else {
        this.validateCreditCardNumber = (rule, value, callback) => {
          callback()
        }
      }
    },
    //縣市名稱
    getCityCodeName() {
      this.$http({
        url: this.$http.adornUrl(`/order/getCityName`),
        method: 'get'
      }).then(({data}) => {
        if (data && data.resultCode === 200) {
          this.cityList = data.body;
        } else {
          this.cityList = [];
        }
      }).catch((e) => {
      })
    },
    //獲取縣市名稱下面區域名
    choseCity(cityId) {
      if (cityId == '') {
        return
      }
      this.$http({
        url: this.$http.adornUrl('/order/getAreaName/' + cityId),
        method: 'get'
      }).then(({data}) => {
        if (data && data.resultCode === 200) {
          this.areaList = data.body;
        } else {
          this.areaList = [];
        }
      }).catch((e) => {
      })
    },
    //清空城市區域下來選
    clearSelect(form, model) {
      this[form][model] = ""
      this.dataForm.city = ''
      this.areaList = []
    },
    //發票radio改變
    changeInvoiceStatus(val) {
      //自然人憑證條碼
      this.dataForm.carrierIdPerson = '';
      //手機條碼
      this.dataForm.carrierIdMobile = '';
      //統一編號
      this.dataForm.identifier = '';
      //捐贈碼
      this.dataForm.poban = '';
      this.dataForm.invoiceHead = '';
    },
    // 獲取捐贈號碼數據列表
    getPobanDataList() {
      this.$http({
        url: this.$http.adornUrl('/sys/donateinvoice/getList'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data && data.resultCode === 200) {
          if (data.body) {
            this.pobanList = data.body;
          } else {
            this.pobanList = [];
          }
        }
      })
    },
    icCardFrontSuccess(response, file, fileList) {
      this.dataForm.idCardPhotoFrontResourceId = response.body.resourceId;
      this.global.getBlob(response.body.resourceId, (result) => {
        this.idCard1 = result;
      })
    },
    icCardBackSuccess(response, file, fileList) {
      this.dataForm.idCardPhotoBackResourceId = response.body.resourceId;
      this.global.getBlob(response.body.resourceId, (result) => {
        this.idCard2 = result;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/payment.scss";

.card-form-line.el-divider.el-divider--horizontal {
  margin-top: 0 !important;
  margin-bottom: 20px !important;
}

::v-deep .el-input {
  .el-input-group__prepend {
    border: 0px;
    color: #525252;
    font-size: 16px;
    font-weight: 400;
  }
}

::v-deep .el-form--label-top {
  .el-form-item {
    margin-top: 10px !important;

    .el-input__inner {
      font-size: 16px !important;
      font-weight: 400 !important;
      color: #525252;
      background-color: #EDEDED;
      border-color: #EDEDED;
    }

    .el-textarea__inner {
      font-size: 16px !important;
      font-weight: 400 !important;
      color: #525252;
      background-color: #EDEDED;
      border-color: #EDEDED;
    }

    &__label {
      line-height: 23.12px !important;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.9px;
      padding: 0 0 5px !important;
      color: #525252 !important;

    }

    .el-form-item__error {
      color: #FF0000 !important
    }
  }
}

::v-deep .ol-el-input {
  .el-input__inner {
    text-align: center;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #525252;
    background-color: #EDEDED;
    border-color: #EDEDED;
  }

  .el-textarea__inner {
    text-align: center;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #525252;
    background-color: #EDEDED;
    border-color: #EDEDED;
  }
}

::v-deep .le-el-input {
  .el-input__inner {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #525252;
    background-color: #EDEDED;
    border-color: #EDEDED;
  }

  .el-textarea__inner {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #525252;
    background-color: #EDEDED;
    border-color: #EDEDED;
  }
}


::v-deep .right-image-input {
  .el-input__inner {
    padding-right: 60px;
  }

}

::v-deep .selet-drop {
  .el-input__suffix {
    display: none;
  }

  .el-select-dropdown__item.selected {
    color: #78B689 !important;
    font-weight: 700;
  }
}

::v-deep .el-cascader .el-input .el-icon-arrow-down {
  display: none;
}

.right-image {
  width: 38px;
  position: absolute;
  right: 13px;
  top: 6px;
}

.order-txt {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.pay-rad-txt {
  font-weight: 600;
  color: #2A2A2A;
  font-size: 16px;
  line-height: 42px;
}

.box-txt-bl-s {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  line-height: 20px;
}

.box-txt-bl-b {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  line-height: 25px;
}

.box-txt-rd {
  font-weight: 600;
  color: #8F0304;
  font-size: 16px;
  line-height: 20px;
}

.error-msg {
  display: flex;
  flex-direction: row;
  line-height: 20px;
  position: absolute;

  .img {
    width: 16px;
    height: 16px;
    margin-top: 3px;
  }

  .txt {
    margin-left: 5px;
    color: #FF0000;
    font-size: 12px;
    font-weight: 400;
    width: 100%;
  }
}

.pay-form-radio ::v-deep .el-radio__inner {
  border: 2px solid #949494;
  width: 15px;
  height: 15px;
}

.pay-form-radio ::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #1DB145 !important;
  border: 2px solid #1DB145 !important;
  background: #ffffff !important;
}

.pay-form-radio ::v-deep .el-radio__inner::after {
  width: 6px;
  height: 6px;
  background-color: #1DB145 !important;
}

.pay-form-radio ::v-deep .el-radio__input {
  color: #2A2A2A;
}

.pay-form-radio ::v-deep .el-radio__label {
  color: #2A2A2A;
  font-size: 20px !important;
  font-weight: bold;
}

.pay-form-radio ::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #2A2A2A;
  font-size: 20px !important;
  font-weight: bold;
}

.pay-form-check ::v-deep .el-checkbox {
  color: #2A2A2A !important;
}

.pay-form-check ::v-deep .el-checkbox__label {
  font-size: 20px !important;
  font-weight: bold;
}

.pay-form-check ::v-deep .el-checkbox__inner {
  border: 1px solid #606060 !important;
  width: 16px !important;
  height: 16px !important;
}

.pay-form-check ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #606060 !important;
}

.pay-form-check ::v-deep .el-checkbox__inner:hover {
  // border: 1px solid #606060;
  border-color: #606060 !important;
}

.pay-form-check ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #2A2A2A;
  font-size: 20px !important;
}

.pay-form-check ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #1DB145;
  border-color: #1DB145;
}

.pay-form-check ::v-deep .el-checkbox__inner::after {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  content: "";
  border: 2px solid #FFF;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 1px;
  width: 3px;
  -webkit-transition: -webkit-transform .15s ease-in .05s;
  transition: -webkit-transform .15s ease-in .05s;
  transition: transform .15s ease-in .05s;
  transition: transform .15s ease-in .05s, -webkit-transform .15s ease-in .05s;
  transition: transform .15s ease-in .05s, -webkit-transform .15s ease-in .05s;
  -webkit-transform-origin: center;
  transform-origin: center;
}

.information-form-check ::v-deep .el-checkbox {
  color: #8F0304 !important;
}

.information-form-check ::v-deep .el-checkbox__label {
  font-size: 16px !important;
}

.information-form-check ::v-deep .el-checkbox__inner {
  border: 1px solid #8F0304 !important;
  width: 16px !important;
  height: 16px !important;
}

.information-form-check ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #8F0304 !important;
}

.information-form-check ::v-deep .el-checkbox__inner:hover {
  // border: 1px solid #606060;
  border-color: #8F0304 !important;
}

.information-form-check ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #8F0304;
  font-size: 16px !important;
}

.information-form-check ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #8F0304;
  border-color: #8F0304;
}

.information-form-check ::v-deep .el-checkbox__inner::after {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  content: "";
  border: 2px solid #FFF;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 1px;
  width: 3px;
  -webkit-transition: -webkit-transform .15s ease-in .05s;
  transition: -webkit-transform .15s ease-in .05s;
  transition: transform .15s ease-in .05s;
  transition: transform .15s ease-in .05s, -webkit-transform .15s ease-in .05s;
  transition: transform .15s ease-in .05s, -webkit-transform .15s ease-in .05s;
  -webkit-transform-origin: center;
  transform-origin: center;
}

.next-btn {
  line-height: 25px;
  background: #8F0304;
  font-weight: 600;
  font-size: 26px;
  height: 64px;
  width: 320px;
}

.next-btn-dis {
  line-height: 25px;
  background: #a6a6a6;
  border: #a6a6a6;
  font-weight: 600;
  font-size: 26px;
  height: 64px;
  width: 320px;
}

.next-btn-dis ::v-deep .el-button--danger.is-disabled, .el-button--danger.is-disabled:active, .el-button--danger.is-disabled:focus, .el-button--danger.is-disabled:hover {
  color: #FFF;
  background-color: #a6a6a6 !important;
  border-color: #a6a6a6 !important;
}

.box-payForm-card {
  margin-left: -15px;

  .txt {
    font-size: 8px;
    font-weight: 400;
    color: #525252;
    width: 200px;
    margin-left: -5px;
    margin-top: 10px
  }

  .img {
    width: 200px;
    margin-left: -5px
  }
}

.box-payForm-card ::v-deep .el-checkbox {
  color: #525252 !important;
}

.box-payForm-card ::v-deep .el-checkbox__inner {
  border: 1px solid #606060 !important;
}

.box-payForm-card ::v-deep .el-checkbox__input {
  margin-top: -45px;
}

.box-payForm-card ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #606060 !important;
}

.box-payForm-card ::v-deep .el-checkbox__inner:hover {
  // border: 1px solid #606060;
  border-color: #606060 !important;
}

.box-payForm-card ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #525252;
}

.box-payForm-card ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #606060;
  border-color: #606060;
}

.g-b {
  padding-top: 50px;
  padding-bottom: 0px;
}

.w-b {
  //padding: 0 140px !important;
  border-radius: 38px 38px 0 0;
}

.top-logo {
  width: 100%;
  //height: 250px;
  //margin-top: -30px;
  text-align: center;
}

.payment-valid-until {
  display: inline-block;
  vertical-align: middle;
  color: #9D2323;
  font-size: 16px;
  font-weight: 600;
}

.clock-img {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin: 1px 5px;
}

.tb-txt {
  display: inline-block;
  vertical-align: middle;
  color: #8F918E;
  font-size: 16px;
  font-weight: 600;
}

.or-information {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .title-24 {
    font-size: 24px;
    font-weight: 600;
    color: #03328D;
  }

  .title-16 {
    font-size: 16px;
    font-weight: 600;
    color: #03328D;
    line-height: 42px;
    margin-left: 10px;
  }

  .bk-img {
    width: 252px;
    height: 24px;
    margin-top: 16px;
    margin-left: 12px;
    position: absolute;
  }

  .mer-name-l {
    color: #8F918E;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    vertical-align: middle;
    width: 210px;
    margin-top: 20px;
  }

  .mer-name-span {
    color: #ff1400;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    vertical-align: middle;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .mer-name-r {
    color: #8F918E;
    font-size: 16px;
    font-weight: 400;
    margin-left: -10px;
    display: inline-block;
    vertical-align: middle;
    margin-top: 20px;
    width: calc(100% - 210px)
  }

  .order-name {
    color: #2A2A2A;
    font-weight: 600;
    font-size: 26px;
    margin-top: 20px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    // width: calc(100% - 200px);
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  .order-amount-l {
    color: #8F918E;
    font-weight: 400;
    font-size: 16px;
    padding-top: 18px;
    border-top: 2px dashed #E3E3E3;
    width: 83%;
  }

  .order-amount-r {
    font-weight: 600
  }

  .pay-amount {
    color: #9D2323;
    font-weight: 600;
    font-size: 24px;
    margin-top: 10px;
  }

  .quota-info {
    color: #8F918E;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .quota-amount-title {
    color: #8F918E;
    font-weight: 600;
    font-size: 24px;
    padding-left: 50px;
  }

  .quota-amount-a {
    color: #9D2323;
    font-weight: 800;
    font-size: 30px;
    margin-top: 23px;
    display: flex;
    justify-content: center;
  }

  .quota-amount-b {
    margin-top: 30px;
    display: flex;
    justify-content: center;

  }
}

::v-deep.quota-style {
  margin-top: 20px;
  // display: flex;
  // justify-content: center;
  border: 2px solid #e0e5ff;
  border-radius: 10px;
  width: 100%;

  .el-form-item {
    margin-bottom: 0px;
    margin-top: 0px !important;
    display: flex;
    align-items: center;

    &__label {
      color: #241d53 !important;
      font-weight: 700;
    }
  }
}

.pay-method {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .tit-area {
    display: flex;
  }

  .tit-cn {
    font-size: 24px;
    font-weight: 600;
    color: #03328D;
  }

  .tit-en {
    font-size: 16px;
    font-weight: 600;
    color: #03328D;
    line-height: 42px;
    margin-left: 10px;
  }

  .tit-bk {
    width: 292px;
    height: 24px;
    margin-top: 16px;
    margin-left: 12px;
    position: absolute;
  }

  .cre-card-img-1 {
    width: 18px;
    height: 18px;
    margin-left: 5px;
    top: 3px;
  }

  .cre-card-img-2 {
    width: 26px;
    height: 25px;
    margin-left: 1px;
    top: 7px;
  }

  .cre-card-img-3 {
    width: 18px;
    height: 18px;
    margin-left: 3px;
    top: 3px;
  }

  .gg-img {
    width: 50px;
    height: 19px;
    margin-left: 3px;
    top: 5px;
  }

  .ap-img {
    width: 50px;
    height: 19px;
    margin-left: 3px;
    top: 5px;
  }

  .su-img {
    width: 50px;
    height: 22px;
    margin-left: 3px;
    top: 6px;
  }

  .un-card {
    width: 18px;
    height: 18px;
    margin-left: 3px;
    top: 3px;
  }

  .piiluu {
    width: 18px;
    height: 18px;
    margin-left: 3px;
    top: 3px;
  }

  .n-card {
    width: 140px;
    height: 18px;
    margin-left: 3px;
    top: 3px;
  }
}

.pay-information {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .tit-area {
    display: flex
  }

  .tit-cn {
    font-size: 24px;
    font-weight: 600;
    color: #03328D;
  }

  .tit-en {
    font-size: 16px;
    font-weight: 600;
    color: #03328D;
    line-height: 42px;
    margin-left: 10px;
  }

  .tit-bg {
    width: 275px;
    height: 24px;
    margin-top: 16px;
    margin-left: 12px;
    position: absolute;
  }
}

@font-face {
  font-family: 'myFont';
  src: url('../assets/font/jost-black.ttf');
}

.self-font {
  //font-family: 'myFont', serif;
  font-size: 16px;
}

.tit-no {
  font-size: 16px;
  font-weight: 600;
  color: #C0C0C0;
  margin-top: 10px;
  position: absolute;
  right: 49%;
}

@media(max-width: 991px) {
  .tit-no {
    position: relative;
    right: 0;
    text-align: center;
  }
}


::v-deep .el-radio__label {
  font-size: 14px !important;
}

.paymentMethod {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

.order-txt-center {
  width: calc(100% - 80px);
}

@media(max-width: 767px) {
  .paymentMethod {
    display: block;
    width: 100%;
  }
  .or-information .order-name {
    width: calc(100% - 100px) !important;
  }
  .order-txt-center {
    width: calc(100% - 40px);
  }
}

.paymentFormItem0 {
  display: inline-block;
  vertical-align: top;
  background-color: #f1f3ff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 10px;
  width: 40%;
}

.paymentFormItem1 {
  display: inline-block;
  vertical-align: top;
  // border: 2px solid #e0e5ff;
  // border-bottom-right-radius: 10px;
  // border-top-right-radius: 10px;
  padding: 10px;
  align-content: center;
  width: 60%;
}

.paymentFormItem {
  display: inline-block;
  vertical-align: top;
  width: 48%;
}

.paymentFormItem2 {
  display: inline-block;
  vertical-align: top;
  width: 48%;
  margin-top: 12px;
}

.paymentFormItem ~ .paymentFormItem {
  margin-left: 2%;
}

@media(max-width: 991px) {
  .quota-style {
    margin-top: 20px;
    // display: flex;
    // justify-content: center;
    border: 2px solid #e0e5ff;
    border-radius: 10px;
    width: 100%;

    .el-form-item {
      margin-bottom: 0px;
      margin-top: 0px !important;
      display: flex;
      align-items: center;

      &__label {
        color: #241d53 !important;
        font-weight: 700;
      }
    }
  }

  .paymentFormItem {
    display: block;
    width: 100%;
  }
  .paymentFormItem0 {
    display: block;
    width: 100%;
  }
  .paymentFormItem1 {
    display: block;
    width: 100%;
  }
  .paymentFormItem2 {
    display: block;
    width: 100%;
  }
  .paymentFormItem ~ .paymentFormItem {
    margin-left: 0;
  }
}

.information-form-check ::v-deep .box-txt-bl-b {
  white-space: break-spaces;
  vertical-align: top;
}

.information-form-check ::v-deep .el-checkbox__input {
  vertical-align: top;
  margin-top: 4px;
}

.invoiceItem {
  display: inline-block;
  vertical-align: top;
  width: 48%;
}

.slide-box {
  width: 80%;
  margin: 30px auto;
  text-align: center;
}

.slide-box ::v-deep .el-carousel {
  &__button {
    height: 16px;
    width: 16px;
    border-radius: 10px;
    background-color: #999999;
  }

  &__indicator.is-active {
    .el-carousel__button {
      background-color: #9D2323;
    }
  }

  &__indicator--horizontal {
    padding: 20px 4px 12px 4px;
  }

  &__arrow {
    display: none !important;
  }

  &__item {
    background-color: #dddddd;
  }
}

.order-number-box {
  padding-bottom: 18px;

}

.order-number-title {
  font-size: 16px;
  color: #8F918E;
  margin-top: 10px;

  & .redText {
    color: #FF0000;
  }
}

::v-deep .order-number-input {
  display: inline-block;
  vertical-align: middle;
  width: 180px;
  height: 40px;
  background-color: #EDEDED;
  border-radius: 8px;
  padding: 0px 48px;
  box-sizing: border-box;
  position: relative;
  margin-top: 3px;

  & input {
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    background: transparent;
  }

  .el-input .el-input__inner {
    padding: 0;
    border: 0;
    background: transparent;
    line-height: 40px;
    height: 40px !important;
    text-align: center;
  }
}

.num-sub {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 12px;
  top: 8px;
}

.num-add {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 12px;
  top: 8px;
}

.inventory-text {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  color: #C0C0C0;
  margin-left: 20px;
  margin-top: 3px;

}

.goods-desc {
  color: #525252;
  font-size: 16px;
  height: 36px;
  overflow: hidden;
}

::v-deep .priceInput {
  display: inline-block;
  width: 200px;

  input {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #525252;
    background-color: #EDEDED;
    border-color: #EDEDED;
  }
}

.identity-tips {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  margin: 20px 0px;
  line-height: 25px;
}

.identity-title {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 25px;
}

.identity-title2 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  line-height: 25px;
}

////////////////////////

/* 睜眼/閉眼樣式 */
.card-image {
  width: 20%;
  max-height: 20px;
  margin-left: 5px;
  cursor: pointer;
}

/* 基本樣式 */
.information-form-check {
  position: relative; /* 確保浮動視窗位置正確 */
}

/* 彈跳視窗內容 */
.popup-content {
  padding: 20px;
  font-size: 16px;
  background-color: #E0E0E0;
  color: #000000;
  border-radius: 5px;
  line-height: 1.4em;
}

.details, .title {
  word-break: normal;
}

.fixed-tabs {
  margin: 15px 0;
}

.title {
  margin: 15px 0;
}

/* 中英文切換按鈕 */
.language-btn {
  width: 80px;
  height: 25px;
  background: #BEBEBE;
  border: #BEBEBE;
  font-size: 12px;
  font-weight: 600;
  border-radius: 25px;
  padding: 3px;
}

.language-btn:hover {
  background-color: #8F0304; /* 鼠標懸停時的背景色 */
}

/* 連結樣式 */
.link {
  color: #4F4F4F;
  text-decoration: underline;
  cursor: pointer;
}

/* 頁腳圖片樣式 */
.footer-img {
  max-width: 200px;
  width: 100%;
}

.numbered-list2 {
  padding-left: 13px; /* 調整編號與內容縮排 */
  text-indent: -0.84em; /* 確保編號與文本對齊 */
}

.cn-numbered-list {
  padding-left: 1.55em; /* 總共空出 1 個半形 + 1 個全形 */
  text-indent: -1.55em; /* 編號向左移動以與其他文本對齊 */
}

.numbered-list {
  padding-left: 1.25em; /* 調整編號與內容縮排 */
  text-indent: -1.25em; /* 確保編號不會被縮排，與其他文本對齊 */
}

@media (max-width: 1024px) {
  .el-dialog {
    width: 80%; /* 當螢幕寬度小於1024px時 */
  }
  .popup-content {
    padding: 10px; /* 修改: 調整內邊距 */
  }
  .fixed-tabs h2 {
  }
  .language-btn {
    margin-bottom: 10px;
  }

  /* 頁腳樣式 */
  td {
    font-size: 14px;
  }
  .footer-img {
    max-width: 175px;
    width: 90%;
  }
}

@media (max-width: 768px) {
  .popup-content {
    padding: 18px; /* 修改: 調整內邊距 */
  }
  /* 睜眼/閉眼樣式 */
  .card-image {
    margin-top: 5px;
  }
}

@media (max-width: 414px) {
  .popup-content {
    padding: 15px; /* 修改: 調整內邊距 */
  }
  .pay-form-check-txt {
    font-size: 16px;
  }
  /* 睜眼/閉眼樣式 */
  .card-image {
    margin-top: 12px;
  }
}

</style>
<style>
.el-tabs .el-tabs__item {
  text-align: center; /* 使文字水平居中 */
  padding: 15px 20px; /* 調整內邊距 */
  line-height: 1; /* 設定行高以垂直居中 */
  font-size: 14px;
  border-radius: 2px;
}

.el-tabs .el-tabs__item.is-active {
  background-color: #8F0304 !important; /* 背景色 */
  color: white !important; /* 白色文字 */
  font-weight: bold; /* 設定文字為粗體 */
}

.el-tabs .el-tabs__item:not(.is-active) {
  color: grey; /* 非活動頁籤的文字顏色 */
}

.el-dialog {
  font-family: 'Roboto', sans-serif;
}

</style>
