<template>
	<!-- 定期定额开单成功 -->
  <div class="payment grey-bg" style="padding-top: 1px; position: relative;" ref="content">
    <div class="container box" style="position: relative; z-index: 9;">
      <el-row class="txt-cen">
        <el-col>
          <el-image style="width: 100px;" :src="successIconImgUrl"></el-image>
        </el-col>
      </el-row>
      <el-row  class="m-top-20 txt-cen">
        <el-col>
          <span class="top-txt">定額定期開單成立 付款成功</span>
        </el-col>
      </el-row>
      <el-row class="txt-cen m-top-15">
        <el-col>
          <span class="top-txt">Order completed. Payment Success!</span>
        </el-col>
      </el-row>
      <el-row class="txt-cen m-top-25">
        <el-col>
          <span class="top-txt-g">NT <span v-format="'$#,##0'">{{dataForm.transactionAmount}}</span></span>
        </el-col>
      </el-row>
      <el-row class="txt-cen m-top-20">
        <el-col>
          <el-image class="line" style="max-width: 100%;" :src="lineImgUrl"></el-image>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="m-top-20">
        <el-col :span="12" >
          <span class="bot-txt-g">特店名稱 Merchant Name</span>
        </el-col>
        <el-col :span="12" class="txt-end">
          <span class="bot-txt-b">{{dataForm.merchantName}}</span>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="m-top-20" >
        <el-col :span="12" >
          <span class="bot-txt-g">紅陽交易編號 Sunpay Transaction No.</span>
        </el-col>
        <el-col :span="12" class="txt-end">
          <span class="bot-txt-b">{{dataForm.orderId}}</span>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="m-top-20" >
        <el-col :span="12" >
          <span class="bot-txt-g">特店訂單編號 Merchant Order No.</span>
        </el-col>
        <el-col :span="12" class="txt-end">
          <span class="bot-txt-b">{{dataForm.merchantOrderId}}</span>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="m-top-20" >
        <el-col :span="12" >
          <span class="bot-txt-g">交易時間 Payment Time</span>
        </el-col>
        <el-col :span="12" class="txt-end">
          <span class="bot-txt-b">{{dataForm.createOrderTime}}</span>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="m-top-20">
        <el-col :span="12" >
          <span class="bot-txt-g">交易金額 Payment Amount</span>
        </el-col>
        <el-col :span="12" class="txt-end">
          <span class="bot-txt-b">NT<span v-format="'$#,##0'">{{dataForm.transactionAmount}}</span></span>
        </el-col>
      </el-row>
	  <!-- <el-row type="flex" justify="space-between" class="m-top-20">
	    <el-col :span="12">
	      <span class="bot-txt-g">取貨門市地址 Store Address</span>
	    </el-col>
	    <el-col :span="12" class="txt-end">
	      <span class="bot-txt-b">{{dataForm.storeAddress}}</span>
	    </el-col>
	  </el-row> -->
	  <!-- <el-row type="flex" justify="space-between" class="m-top-20" >
	    <el-col :span="12">
	      <span class="bot-txt-g">寄件代碼 Shipping code</span>
	    </el-col>
	    <el-col :span="12" class="txt-end">
	      <span class="bot-txt-b">{{dataForm.shippingCode}}</span>
	    </el-col>
	  </el-row> -->
	  <el-row type="flex" justify="space-between" class="m-top-20" >
	    <el-col :span="12">
	      <span class="bot-txt-g">定期定額單號 Periodic Order No.</span>
	    </el-col>
	    <el-col :span="12" class="txt-end">
	      <span class="bot-txt-b">{{dataForm.fixedTermAmountMasterId}}</span>
	    </el-col>
	  </el-row>
	  <el-row type="flex" justify="space-between" class="m-top-20" >
	    <el-col :span="12">
	      <span class="bot-txt-g">總期數 Number of Period</span>
	    </el-col>
	    <el-col :span="12" class="txt-end">
	      <span class="bot-txt-b">{{dataForm.totalTerm}}</span>
	    </el-col>
	  </el-row>
	  <el-row type="flex" justify="space-between" class="m-top-20" >
	    <el-col :span="12">
	      <span class="bot-txt-g">總金額 Total amount</span>
	    </el-col>
	    <el-col :span="12" class="txt-end">
	      <span class="bot-txt-b">NT<span v-format="'$#,##0'">{{dataForm.paymentAmount}}</span></span>
	    </el-col>
	  </el-row>

      <el-row class="m-top-40 txt-cen">
        <el-col>
          <el-image class="line" style="max-width: 100%;" :src="lineImgUrl"></el-image>
        </el-col>
      </el-row>

      <el-row type="flex" justify="center" class="m-top-20" style="text-align: center">
        <el-col :span="19">
          <span class="bottom-txt">本次交易已透過紅陽支付金流完成付款</span>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" style="text-align: center">
        <el-col :span="19">
          <span class="bottom-txt">The transaction has been completed through SunPay.</span>
        </el-col>
      </el-row>


      <div  class="m-top-20 txt-cen">
        <el-button type="danger"  class="next-btn" @click="captrue">
          <template>
            <el-image class="img" :src="imageDownImgUrl"></el-image>
            <span class="txt">儲存畫面 Save Screen</span>
          </template>
        </el-button>
      </div>

      <el-row  class="m-top-40 txt-cen">
        <el-col>
<!--          <el-image style="width: 600px; max-width: 100%;" :src="footerFinishImgUrl"></el-image>-->
          <el-image style="width: 200px; max-width: 100%;" :src="sLogImgUrl"></el-image>
        </el-col>
      </el-row>
    </div>


    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import storLogoImg from '../../assets/img/new-payment/store-logo.png'
import successIconImg from '../../assets/img/new-payment/success-icon.png'
import lineImg from '../../assets/img/new-payment/line.png'
import sLogImg from '../../assets/img/new-payment/s-logo.png'
import footerFinishImg from '../../assets/img/new-payment/footer-finish.png'
import imageDownImg from '../../assets/img/new-payment/image-down.png'
import sLineImg from '../../assets/img/new-payment/s-line.png'
  export default {
    name: 'super-quato-info',
    data () {
      return {
        titleIcon: require('../../assets/img/payment/pay-icon-3.png'),
        greenIcon: require('../../assets/img/payment/pay-icon-1.jpg'),
        dataForm: {
          // 交易金額
          transactionAmount: '',
          // 特店名稱
          merchantName: '',
          // 客服電話
          phoneNumber: '',
          // 紅陽訂單編號
          orderId: '',
          // 交易時間
          createOrderTime: '',
          // 交易內容
          orderInfo: '',
          // 驗證碼
          uuid: '',
          // 商店自訂編號
          merchantOrderId: '',
        },
        storLogoImgUrl: storLogoImg,
        successIconImgUrl: successIconImg,
        lineImgUrl: lineImg,
        sLogImgUrl: sLogImg,
        imageDownImgUrl: imageDownImg,
        sLineImgUrl: sLineImg,
        footerFinishImgUrl: footerFinishImg,
        dataList:[],
		//取貨門市地址
		storeAddress:'',
		//寄件代碼
		// shippingCode:'',
      }
    },
    mounted () {
      this.showInfo()
    },
    methods: {
      showInfo () {
        this.$http({
          url: this.$http.adornUrl(`/order/getCreditCard`),
          method: 'get',
          params: this.$http.adornParams({
            masterId: this.$route.query.masterId
          })
        }).then(({data}) => {
          if (data && data.resultCode === 200) {
            this.dataForm.uuid = this.$route.query.uuid
            this.dataForm.masterId = this.$route.query.masterId
            //this.dataForm.transactionAmount = this.$route.query.transactionAmount
            this.dataForm.transactionAmount = data.body.orderAmount
            this.dataForm.orderInfo = this.$route.query.orderInfo
            this.dataForm.merchantName = data.body.merchantName
            this.dataForm.createOrderTime = data.body.createOrderTime
            this.dataForm.orderId = data.body.orderId
            this.dataForm.merchantOrderId = data.body.merchantOrderId
            this.dataForm.phoneNumber = data.body.phoneNumber
			this.dataForm.storeAddress = data.body.storeAddress
			// this.dataForm.shippingCode = data.body.shippingCode
			this.dataForm.fixedTermAmountMasterId = data.body.fixedTermAmountMasterId
			this.dataForm.totalTerm = data.body.totalTerm
			this.dataForm.paymentAmount = data.body.paymentAmount
          }
        })
      },
      captrue() {
        const ref = this.$refs.content
        html2canvas(ref, {
          backgroundColor: '#e8f4ff',
          useCORS: true
        }).then((canvas) => {
          const dataURL = canvas.toDataURL('image/png')
          const creatDom = document.createElement('a')
          document.body.appendChild(creatDom)
          creatDom.href = dataURL
          creatDom.download = 'image'
          creatDom.click()
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/payment.scss";
  .show-body{
  text-align: left!important;
  &-inner{
    width: 700px;
    margin:0 auto;
  }
  &-title{
    font-size:32px;
    color: #8e0303;
    margin-bottom:32px;
  }
  &-amount{
    font-size:32px;
    color: #707070;
    margin-bottom:14px;
    font-weight: bold;
  }
  &-business{
    font-size: 20px;
    color: #707070;
  }
  &-bottom{
    font-size:21px;
    color: #030303;
    margin-bottom:31px;
  }
  &-pcode{
    font-size: 32px;
    color: #030303;
  }
  &-img-box{
    margin-bottom:63px;
  }
  &-img{
    width:40px;
    height:40px;
  }
  &-back{
    font-size: 23px;
    color: #8e0303;
    font-weight: bold;
    border-bottom:2px solid #8e0303;
    width: 97px;
    padding: 2px;
    text-align: center;
    cursor: pointer;
  }
  .line.el-divider--horizontal {
    margin: 23px 0  53px 0 !important;
  }
  .body-sec{
    margin-bottom:48.7px;
  }
}

.txt-end {
 text-align: end;

}
.back-btn {
  font-size: 26px;
  color: #8F0304;
  font-weight: bold;
  border-bottom: 2px solid #8F0304;
  padding: 2px;
  cursor: pointer;
  text-align: center;
}

.next-btn {
  background: #8F0304;
  width: 400px;
  max-width: 100%;
  .img {
      width: 30px;
      top: 5px;
  }
  .txt {
    font-size: 26px;
    font-weight: 600;
    line-height: 45px;
    margin-left: 10px;
  }
}

.box-txt-bl-s {
  font-weight: 600;
  font-size: 12px;
  color: #000000;
  line-height: 20px;
}

.box-txt-bl-w {
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  line-height: 25px;
}

.box-txt-rd {
  font-weight: 600;
  color: #8F0304;
  font-size: 12px;
  line-height: 20px;
}

.box-txt-rd-w {
  font-weight: 400;
  color: #8F0304;
  font-size: 12px;
  line-height: 20px;
}

.bot-txt-g {
  font-weight: 400;
  font-size: 18px;
  color: #707070;
}

.bot-txt-b {
  font-weight: 400;
  font-size: 18px;
  color: #121212;
  word-break: break-all
}

.m-top-15 {
    margin-top: 15px;
  }
  .m-top-20 {
    margin-top: 20px;
  }
  .m-top-25 {
    margin-top: 25px;
  }
  .m-top-40 {
    margin-top: 40px;
  }

  .txt-cen {
    text-align: center;
  }

  .left-txt-g {
    font-weight: 600;
    font-size: 26px;
    color: #707070;
  }

  .top-txt-g {
    font-weight: 600;
    font-size: 36px;
    color: #23A26D;
  }

  .left-txt-r {
    font-weight: 600;
    font-size: 26px;
    color: #8F0304;
  }

  .left-txt-b {
    font-weight: 600;
    font-size: 26px;
    color: #121212;
  }

  .line {
    width: 600px;
    height: 2px;
  }

  .notice {
    border-radius:10px;
    background: #FAF0CA;
    margin: 30px 0;
    padding: 20px;
  }

  .transactionAmount {
    font-weight: 600;
    font-size: 36px;
  }
  .top-txt {
    font-weight: 400;
    font-size: 22px;
  }
  .top-logo {
    width: 100px;
  }
  .w-b {
    padding: 60px 140px !important;
    margin-top: 20px;
    border-radius: 38px;
  }
  .bottom-txt {
    line-height: 32px;
    font-size: 18px;
    font-weight: 400;
    color: #707070;
  }
</style>
