<template>
<!--個人資料提供同意書-->
  <div style="border-radius:10px; border: 1px solid #000000;background: #FFFFFF;margin: 10px 0 20px 0;padding: 15px;">
    <el-button @click="()=>{ agreement1Lng = !agreement1Lng}" type="danger" style="width: 80px; height: 25px;background: #8F0304; font-size: 12px; font-weight: 600;border-radius: 25px; padding: 3px;">{{ agreement1Lng ? 'English' : '中文' }}</el-button>
    <div style="color:#8F918E;margin-top: 20px; height: 96px; overflow: auto;">
      <div v-if="agreement1Lng">
        <div>紅陽科技股份有限公司(下稱「本公司」)為提供您與本公司間各項往來之交易或服務、依中華民國法令及本公司主管機關法令規定及金融監理需要，依法定義務、依契約、類似契約關係或其他法律關係等，所為個人資料之蒐集、處理及利用及國際傳輸，茲依據個人資料保護法（下稱「個資法」）及其施行細則之規定，由本公司告知下列事項：(一)蒐集之目的 (二)個人資料之類別 (三)個人資料利用之期間、地區、對象及方式 (四)當事人依個資法第三條規定得行使之權利及方式 (五)當事人得自由選擇提供個人資料時，不提供將對其權益之影響。</div>
        <div>一、有關本公司蒐集您個人資料之目的、類別及個人資料利用之期間、地區、對象及方式等內容分述如下。但本公司實際蒐集、處理及利用資料之類別、使用目的、地區、對象及方式，仍以您與本公司實際往來之相關業務或服務所涉者為準。</div>
        <div>(一)蒐集目的<br>
          本公司於蒐集您個人資料之特定目的為「契約、類似契約或其他法律關係事務」、「消費者、客戶管理與服務」、「商業與技術資訊」、「資（通）訊服務」、「網路購物及其他電子商務服務」、「調查、統計與研究分析」、「營業登記項目或章程所定業務或其他法令所准許之各項目的」、「業務或營運管理之目的(包括但不限於客戶管理、稅務、諮詢顧問、行政研究、統計調查分析、資訊與資料庫管理、輔助性與後勤支援、風險控管、執行洗錢防制作業及配合全球打擊恐怖份子調查等目的」、「進行認識客戶（Know Your Customers）之程序」、及「委託他人處理開立電子發票事宜」，而蒐集、處理、利用及國際傳輸涉及您的個人資料。
        </div>
        <div>(二)蒐集類別<br>
          姓名、出生年月日、性別、地址、電話、電子郵件、網路位址、交易地點、交易時間、交易內容、信用卡號碼(個人資料類別： C001、C002、C003、C091、C092、C093、C101、C102)。
        </div>
        <div>
          (三)個人資料利用期間、地區、對象及方式<br>
          本公司依相關法令所定(例如商業會計法等)或因執行業務所必須之保存期間，將於本公司及合作夥伴的營運所及地區(含中華民國境內及未經主管機關禁止國際傳輸的境外地區)利用您的個人資料向你寄送上述訂購產品訊息、付款資訊、收據、活動資訊及您授權提供的信用卡相關資料透過自動扣款方式繳付相關帳款、暨就您所提出之詢問或申請需求，核對您的個人資料，並依個案透過電子郵件、電話、簡訊或其他您提供之連絡方式，使用、處理您的個人資料以回覆您的需求；或於前開第一條第(一)項「蒐集目的」之「必要的範圍內」，將您的資料提供予本公司或與本公司因業務需要訂有契約關係或業務往來機構或依法有權機關或金融監理機關；包含本公司次特約商店、發卡銀行、收單機構、往來合作廠商等。以達上述蒐集之目的。
        </div>
        <div>
          (四)當事人權利及行使方式<br>
          依據個資法相關規定，您就本公司保有之個人資料得行使下列權利：<br>
          1、除有個資法第十條所規定之例外情形外，得向本公司查詢、請求閱覽或請求製給複製本，惟本公司依個資法第十四條規定得酌收必要成本費用。<br>
          2、得向本公司請求補充或更正，惟依個資法施行細則第十九條規定，您應適當釋明其原因及事實。<br>
          3、本公司如有違反個資法規定蒐集、處理或利用個人資料，依個資法第十一條第四項規定，您得向本公司請求停止蒐集、處理或利用。<br>
          4、依個資法第十一條第二項規定，個人資料正確性有爭議者，得向本公司請求停止處理或利用個人資料。惟依該項但書規定，本公司因執行業務所必須並註明其爭議或經您書面同意者，不在此限。<br>
          5、依個資法第十一條第三項規定，個人資料蒐集之特定目的消失或期限屆滿時，得向本公司請求刪除、停止處理或利用個人資料。惟依該項但書規定，本公司因執行業務所必須或經您書面同意者，不在此限。
        </div>
        <div>
          二、若您擬行使上述任一權利，請檢附相關證明文件向本公司客服信箱(service@esafe.com.tw)提出請求，本公司就該書面請求之決定，將於收到書面請求之次日起15日內以書面通知申請人。前述15日期間於必要時，得予再延長15日，本公司並將以書面通知申請人。如係請求查詢或閱覽文件，申請人應於收受本公司通知後，於通知指定之期間內，至本公司通知函指定之地點查詢或閱覽。如申請人逾期未查詢或閱覽文件者，需向本公司重新提出書面請求。
        </div>
        <div>
          三、您得自由選擇是否提供有關個人資料，惟若拒絕提供相關個人資料係本公司辦理業務審核或作業所需之資料，本公司可能無法進行必要之業務審核或作業而無法提供您相關服務，本公司有權依其判斷決定是否同意處理您的申請。
        </div>
        <div>
          四、本公司有權修訂本同意書，並於修訂後，得以言詞、書面、電話、簡訊、電子郵件、傳真、電子文件或其他足以使您知悉或可得知悉之方式（包括但不限於以前述方式告知提供詳載本同意書內容之網站連結），告知修訂要點及指定網頁，屆時，請詳閱指定網頁內容。
        </div>
        <div>
          五、如您與<span class="red">紅陽科技股份有限公司(紅陽科技股份有限公司)</span>先前簽訂之契約或文件所定與個人資料之蒐集、處理、利用、國際傳輸有關條款與本同意書有所歧異者，以本同意書為準。
        </div>
      </div>
      <div v-if="!agreement1Lng">
        <div>
          Red Sunrise Co., Ltd. (hereinafter referred to as "the Company") may, for the purpose of (1) processing any and all of the transactions with your (the "Client"), or providing services to the Client; (2) (i) fulfilling Company's legal obligations in compliance with the laws and regulations of the Republic of China, or (ii) complying with the financial supervisory requirements of the governmental authorities in the Republic of China, or (iii) based on the contractual, quasi-contractual or other legal relationship between Company and the Client, pursuant to the Personal Data Protection Act ("PDPA") and Enforcement Rules of the PDPA ("PDPA Enforcement Rules"), notify the Client of the following matters:
        </div>
        <div>
          1. Regarding the type of Personal Data to be collected, time period, areas and manners of use of Personal Data and entities to use Personal Data, please see the contents are as follows for details, provided however that, the actual contents regarding collection, processing and use of the Personal Data will depend on the actual businesses and services provided by Company to the Client.<br>
          (1) The specific purposes<br>
          The specific purpose of the Company to collect your personal information is for "Contract, similar contract or other legal relationship", "Consumer, customer management and service", "Business and technical information", "Internet shopping and other e-commerce services", "the business or operational management (including but not limited to customer management, tax administration, consulting and advisory services, administrative research, statistical investigation and analysis, information and database management, logistics support, risk control and management, money laundering prevention operations, compliance with the global goal to fight crime and conduct investigation, etc.), conducting "know your customers", "outsourced issuing electronic invoice matters to third parties" and collection, processing, use and international transmission of your personal information.
        </div>
        <div>
          (2) The collection categories
          Name, date of birth, gender, address, telephone, e-mail, internet address, place of transaction occurred, transaction time, transaction content, and credit card number (personal data category: C001, C002, C003, C091, C092, C093, C101, C102).
        </div>
        <div>
          (3) Period, region, object and method of use of personal data
          The company will store in the areas where the company and its partners operate (including the territory of the Republic of China and overseas where international transmission is not prohibited by the competent authority) during the storage period determined by the relevant laws and regulations (such as the Commercial Accounting Law, etc.) or due to the execution of the business. region) use your personal information to send you ordering product information, payment information, receipts, event information and credit card related information authorized by you to pay the relevant accounts through automatic debit, and for your inquiries or applications needs, check your personal data, and use and process your personal data to respond to your needs through email, telephone, text message or other contact methods you provide according to the individual case; or in the first item (1) above Provide your information to the company or have a contractual relationship or business relationship with the company due to business needs, or legally authorized agencies or financial supervision agencies; including the company's special contract Stores, card-issuing banks, acquirers, cooperating manufacturers, etc., to achieve the above collection purposes.
        </div>
        <div>
          (4) Each of the Client Personnel may exercise the following rights with respect to his/her information according to relevant Articles set forth in the PDPA:<br>
          A. to make any enquiries or request for review or making a duplicate copy, except for the Exceptions set forth in Article 10 of the PDPA, provided however that, Company may charge a reasonable fee in accordance with Article 14 of the PDPA;<br>
          B. to request to supplement or rectify any error, provided that the Client Personnel shall provide Company with the adequate explanations in accordance with Article19 of the PDPA Enforcement Rules;<br>
          C. to request Company to cease the collection, processing or use of Personal Data if Company violates the PDPA to collect, process and use the Personal Data in accordance with Paragraph 4 of Article 1 of the PDPA;<br>
          D. to request to cease processing or using the Personal Data if the accuracy of the Personal Data is in dispute in accordance with Paragraph 2 of Article 11 of the PDPA, provided however that, according to the proviso of the same Paragraph, Company may not proceed with the cessation request if the Personal Data is required for the performance of the Company's business operation and such dispute is recorded or the written consent is obtained / from the Client Personnel; and<br>
          E. to request to delete or cease processing or using the Personal Data if the specific purpose no longer exists or time period expires in accordance with Paragraph 3 of Article 11 of the PDPA, provided however that, according to the proviso of the same Paragraph, Company may not proceed with the deletion or cessation request if such Personal Data is required for the performance of the Company's business operation or the written consent is obtained from the Client Personnel.
        </div>
        <div>
          2. To exercise any of the aforementioned rights, the applicant for any above request ("Applicant") should provide Company with a email request (email address: service@esafe.com.tw) together with the relevant supporting documents. Company will, within 15 days from the next day of receiving the written request, send to such Applicant a written notice informing Company determination. Such 15-day period may be extended for another 15 days when necessary, and Company will notify the Applicant of the extension in writing. In the case of request for making an inquiry or review documents, the Applicant should, after receiving a notice from Company in response to such request, visit the location designated by Company in the notice within the time limit specified by Company. The Applicant should submit a new request if he/she fails to make an inquiry or review of the Personal Data within the specified time limit.
        </div>
        <div>
          3. Client are free to choose whether to provide the relevant personal information, but if the refusal to provide relevant personal information is the company for business audit or operation of the necessary information, the company may not be able to carry out the necessary business audit or operation and cannot provide you with services, Have the right to decide whether or not to agree to your application.
        </div>
        <div>
          4. The Company has the right to amend this Notification Letter at any time, and notify the Client and Client Personnel of the amendments orally, in writing, or by telephone, text messaging, e-mail, facsimile, electronic documents, or other methods that may give the data subject direct or constructive notice (including but not limited to notification made via the aforementioned method to provide a website for reference to the contents of this Notification Letter). Please refer to such website so provided then.
        </div>
        <div>
          5. In the event of any inconsistency between this Notification Letter and any consent to collect, process, use, and internationally transmit Personal Data given by the Client or the Client personnel under any agreements or documents previously executed between the Client and <span class="red">紅陽科技(聯信Demo)(紅陽科技網站)</span>, this Notification Letter shall prevail.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "agreement1",
  data(){
    return{
      agreement1Lng: true

    }
  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/agreement.scss";
</style>
