<template>
<!--紅陽科技網路代理收付服務契約-->
  <div style="border-radius:10px; border: 1px solid #000000;background: #FFFFFF;margin: 10px 0 20px 0;padding: 15px;">
    <el-button @click="()=>{ agreement2Lng = !agreement2Lng}" type="danger" style="width: 80px; height: 25px;background: #8F0304; font-size: 12px; font-weight: 600;border-radius: 25px; padding: 3px;">{{ agreement2Lng ? 'English' : '中文' }}</el-button>
    <div style="color:#8F918E;margin-top: 20px; height: 96px; overflow: auto;" >
      <div v-if="agreement2Lng">
        <div>
          第一條 關於「紅陽科技」<br>
          一、本網路代理收付服務係由紅陽科技股份有限公司（以下簡稱本公司）向買（店）家提供的系統服務平台暨代收轉付的服務（以下簡稱本服務）。為了保障買（店）家的權益，請於註冊（如有）及使用本服務前，詳細閱讀了本服務條款所有內容。當使用本服務時，即表示買（店）家已閱讀、理解並同意接受本服務所定之所有內容。若買（店）家未滿二十歲，並應於買（店）家的法定代理人閱讀、瞭解且同意本服務條款之所有內容後，方得使用本服務。但若買（店）家已接受本服務，即視為已取得法定代理人之同意，並應遵守以下所有規範。<br>
          二、如買（店）家所屬的國家或地區排除本服務條款內容之全部或部分，則應立即停止使用本服務。<br>
          三、公司代表人：呂寶麟。<br>
          四、客服信箱：service@esafe.com.tw。<br>
          五、客服電話：02-2502-6969<br>
          六、公司網址：https://www.sunpay.com.tw<br>
          七、服務時間：週一至週五，9:30~18:30<br>
          八、營業地址：台北市大安區敦化南路二段65號13樓。<br>
        </div>
        <div>
          第二條 以電子文件為表示方法<br>
          本公司與買（店）家間以電子文件為表示方法時，依本服務條款交換之電子文件，如其內容可完整呈現且可於日後取出供查驗者，其效力與書面文件相同。但依法令或行政機關之公告排除適用者，不在此限。
        </div>
        <div>
          第三條 買家及店家帳號、密碼及安全（如有）<br>
          買（店）家瞭解並同意，確保密碼及帳號的機密安全是自身的責任。買（店）家將對利用該密碼、帳號所進行的一切行為及言論，負適當的義務，並同意以下事項：<br>
          一、買（店）家同意妥善保管帳號、密碼及或任何個人資料。在買（店）家完成購物或購物等程序後，應採取登出帳戶的動作，尤其是買（店）家是與他人共用電腦或使用公共電腦，務必要關閉瀏覽器視窗，以防止他人讀取買（店）家信件或其他個人資料。<br>
          二、買（店）家同意於發現有第三人冒用或盜用帳號及密碼，或其他任何未經合法授權之情形，應即刻通知本公司，同意暫停其使用本服務並採取防範之措施。
        </div>
        <div>
          第四條 買家及店家的守法義務及承諾<br>
          一、買（店）家承諾絕不為任何非法目的或以任何非法方式使用本服務，也不將本服務用於禁止或限制物品的交易，並承諾遵守中華民國相關法律、法規及一切使用網際網路之國際慣例。買（店）家若係中華民國以外之使用者，買（店）家同意同時遵守所屬國家或地域之法令。<br>
          二、買（店）家同意並保證不得利用本服務從事侵害他人權益或違法之行為，若有違反者應負所有法律責任，包含但不限於：<br>
          （一）侵害他人名譽、隱私權、商業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利。<br>
          （二）違反依法律或條款所應負之保密義務。<br>
          （三）冒用他人名義使用本服務。<br>
          （四）從事任何不法交易行為，如洗錢等。<br>
          （五）提供賭博資訊或以任何方式引誘他人參與賭博。<br>
          （六）使用無效信用卡號碼，或非使用者本人的信用卡號碼進行交易。<br>
          （七）使用他人銀行帳號或無效銀行帳號交易。<br>
          （八）利用本服務進行無真實交易背景的虛假交易。<br>
          （九）從事任何可能含有電腦病毒或是可能侵害本服務系統、資料之行為。<br>
          （十）其他本公司有正當理由認為不適當之行為。<br>
          三、店家使用本服務時，應依本公司及發卡銀行規定負責檢查信用卡真偽及核對買家持卡人身分；店家自發卡銀行所取得之授權碼，僅為發卡銀行所提供信用卡號碼之有效性之判別，並非對於信用卡真偽或持卡人身分之判別，亦不代表店家已完成對買家應履行之債務。<br>
          四、店家經由本服務接受其交易相對人以信用卡線上刷卡方式支付交易款項時，店家與其交易相對人間之整筆交易金額，須一次取得授權及請款，店家不得將其交易攤分作兩筆或多筆金額進行分刷交易或個別取得交易授權號碼，且店家擔保不得利用本服務從事任何非法交易、非實際消費之簽帳、融資性墊付現款（俗稱調現）或其他變相之融資等交易、或涉及無實質交易基礎之資金傳輸，亦不得從事代其交易相對人先行墊款繳交相關費用再透過本公司向發卡機構請款之行為。店家違反本項約定者，本公司得保留該等交易款項，如該等交易款項已撥付予店家，店家應返還予本公司，本公司並得自店家於本服務之帳戶內扣還相當於該等交易金額之款項。
        </div>
        <div>
          第五條 對「會員」服務的使用<br>
          一、「買（店）家會員」服務說明<br>
          （一）一旦買（店）家註冊成為本公司客戶，本公司將代理買家在買家或店家符合指定條件或狀態時，支付款項給店家。<br>
          （二）買（店）家在使用本服務過程中，畫面上出現的關於交易操作的提示是買（店）家會員交易的規則，買（店）家對於交易過程中任何一個頁面的確認表示對買（店）家會員交易規則的認同。<br>
          （三）買家使用本服務進行交易，即表示同意並授權本公司將您經遮罩之個人資訊以及交易資訊回傳通知所指定的交易店家。<br>
          二、銷貨退款之處理流程<br>
          交易完成後，買家如欲退貨或取消該筆交易，本公司應協助買家與店家溝通處理相關退貨事項。<br>
          三、購物糾紛之處理<br>
          買（店）家都應保留交易憑證等相關文件，以備糾紛爭議時供查證之用。
        </div>
        <div>
          第六條 商標、知識產權的保護<br>
          一、買（店）家會員平台上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由本公司或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、商業秘密等。<br>
          二、非經本公司或其他權利人書面同意任何人不得擅自使用、修改、複製、公開傳播、改變、散佈、發行或公開發表本網站程式或內容。<br>
          三、如有違反，買（店）家應對本公司負損害賠償責任。
        </div>
        <div>
          第七條 本服務契約解釋原則<br>
          本服務契約條款如有疑義時，應為有利買（店）家之解釋。
        </div>
        <div>
          第八條 本服務內容及費用<br>
          一、支付工具：信用卡、金融卡、超商代收等服務。<br>
          二、收費項目：交易手續費、帳務處理費。<br>
          三、收費方式：撥款予店家時內扣手續費。<br>
          四、收費標準：依實際交易金額計收。<br>
          五、收費金額：各項服務費之項目、計算方式及金額請參閱《申請費用》說明。<br>
          六、應收費之對象：與本公司簽約之店家。<br>
          七、支付流程：<br>
          （一）信用卡：買家至店家網站選購商品→買家輸入信用卡付款資訊→取得買家發卡銀行之授權碼→成功授權後店家出貨→收單銀行撥款至本公司與信託銀行簽訂信託契約所約定之信託專戶→交易完成後8~10天由本公司指示信託銀行整批匯款至店家指定戶頭。<br>
          （二）金融卡：買家至店家網站選購商品→買家使用金融卡轉帳→收單銀行進行扣款→扣款成功後店家出貨→收單銀行撥款至本公司指定專戶→交易完成後二工作日由本公司整批匯款至店家指定戶頭。<br>
          （三）超商代收：買家至店家網站選購商品→買家選擇超商代收方式付款→買家至超商付款→代收款機構通知本公司買家已付款→本公司將付款成功訊息通知店家，店家出貨→代收款機構撥款至本公司指定專戶→交易完成後5~25天由本公司整批匯款至店家指定戶頭。<br>
          八、支付帳戶款項提領方式：依店家與本公司之約定期間（如上述支付流程內說明），由本公司主動指示信託銀行辦理匯撥。<br>
          九、支付款項專用存款帳戶的存款銀行：華泰銀行、第一銀行、合作金庫、彰化銀行、凱基銀行。
        </div>
        <div>
          第九條 匯率之計算<br>
          本服務買（店）家所有交易帳款均應以新臺幣結付，不涉及匯率換算。
        </div>
        <div>
          第十條 支付款項之保障<br>
          本服務支付款項已經分別全部存入與華泰銀行、第一銀行、合作金庫、彰化銀行、凱基銀行簽訂信託契約所約定之信託專戶，專款專用。所稱專用，指本公司為履行網路代理收付服務契約之義務所使用。
        </div>
        <div>
          第十一條 支付指示之再確認及事後核對<br>
          一、本公司於支付完成前，就買家之支付指示，應顯示「確定付款嗎?」之選擇機制（含確定或取消），俾供買家得以再確認支付指示內容是否正確。<br>
          二、本公司應於每筆款項支付完成後，以電子郵件或發送簡訊之方式通知買家支付明細，俾供買家事後核對。
        </div>
        <div>
          第十二條 支付錯誤之處理<br>
          一、因不可歸責於買家之事由而發生支付錯誤時，本公司應協助買家更正，並提供其他必要之協助。<br>
          二、因可歸責於本公司之事由而發生支付錯誤時，本公司應於知悉時，立即更正，並同時以電子郵件或雙方約定之方式通知買家及店家。<br>
          三、因可歸責於買家之事由而發生支付錯誤時，例如買家輸入錯誤之金額或輸入錯誤之收款方，經買家通知後，本公司應立即協助處理。
        </div>
        <div>
          第十三條 資訊安全<br>
          一、本服務是經由信用卡國際組織規定的PCIDSS認證再加上國際認證標準SSL安全加密機制，確保買（店）家的任何資料絕不外洩。<br>
          二、本公司、買（店）家應各自確保所使用資訊系統之安全，防止非法入侵、取得、竄改、毀損業務紀錄或買家之個人資料。<br>
          三、第三人破解本公司資訊系統之保護措施或利用資訊系統之漏洞爭議，由本公司就該事實不存在負舉證責任。<br>
          四、第三人入侵網路代理收付服務資訊系統對買（店）家所造成之損害，由本公司負擔。
        </div>
        <div>
          第十四條 本公司之終止契約或暫停服務<br>
          本公司於下列情形，得片面終止契約或暫時停止本服務：<br>
          一、有相當事證足認買（店）家有利用支付服務為洗錢、詐欺等犯罪行為或不法行為者。<br>
          二、支付款項經法院裁定或檢察官命令扣押者。<br>
          三、買（店）家提交虛偽之身分認證資料，經查證屬實者。<br>
          四、買（店）家由於不履行本合約規定之義務者，視作單方面終止本合約，本公司有權向買（店）家請求損害賠償，並有權終止本合約。<br>
          五、買（店）家未配合第十九條第三項再次進行識別及確認身分者。
        </div>
        <div>
          第十五條 帳號密碼被冒用之處理<br>
          一、本公司或買（店）家於知悉帳號密碼被冒用時，應立即通知他方並暫停該帳號所指示之支付行為暨暫停該帳號後續之支付指示。<br>
          二、本公司應於服務網頁明顯處載明帳號密碼被冒用時的連絡電0509-11-2277，除有不可抗力或其他重大事由，通知電話之服務時間應為全日（24小時）全年（365日）無休。<br>
          三、買（店）家辦理帳號密碼被冒用手續完成後所發生之損失，概由本公司負擔；其辦理帳號密碼被冒用手續前所發生之損失，於有下列情事時，悉由買（店）家負擔：<br>
          （一）買（店）家未妥善保管帳號密碼。<br>
          （二）買（店）家自行將帳號密碼提供與他人。<br>
          （三）買（店）家未使用本公司所提供的帳號安全機制。<br>
          （四）因買（店）家之故意或重大過失所致之事由。<br>
          四、調查買（店）家帳號密碼被冒用所生之費用，由本公司負擔。<br>
          五、本公司基於保護買（店）家安全之理由，於發現或接獲買（店）家通知有本條第一項情形時，立即停止該帳號使用本服務，直至該異常狀況解除為止，本公司並得要求買（店）家出具相關文件（包含但不限於：警政機關報案證明、切結書、身分證明文件等），以恢復該帳號於本服務之使用。<br>
          六、買（店）家同意於使用本服務時，本公司得就使用者登入資訊（包括網路IP位置與時間）、所為之行為及其他依法令應留存之紀錄予以詳實記錄。
        </div>
        <div>
          第十六條 買家或店家之終止契約<br>
          買（店）家得依雙方約定之方式隨時終止契約。
        </div>
        <div>
          第十七條 契約條款變更<br>
          一、本公司如欲變更契約內容，應於網站明顯處公告，並以電子郵件或雙方約定之方式通知買（店）家。<br>
          二、本公司未依前項進行公告及通知者，其契約之變更無效。但有利於買（店）家的變更不在此限。
        </div>
        <div>
          第十八條 爭議處理<br>
          一、消費爭議之客服電話：02-25026969及電子郵件信：service@esafe.com.tw。<br>
          二、申訴、爭議處理機制及程序：由客服受理買（店）家爭議暨申訴→買（店）家填寫「線上客服處理表」→依買（店）家爭議暨申訴內容會辦相關單位→經辦單位先判斷是否可歸責本公司之疏失→若是，則依本服務條款檢討改善並採取補救措施→回覆客戶；若否，則逕回覆客戶→填覆客服人員「線上客服處理表」→客服人員與客戶確認是否接受？若接受，交行政部主管核決→客服人員結案歸檔；若不接受，則交回經辦單位重新處理。
        </div>
        <div>
          第十九條 買（店）家之身分認證<br>
          一、買（店）家應確認提交之身分認證資料正確且真實，不得有虛偽情事，如該等資料事後有變更，應立即通知本公司。<br>
          二、本服務會員身分認證機制如下：<br>
          （一）買家：身分認證資料填妥後，本服務自動帶出手機號碼後發送簡訊，買家將手機簡訊收到的四碼驗證碼輸入至驗證碼欄位（逾十分鐘後驗證碼即失效），經本服務伺服器判斷正確後即完成會員身分認證。<br>
          （二）店家：身分認證資料填妥後，店家以手機撥語音專線，經本服務伺服器判斷為真後即完成會員身分認證。<br>
          三、如有下列情形之一，本公司得要求買（店）家再次進行身分認證程序：<br>
          （一）買（店）家申請變更基本身分資料。<br>
          （二）買（店）家提供之身分證明文件或登記證照等相關文件疑似偽造或變造。<br>
          （三）買（店）家交易時距前次交易已逾半年。<br>
          （四）同一行動電話號碼、電子郵件信箱等遭不同買（店）家用於身分確認程序。<br>
          （五）發現疑似洗錢或資助恐怖主義交易，或自洗錢或資助恐怖主義高風險國家或地區匯入款項之交易時。<br>
          （六）對於所取得買（店）家身分資料之真實性或妥適性有所懷疑時。
        </div>
        <div>
          第二十條 洗錢防制及打擊資助恐怖主義<br>
          本公司為防制洗錢及打擊資助恐怖主義之目的，買（店）家同意本公司得依「洗錢防制法」、「中華民國銀行公會銀行防制洗錢及打擊資助恐怖主義注意事項範本」之規定，進行以下措施，本公司依本條辦理若致買（店）家發生損害或承受不利益者，均由買（店）家自行承擔，本公司不負損害賠償責任。<br>
          （一）本公司於發現買（店）家及關聯人為受經濟制裁、外國政府或國際洗錢防制組織認定或追查之恐怖分子或團體者之時，得逕行暫時停止本服務之全部或一部而無須另通知買（店）家及關聯人；本公司並得以電子郵件或雙方約定之方式通知買（店）家終止本服務條款。<br>
          （二）本公司於定期或不定期審查買（店）家及關聯人身分作業或認為必要時（包括但不限於：懷疑買（店）家涉及非法活動、疑似洗錢、資助恐怖主義活動、或媒體報導涉及違法之特殊案件等），得要求買（店）家於接獲本公司通知後60天（含）內提供審查所需之必要個人（含使用者及關聯人）或公司資料、法人戶之實際受益人或對其行使控制權之人的資訊或對交易性質與目的或資金來源進行說明，買（店）家逾期仍不履行者，本公司得以電子郵件或雙方約定之方式通知暫時停止本服務之全部或一部、或終止本服務。
        </div>
        <div>
          第二十一條 賠償責任<br>
          一、（店）家使用本公司金流服務時，應依據與消費者之約定確實履行義務。如（店）家出於任何原因與消費者發生糾紛致消費者向發卡銀行或收單機構請求拒付或索回其向本公司支付的全部或部分款項時，於發卡銀行拒付或對本公司強制扣款時，（店）家同意無條件授權本公司依實際情形先行賠償或補償消費者、發卡銀行或收單機構之損失或損害，（店）家並同意於本公司為前揭賠償或補償後，應無條件賠償或補償本公司因此所生之一切損失或損害（包括但不限於賠償、補償之金額、退還之款項、利息、律師費及其他因訴訟或非訴訟所支出之費用等），這些情況包括但不限於下述情節：<br>
          （一）（店）家沒能提供服務或所提供的服務與消費者訂購明細不符或拒絕退貨；<br>
          （二）（店）家未能提供交易對應的消費者消費紀錄或相關證據；<br>
          （三）由於（店）家原因導致交易不合法或不可執行；<br>
          二、（店）家未依本合約約定處理帳款，致本公司或收單機構或發卡銀行所受損害，（店）家應負賠償之責（包括但不限於律師費及其他訴訟或非訴訟所生之費用）。<br>
          三、（店）家依本條前三項規定而應負賠償之責時，（店）家無條件同意並授權本公司於當期或未來應給付（店）家之貨款中逕行扣抵，不足部分並同意以（店）家交付之保證金作為扣抵使用（如有），扣抵後仍有差額者，（店）家應於本公司通知之日起三個日曆天內，以匯款或現金方式支付本公司該差額。若違反前揭規定者，（店）家所積欠之款項，本公司得加計自通知日起至清償日止，按年利率百分之十計算之利息。
        </div>
        <div>
          第二十二條 準據法及管轄法院<br>
          一、本服務之爭議應以中華民國法律為準據法。<br>
          二、因本服務所生之一切爭議，立約人同意本誠信原則協議解決之；如有訴訟之必要時，除法律另有規定外，買（店）家同意以臺灣臺北地方法院為第一審管轄法院。
        </div>
        <div>
          第二十三條 其他約定事項<br>
          一、本服務代收款項，一律僅得撥付至買（店）家本人持有且已經認證之金融機構實體存款帳戶。買（店）家不得因該帳戶因故（包括但不限於遭凍結、設為警示帳戶等事由），而主張本公司應將款項撥付至其他未經認證之帳戶中。<br>
          二、買（店）家同意本公司提供各項業務、商品或服務相關訊息，及寄送各項業務之消費、行銷或優惠活動訊息；如買（店）家以書面明示拒絕同意，本公司即應停止提供前述各項訊息。<br>
          三、本服務之店家均有交易限額及額度限制，本公司保有依據店家之相關財力情形及檢附文件調整各項限制之權利。<br>
          四、店家應隨時注意其於本服務之收款額度狀況，若有較高收款額度需求時，須另向本公司提出申請，惟店家對本公司所訂之收款額度上限不得異議。<br>
          五、店家僅能於收款額度內進行收款，若因已達收款額度上限且未事先向本公司提出申請調高收款額度，將無法再進行收款，故店家不得以任何理由向本公司提出因此造成其收入減損、營業損失及請求損害賠償之主張。<br>
          六、買家若因已達付款額度上限而無法進行付款，不得以任何理由向本公司提出因此造成其買賣、消費之不便利、或買賣雙方之糾紛等主張。
        </div>
      </div>
      <div v-if="!agreement2Lng">
        <div>
          Article 1 About SunTech<br>
          1. These online payment-processing services are the system service platform and payment forwarding services (hereinafter referred to as the "Services") provided by SunTech Co., Ltd. (hereinafter referred to as the "Company") to the buyer (vendor). To protect the buyer's (vendor's) interest, please read all the service terms and conditions hereunder carefully. The buyer's (vendor's) use of the Services shall constitute the buyer's (vendor's) reading, understanding and acceptance of all terms and conditions of the Services. For any buyer (vendor) below the age of 20, these Services shall only be used after the buyer's (vendor's) legal representative have read, understood and agreed to all terms and conditions of the Services. However, the buyer's (vendor's) acceptance of the Services shall constitute the consent of its legal representative and the buyer (vendor) shall comply with all terms and conditions below.<br>
          2. If the buyer's (vendor's) country or territory excludes the terms and condition the Services in whole or in part, such buyer (vendor) shall immediately stop the use of the Services.<br>
          3. The Company's legal representative: Lu, Baolin.<br>
          4. Customer service email address: service@esafe.com.tw.<br>
          5. Customer Service Hotline: 02-2502-6969.<br>
          6. Official website: https://www.esafe.com.tw<br>
          7. Service Hours: Monday through Friday, 9:30 - 18:30.<br>
          8. Business location: 13F, No.65, Sec. 2, Dunhua S. Rd., Da`an Dist., Taipei City 106, Taiwan (R.O.C.)
        </div>
        <div>
          Article 2 Electronic Documents<br>
          When the Company exchanges an electronic document with a buyer (vendor) in accordance with the terms and conditions of the Services and if the provisions of the document can be displayed in a complete manner and are available for future verifications, such document shall have the same effect as a written document, unless it is excluded by law or any public announcement by an administrative authority.
        </div>
        <div>
          Article 3 Buyer and Vendor Account, Password and Security (if any)<br>
          The buyer (vendor) understands and agrees to be solely liable for the confidentiality and security of its password and account. The buyer (vendor) shall be duly obliged for all conducts and statements through use of such password and account. The buyer (vendor) also agrees to the following:<br>
          1. The buyer (vendor) agrees to maintain the account, password or any personal information in a proper manner. After the buyer (vendor) completes shopping or shopping procedures, it shall log out of the account, particularly when the buyer (vendor) shares a computer with any other person or uses a public computer. Make sure that the browser window is closed to prevent others from reading the buyer's (vendor's) correspondence or other personal information.<br>
          2. The buyer (vendor) agrees to give immediate notice to the Company upon discovery of any third party fraudulent use or theft of its account, password or any other event without legal authorization. The buyer (vendor) agrees to suspend its use of the Services and shall undertake precautionary measures.
        </div>
        <div>
          Article 4 Buyer and Vendor's Obligation and Undertaking about Compliance with Law<br>
          1. The buyer (vendor) undertakes to never use the Services for any illegal purposes or in any illegal manner, nor shall the Services be used to trade any contraband or restricted object. The buyer (vendor) also undertakes to comply with applicable laws and regulations of the Republic of China, as well as international practice for Internet use. If the buyer (vendor) is a user outside the Republic of China, the buyer (vendor) also agrees to comply with the laws of its country or territory.<br>
          2. The buyer (vendor) agrees and warrants not using the Services to engage in any act that jeopardizes any other person's interest or in any illegal act. In case of any breach, the buyer (vendor) shall bear all legal liabilities, including but not limited to:<br>
          (1) Infringement against any other person's goodwill, privacy, trade secret, trademark, copyright, patent, other intellectual property rights or other rights.<br>
          (2) Breach of any confidentiality obligation by law or provision.<br>
          (3) Fraudulent use of any other person's name to use the Services.<br>
          (4) Engagement of any unlawful transaction, such as money laundering.<br>
          (5) Provision of any gambling information or enticing any other person to participate in gambling in any manner.<br>
          (6) Transaction through use of any invalid credit card number or the credit card number of any other person.<br>
          (7) Transaction through use of any other person's bank account or any invalid bank account.<br>
          (8) Engagement of false transactions without genuine transaction backgrounds through the use of these Services.<br>
          (9) Engagement of any conduct that may include a computer virus or that may jeopardize the system or data of the Services.<br>
          (10) Any other conduct deemed improper by the Company with justification.<br>
          3. In using these Services, the vendor is responsible for verifying the genuineness of the credit card and the identity of the buyer/cardholder in accordance with the regulations of the Company and the card-issuing bank. The authorization code acquired by the vendor from the card issuing bank only represents a verification by the card issuing bank about the validity of the credit card number and does not serve as a verification about the genuineness of the credit card or the identity of the cardholder and thus shall not release the vendor of its obligation to the buyer.<br>
          4. When a vendor accepts an online credit card payment by the transaction counterparty through the Services, an authorization and payment claim shall be acquired for the entire transaction amount between the vendor and the transaction counterparty. The vendor shall not divide any single transaction into two or more amounts to achieve multiple transactions or individual transaction authorization codes. The vendor also warrants that the Services shall not be used to engage in any unlawful transaction, credit card transaction without actual consumption, financing cash advance ("cash-out"), any other financing transaction or any fund transmission without underlying transaction, nor shall the vendor advance any funds to the transaction counterparty for payment, followed by payment claim against the card issuer through the Company. In case of any breaches by the vendor, the Company may withhold the transaction amount. If the transaction amount has been released to the vendor, the vendor shall return the amount to the Company. The Company may also debit an amount equivalent to the transaction amount from the vendor's account under the Services.
        </div>
        <div>
          Article 5 Use of "Member" Services<br>
          1. "Buyer (Vendor) Member" Services<br>
          (1) Once the buyer (vendor) registers as a client to the Company, the Company shall make payments to the vendor on behalf of the buyer when the buyer or the vendor meets the designated conditions or status.<br>
          (2) When the buyer (vendor) uses the Services, the instructions related to transaction operations displayed on the screen represent the buyer (vendor) member transaction rules. The buyer's (vendor's) confirmation on any single page in the course of a transaction shall be deemed the buyer's (vendor's) agreement to the member transaction rules.<br>
          (3) The buyer's engagement of a transaction through use of the Services shall be deemed its agreement and authorization to the Company to transmit your personal information that has been blinded and the transaction data to the designated transaction vendor.<br>
          2. Cancellation and Refund Procedure<br>
          If the buyer wishes to return the product or cancel the transaction after the transaction has been completed, the Company shall assist the buyer and the vendor to communicate and process relevant return matters.<br>
          3. Shopping Disputes<br>
          Both the buyer and the vendor shall maintain relevant documents such as proof of transactions for verification in case of a dispute.
        </div>
        <div>
          Article 6 Protection of Trademark and Intellectual Property Rights<br>
          1. The intellectual property rights to all contents on the buyer (vendor) member platform, including but not limited to written work, pictures, files, data, information, website structure, webpage arrangements, webpage design belongs to the Company or other rights holders in accordance with the law, including but not limited to trademark, patent, copyright and trade secrets.<br>
          2. No one shall use, adapt, reproduce, publicly broadcast, disseminate, alter, issue or publish any program or content of the website without the written consent of the company or any other right holder.<br>
          3. In case of any breach, the buyer (vendor) shall be liable for compensating the Company.
        </div>
        <div>
          Article 7 Rules of Interpretation of This Service Agreement<br>
          In case of any ambiguities, the contract terms and conditions of the Services shall be interpreted to the buyer's (vendor's) favor.
        </div>
        <div>
          Article 8 Scope of Services and Fees<br>
          1. Payment Tools: Credit card, debit card and convenience store payment services.<br>
          2. Fee Items: Transaction processing fee, account management fee.<br>
          3. Fee Payment: Processing fee is debited from payment to the vendor.<br>
          4. Fee Schedule: Based on the actual transaction amount.<br>
          5. Fee Amount: For the items, calculation method and amount of different service fees please refer to "Application Fees".<br>
          6. Payers: Vendors that have signed contracts with the Company.<br>
          7. Payment Procedure:<br>
          (1) Credit Card: Buyer selects product from the vendor's website → Buyer keys in credit card payment information → Acquire authorization code from the buyer's card issuing bank → Successful authorization, followed by the vendor's shipping → Bank remits funds to the trust account designated under the trust agreement signed by the Company and the trust bank → The Company instructs the trust bank to make batch remittance to the vendor's designated account 8 to 10 days after transactions are completed.<br>
          (2) Debit Card: Buyer selects product from the vendor's website → Buyer uses debit card to transfer funds → Bank debits the amount → Successful debit, followed by vendor's shipping → Bank remits funds to the Company's designated account → The Company makes batch remittance to the vendor's designated account 2 working days after transactions are completed.<br>
          (3) Convenience Store Payment: Buyer selects product from the vendor's website → Buyer chooses to make payment through a convenience store → Buyer goes to the convenience store to make payment → Payment processing organization gives notice to the Company about the buyer's payment → The Company gives notice about successful payment to the vendor, followed by the vendor's shipping → Payment processing organization remits funds to the Company's designated account → The Company makes batch remittances to the vendor's designated account 5 to 25 days after transactions are completed.<br>
          8. Withdrawal from Payment Account: In accordance with the period agreed between the vendor and the Company (as provided in the above payment procedure), the company will give instruction to the trust bank for transfer.<br>
          9. Deposit bank providing payment deposit accounts: Hwatai Bank, First Bank, Taiwan Cooperative Bank, Changhwa Bank, KGI Bank.
        </div>
        <div>
          Article 9 Foreign Exchange<br>
          All transaction amounts by the buyer (vendor) through the Services shall be in NT Dollars, without any foreign exchange conversion.
        </div>
        <div>
          Article 10 Secured Payment<br>
          The payments through the Services have been fully deposited into trust accounts agreed under trust contracts signed with Hwatai Bank, First Bank, Taiwan Cooperative Bank, Changhwa Bank and KGI Bank for exclusive purpose. Exclusive purpose means the use by the Company for the performance of its obligations under the Online Payment Services Agreement.
        </div>
        <div>
          Article 11 Re-Confirmation of Payment Instruction and Post-Transaction Verification<br>
          1. Before payment is completed, the Company shall display an option mechanism showing "Do you confirm payment?" (offering options between "Confirm" or "Cancel") in relation to the buyer's payment instruction, so that the buyer can re-confirm whether the payment instruction is correct.<br>
          2. After completion of each payment, the company shall send a notice to the buyer showing payment details by email or short message to allow the buyer's post-transaction verification.
        </div>
        <div>
          Article 12 Payment Error<br>
          1. In case of payment errors due to any reason that is not imputable to the buyer, the Company shall assist the buyer with the correction and shall provide other necessary assistance.<br>
          2. In case of payment errors due to any reason that is imputable to the Company, the Company shall make immediate correction upon learning of the event and shall give notice to the buyer and the vendor by email or another manner agreed by both parties.<br>
          3. In case of payment errors due to any reason that is imputable to the buyer, such as the buyer's input error in amount or payee, the Company shall provide immediate assistance upon the buyer's notice.
        </div>
        <div>
          Article 13 Information Security<br>
          1. The Services are PCIDSS certified by the international credit card organizations, with security encryption mechanism under SSL international certification standard, in order to ensure no disclosure of any of the buyer's (vendor's) information.<br>
          2. The Company and the buyer (vendor) shall each ensure the security of the information system that they use to prevent unlawful intrusion, acquisition, alteration or jeopardy to any business record of the buyer's personal information.<br>
          3. In case of any dispute about any third party's breaking into the protection measure of the Company's information system or use of any loophole in the information system, the Company shall bear the burden of proof about the non-existence of such event.<br>
          4. The Company shall be liable for any damage suffered by the buyer (vendor) caused by any third party hacking into the online payment processing services information system.
        </div>
        <div>
          Article 14 The Company's Termination of Contract or Suspension of Services<br>
          The Company may unilaterally terminate the agreement or suspend the Services in the following events:<br>
          1. Sufficient evidence showing that the buyer (vendor) uses the payment services to engage in any criminal offense or unlawful conduct such as money laundering or fraud.<br>
          2. The payment amount has been seized by court ruling or prosecutor's order.<br>
          3. The buyer (vendor) submits false identification information, as confirmed following verification.<br>
          4. The buyer (vendor) is deemed to have unilaterally terminated this agreement due to failure to perform its obligations under this agreement. The Company shall be entitled to seek compensation from the buyer (vendor) and shall have the right to terminate this agreement.<br>
          5. The buyer (vendor) fails to perform second identification and confirmation of identity in accordance with Paragraph 3, Article 19.
        </div>
        <div>
          Article 15 Fraudulent Use of Account and Password<br>
          1. If the Company or the buyer (vendor) learns that its account and passcode have been subject to fraudulent use, it shall give immediate notice to the other party and suspend the payment instructed with such account and suspend any subsequent payment instruction with such account.<br>
          2. The Company shall indicate in a clear location of the service webpage the contact number 0509-11-2277 in case of fraudulent use of account and password. Unless there is any even of force majeure or any other material event, the service hours of such number shall be full day (24 hours) and full year (365 days).<br>
          3. The Company shall bear all losses incurred after the buyer (vendor) has completed the procedure for fraudulent use of account and password. In case of the below events, the losses incurred before the completion of such procedure shall be borne by the buyer (vendor):<br>
          (1) The buyer (vendor) fails to keep in due custody the account and password.<br>
          (2) The buyer (vendor) provides its account and password to another person.<br>
          (3) The buyer (vendor) fails to use the account security mechanism provided by the Company.<br>
          (4) Any event caused by the buyer's (vendor's) willful conduct or gross negligence.<br>
          4. The Company shall bear the cost incurred for the investigation of fraudulent use of the buyer's (vendor's) account and password.<br>
          5. To protect the buyer's (vendor's) security, upon receipt of any buyer's (vendor's) notice about any event under Paragraph 1 of this article, the Company shall immediately stop the use of the Services with such account until the anomaly has ceased to exist. The Company may also ask the buyer (vendor) to provide relevant documents (including but not limited to proof of report to the police authority, affidavit and proof of documentation) to reinstate the use of the Services with such account.<br>
          6. The buyer (vendor) agrees that, during its use of the Services, the Company may keep detailed records of the user log-in information (including IP location and time), the activities and other records that should be maintained in accordance with the law.
        </div>
        <div>
          Article 16 Termination of Contract by Buyer or Vendor<br>
          The buyer (vendor) may terminate the contract at any time in the manner agreed by both parties.
        </div>
        <div>
          Article 17 Amendment<br>
          1. If the Company wishes to change the contract terms, it shall make a public announcement in a clear location on the website and shall give notice to the buyer (vendor) by email or any other manner agreed by both parties.<br>
          2. If the Company fails to make public announcement and give notice in accordance with the previous paragraph, the amendment shall be invalid, except if the amendment is to the buyer's (vendor's) favor.
        </div>
        <div>
          Article 18 Dispute Resolution<br>
          1. Consumer dispute customer service hotline: 02-25026969 and email: service@esafe.com.tw.<br>
          2. Complaint, Dispute Resolution Mechanism and Procedure: The customer service receives buyer's (vendor's) dispute and complaint → The buyer (vendor) completes the "Online Customer Service Processing Form" → Inform relevant department according to the buyer's (vendor's) dispute and nature of complaint → Processing department makes a first determination about whether there is a negligence imputable to the Company → If yes, review and undertake remedy measures in accordance with these service terms and conditions → Reply to the client; If No, reply to the client directly → Complete the customer service staff "Online Customer Service Processing Form" → Customer service staff confirms whether the client accepts? If yes, submit to supervisor of administration department for approval → Customer service staff closes and files the case; If not accepted, return the case to processing department for further handling.
        </div>
        <div>
          Article 19 Buyer's (Vendor's) Identity Certification<br>
          1. The buyer (vendor) shall confirm that the identity certification information that its submits is correct and truthful, without any falsity. If any such information is changed subsequently, an immediate notice shall be given to the Company.<br>
          2. The Service member identity certification mechanism is as follows:<br>
          (1) Buyer: After the identity certification information is completed, the Service will automatically bring out a mobile number and send a short message. The buyer should key in the 4-digit verification code received through mobile short message into the verification code box (verification code is valid for 10 minutes). Member identity certification will be completed after the server of the Services makes a positive determination.<br>
          (2) Vendor: After the identity certification information is completed, the vendor should dial a voice hotline by mobile phone. The member identity certification will be completed after the server of the Services makes a positive detemrination.<br>
          3. In case of any of the following events, the Company may ask the buyer (vendor) to complete second identity certification procedure:<br>
          (1) The buyer (vendor) asks to change basic identity information.<br>
          (2) Relevant document such as identification document or registration license provided by the buyer (vendor) may be forged or altered.<br>
          (3) The buyer's (vendor's) last transaction was more than six months ago.<br>
          (4) The same mobile phone number or email address was used by different buyers (vendors) in the identity confirmation procedure.<br>
          (5) Suspected trade involving money laundering or funding for terrorism, or remittance of transaction funds from a high-risk country or territory in terms of money laundering or terrorism funding.<br>
          (6) There is a concern about the genuineness or appropriateness of the buyer's (vendor's) identity information that was acquired.
        </div>
        <div>
          Article 20 Money Laundering Prevention and Crackdown on Terrorism Funding<br>
          To achieve the Company's purpose of money laundering prevention and crackdown on terrorism funding, the buyer (vendor) agrees that the Company may undertake the following measures in accordance with the Money Laundering Prevention Act and the Template Guidelines for Money Laundering Prevention and Crackdown on Terrorism Funding by the Banker's Association of the Republic of China. Any damage or jeopardy suffered by the buyer (vendor) due to the Company's performance in accordance with this article shall be solely borne by the buyer (vendor) and the Company shall not be liable for compensation.<br>
          (1) If the Company discovers that the buyer (vendor) or its related party is a terrorist or terrorist group subject to economic sanction, determination or investigation by a foreign government or international money laundering prevention organization, it may suspend the Services in whole or in part without notice to the buyer (vendor) or its related party. The Company may also terminate these service terms and conditions by notice to the buyer (vendor) through email or in any other manner agreed by both parties.<br>
          (2) When the Company performs regular or aperiodic review of the identity of the buyer (vendor) or its related party or as it deems it necessary (including but not limited to: suspicion about the buyer's (vendor's) involvement in unlawful activities, suspicion of money laundering, funding for terrorist activities or special cases of breach of law reported by the media), it may ask the buyer (vendor) to provide individual (including the user and related parties) or company information, actual beneficiary of a corporate account, information about the person exercising control or explanations about the nature and purpose of the transaction and the source of funds that is required for review within 60 days (inclusive) from receipt of the Company's notice. If the buyer (vendor) fails to perform before the deadline, the Company may suspend the Services in whole or in party or terminate the Services by email notice or in any other manner agreed by the parties.
        </div>
        <div>
          Article 21 Compensation<br>
          1. When the (vendor) uses any of the Company's cash flows services, it shall duly perform its obligations in accordance with its agreements with the consumers. If the (vendor) has any disagreement with any consumer due to any reason and if the consumer asks the card issuing bank or card accepting organization to refuse payment or claims the refund of any amount paid to the Company in whole or in part, upon the card issuing bank's refusal for payment or mandatory debit against the Company, the (vendor) agrees to unconditionally authorize the Company to first compensate or indemnify the loss or damage suffered by the consumer, card issuing bank or card accepting organization first based on the actual situation. The (vendor) also agrees that, after the Company has provided such compensation or indemnification, it shall unconditionally compensate or indemnify all losses or damages suffered by the Company (including but not limited to the amount of compensation or indemnification, the amount of refund, interest, attorney's fee or any other litigation or non-litigation cost incurred). These events include but are not limited to the following:<br>
          (1) The (vendor) fails to provide services or the services provided are inconsistent with the consumer's order details or the product is rejected and returned;<br>
          (2) The (vendor) fails to provide consumer purchase records or relevant proof corresponding to the transaction;<br>
          (3) The transaction is unlawful or unenforceable due to the (vendor's) reason.<br>
          2. If the (vendor) fails to process accounts in accordance with the requirements of this agreement, the (vendor) shall be liable for compensating the damages suffered by the Company, the card accepting organization or card-issuing bank (including but not limited to attorney's fee and other litigation or non-litigation cost).<br>
          3. If the (vendor) is liable for compensation in accordance with the previous three paragraphs of this article, the (vendor) unconditionally agrees and authorizes the Company to debit the amount from product payment that is due to the (vendor) in the current period or in the future. It is also agreed that any shortfall may be set off against the security deposit provided by the vendor (if any). If any shortfall remains after the setoff, the (vendor) shall pay the difference to the Company by remittance or in cash within 3 calendar days from the Company's notice. In case of any breach of the above, the Company may accrue interest at 10% per annum from the date of notice to the date of repayment on the overdue amount owed by the (vendor).
        </div>
        <div>
          Article 22 Governing Law and Court of Jurisdiction<br>
          1. Any dispute about the Services shall be governed by the laws of the Republic of China.<br>
          2. The undersigned agree that all disputes arising out of the Services shall be resolved through negotiation based on the principle of good faith. If litigation is necessary, unless otherwise provided by law, the buyer (vendor) agrees that the Taiwan Taipei District Court shall be the first-instance court of jurisdiction.
        </div>
        <div>
          Article 23 Other Agreements<br>
          1. All payments processed by the Services shall only be forwarded to the physical deposit account with a certified financial institution in the name of the buyer (vendor). The buyer (vendor) shall not ask the Company to pay the amount to any other non-certified account for any reason (including but not limited to seized or red-flagged account).<br>
          2. The buyer (vendor) agrees for the Company to provide information about business activities, products or services and to send information about business consumption, marketing or promotional event. If the buyer (vendor) expressly refuses to give this consent in writing, the Company shall immediately stop the provision of such information.<br>
          3. Each vendor of the Services is subject to transaction limit and amount limit. The Company reserves the right to change any limit based on the vendor's relevant financial status and documents submitted.<br>
          4. The vendor shall exercise due care at all times about the status of payment acceptance limit with the Services. If the vendor needs a higher payment acceptance limit, it shall file another application with the Company. However, the vendor shall not object to any payment acceptance limit that is established by the Company.<br>
          5. The vendor shall only accept payments within the payment acceptance limit. If the limit has been reached and if there is no prior application filed with the Company to raise the limit, no further payment shall be accepted. Thus the vendor shall not file any claim against the Company for loss of income, loss of business or compensation based on any reason.<br>
          6. If the buyer has reached its payment limit and cannot make further payment, it shall not file any claim against the Company for inconvenience of trade or consumption or dispute between the buyer and the vendor based on any reason.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "agreement2",
  data(){
    return{
      agreement2Lng: true
    }
  },
  methods:{
    

  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/agreement.scss";
</style>
