import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import overtime from '../views/overtime.vue'
import success from '../views/success.vue'
import orderfinish from '../views/orderfinish.vue'
import error from '../views/error.vue'
import cardInfo from '../views/payShowInfo/card-info.vue'

import superCodeInfo from '../views/payShowInfo/super-code-info.vue'
import superBarCodeInfo from '../views/payShowInfo/super-bar-code-info.vue'
import virtualAccountInfo from '../views/payShowInfo/virtual-account-info.vue'
import contributionDoc from '../views/doc/contribution-doc.vue'
import transferRemittanceDoc from '../views/doc/transfer-remittance-doc.vue'
import superPayPickupInfo from '../views/payShowInfo/super-pay-pickup-info.vue'
import indexPeriodic from '../views/indexPeriodic.vue'
import superQuatoInfo from '../views/payShowInfo/super-quato-info.vue'
import applePayInfo from '../views/payShowInfo/apple-pay-info.vue'
import googlePayInfo from '../views/payShowInfo/google-pay-info.vue'
import orderOvertime from '../views/orderovertime.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'overtime' },
  },
  {
    path: '/quickpay/:code1/:code2',
    name: 'overtime',
    component: index
  },
  {
    path: '/periodic/:code1/:code2',
    name: 'indexPeriodic',
    component: indexPeriodic
  },
  {
    path: '/overtime',
    name: 'overtime',
    component: overtime
  },
  {
    path: '/orderfinish',
    name: 'orderfinish',
    component: orderfinish
  },
  {
    path: '/error',
    name: 'error',
    component: error
  },
  {
    path: '/card-info',
    name: 'card-info',
    component: cardInfo
  },
  {
    path: '/success',
    name: 'success',
    component: success
  },
  {
    path: '/super-code-info',
    name: 'super-code-info',
    component: superCodeInfo
  },
  {
    path: '/super-bar-code-info',
    name: 'super-bar-code-info',
    component: superBarCodeInfo
  },
  {
    path: '/super-pay-pickup-info',
    name: 'super-pay-pickup-info',
    component: superPayPickupInfo
  },
  {
    path: '/virtual-account-info',
    name: 'virtual-account-info',
    component: virtualAccountInfo
  },
  {
    path: '/contribution-doc',
    name: 'contribution-doc',
    component: contributionDoc
  },
  {
    path: '/transfer-remittance-doc',
    name: 'transfer-remittance-doc',
    component: transferRemittanceDoc
  },
  {
    path: '/super-quato-info',
    name: 'super-quato-info',
    component: superQuatoInfo
  },
  {
    path: '/apple-pay-info',
    name: 'apple-pay-info',
    component: applePayInfo,
    meta: { title: 'Apple Pay' }
  },
  {
    path: '/google-pay-info',
    name: 'google-pay-info',
    component: googlePayInfo,
    meta: { title: 'Google Pay' }
  },
  {
    path: '/orderovertime',
    name: 'orderovertime',
    component: orderOvertime
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
